import React from "react";
import {
  EuiHorizontalRule,
  EuiListGroup,
  EuiListGroupItem,
} from "@elastic/eui";
import { useHistory } from "react-router-dom";
import dashboardIcon from "../../../assets/img/dashboardIcon.svg";
import liveOrderIcon from "../../../assets/img/liveOrder.svg";
import pastOrderIcon from "../../../assets/img/pastOrder.svg";
import sendNotificationIcon from "../../../assets/img/sendNotification.svg";
import menuIcon from "../../../assets/img/menuIcon.svg";

export const LeftSideBar = (props) => {
  const history = useHistory();

  return (
    <>
      <EuiListGroup>
        <EuiListGroupItem
          onClick={() => {
            history.push("/dashboard");
          }}
          label="Dashboard"
          iconType={dashboardIcon}
          isActive={history.location.pathname === "/dashboard"}
        />

        <EuiListGroupItem
          onClick={() => {
            history.push("/create-restaurant");
          }}
          label="Create Restaurant"
          iconType={pastOrderIcon}
          isActive={history.location.pathname === "/create-restaurant"}
        />
        <EuiListGroupItem
          onClick={() => {
            history.push("/create-outlet");
          }}
          label="Create Outlet"
          iconType={menuIcon}
          isActive={history.location.pathname === "/create-outlet"}
        />
        <EuiListGroupItem
          onClick={() => {
            history.push("/list-restaurant");
          }}
          label="Restaurant List"
          iconType={menuIcon}
          isActive={history.location.pathname === "/list-restaurant"}
        />
        <EuiListGroupItem
          onClick={() => {
            history.push("/list-outlet");
          }}
          label="Outlet List"
          iconType={menuIcon}
          isActive={history.location.pathname === "/list-outlet"}
        />

        <EuiListGroupItem
          onClick={() => {
            history.push("/user");
          }}
          label="User"
          iconType={menuIcon}
          isActive={history.location.pathname === "/user"}
        />

        <EuiListGroupItem
          onClick={() => {
            history.push("/plans");
          }}
          label="Plans"
          iconType={menuIcon}
          isActive={history.location.pathname === "/plans"}
        />
      </EuiListGroup>
      <EuiHorizontalRule margin="none" />
    </>
  );
};
