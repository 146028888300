import React from "react";
import { EuiHeader, EuiHeaderSectionItem, EuiHeaderLogo } from "@elastic/eui";
import UserMenu from "./userMenu";
import DarkLogo from "../../../assets/img/FabDeliveryNew.svg";
import WhiteLogo from "../../../assets/img/FabDeliveryNewWhite.svg";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CollapsibleNavbar from "../CollapsibleNavbar";

export const Header = (props) => {
  const history = useHistory();
  const currentTheme = useSelector((state) => state.theme.theme);

  return (
    <>
      <EuiHeader
        sections={[
          {
            items: [
              <CollapsibleNavbar />,
              <EuiHeaderSectionItem border="right">
                <EuiHeaderLogo
                  iconType={currentTheme === "light" ? DarkLogo : WhiteLogo}
                  aria-label="Goes to home"
                  className="header-logo-img"
                  onClick={() => history.push("/")}
                />
              </EuiHeaderSectionItem>,
            ],
          },
          {
            items: [<UserMenu />],
            borders: false,
          },
        ]}
      />
    </>
  );
};
