import * as types from "../types/outlet";

const initialState = {
  outletList: [],
  editOutletId: null,
  editOutlet: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_OUTLET_LIST:
      return {
        ...state,
        outletList: action.payload,
      };
    case types.SET_EDIT_OUTLET_ID:
      return {
        ...state,
        editOutletId: action.payload.editOutletId,
      };
    case types.SET_EDIT_OUTLET:
      return {
        ...state,
        editOutlet: action.payload.editOutlet,
      };
    default:
      return state;
  }
};
