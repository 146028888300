import { combineReducers } from "redux";
import auth from "./auth";
import theme from "./theme";
import language from "./language";
import outlet from "./outlet";
import restaurant from "./restaurant";
import user from "./user";
import loading from "./loading";
import plans from "./plans";

export default combineReducers({
  auth,
  theme,
  language,
  outlet,
  restaurant,
  user,
  loading,
  plans,
});
