import React from "react";
import { EuiPage, EuiPageBody } from "@elastic/eui";
import { LoginCard } from "../components/auth/loginCard";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

const LoginPage = () => {
  const userLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  // redirect to home if already logged in
  if (userLoggedIn) {
    return <Redirect to={{ pathname: "/" }} />;
  }
  return (
    <EuiPage style={{ height: "100vh" }}>
      <EuiPageBody>
        <LoginCard />
      </EuiPageBody>
    </EuiPage>
  );
};
export default LoginPage;
