import React, { useState } from "react";
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiSuperSelect,
} from "@elastic/eui";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import UserTablelist from "./userTableList";
import { getAllRestaurants } from "../../api/restaurantsAPICall";

export const UserComponent = (prpos) => {
  const dispatch = useDispatch();

  const {
    isLoading: loading,
    error,
    data: getAllRestaurant,
  } = useQuery("getAllRestaurant", getAllRestaurants);

  const [value, setValue] = useState("0");
  const onChange = (value) => {
    setValue(value);
  };
  if (loading) return <h1>Loading....</h1>;
  if (error) return <h1> Error...</h1>;
  let options = [
    {
      value: "0",
      inputDisplay: "Select Restaurant",
    },
  ];
  console.log("getAllRestaurant", getAllRestaurant);
  if (getAllRestaurant !== undefined) {
    if (getAllRestaurant.success) {
      getAllRestaurant.restaurants &&
        getAllRestaurant.restaurants.map((item, index) =>
          options.push({
            value: item.id,
            inputDisplay: item.name,
          })
        );
    }
  }

  if (value !== "0") {
    const getLanguages = getAllRestaurant.restaurants.find((item) => {
      if (item.id === value) {
        return item;
      }
    });
    console.log("restaurant", getLanguages);
    dispatch({
      type: "set-all-available-languages",
      payload: {
        availableLanguages: getLanguages.languages,
      },
    });
    dispatch({
      type: "set-restaurantId",
      payload: {
        restaurantId: getLanguages.id,
      },
    });
  }

  return (
    <EuiFlexGroup>
      <EuiFlexItem>
        <EuiText>
          <h3>Users</h3>
        </EuiText>
        <EuiText>
          <strong> Please select Restaurant </strong>{" "}
        </EuiText>
        <EuiSuperSelect
          options={options}
          valueOfSelected={value}
          onChange={(value) => onChange(value)}
        />
        {value !== "0" && <UserTablelist value={value} />}
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};
