import React, { useState } from "react";
import { EuiSuperSelect, EuiText } from "@elastic/eui";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "react-query";
import { getAllRestaurants } from "../../../api/restaurantsAPICall";
import CreateOutlet from "./createOutlet";
import { getAllPaymentMethods } from "../../../api/paymentAPICall";
import { normalizeUnits } from "moment";

const SelectRestaurant = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const editOutlet = useSelector((state) => state.outlet.editOutlet);

  const [value, setValue] = useState(
    editOutlet ? editOutlet.restaurant_id : "0"
  );

  const onChange = (value) => {
    setValue(value);
  };
  const {
    isLoading: loading,
    error,
    data: getAllRestaurant,
  } = useQuery("getAllRestaurant", getAllRestaurants);
  const {
    isLoading: loadingPayment,
    error: errorPayment,
    data: getAllPaymentMethod,
  } = useQuery("getAllPaymentMethod", getAllPaymentMethods);
  if (loading || loadingPayment) return <h1>Loading....</h1>;
  if (error || errorPayment) return <h1> Error...</h1>;

  let options = [
    {
      value: "0",
      inputDisplay: "Please select restaurant",
    },
  ];

  if (getAllRestaurant !== undefined) {
    if (getAllRestaurant.success) {
      getAllRestaurant.restaurants &&
        getAllRestaurant.restaurants.map((item, index) =>
          options.push({
            value: item.id,
            inputDisplay: item.name,
          })
        );
    }
  }
  if (value !== "0") {
    const getRestaurantInfo = getAllRestaurant.restaurants.find((item) => {
      if (item.id === value) {
        return item;
      }
    });

    dispatch({
      type: "set-all-available-languages",
      payload: {
        availableLanguages: getRestaurantInfo.languages,
      },
    });
    dispatch({
      type: "set-restaurantId",
      payload: {
        restaurantId: getRestaurantInfo.id,
      },
    });
  }
  return (
    <div>
      <EuiText>
        <strong> Create a new outlet </strong>{" "}
      </EuiText>
      <EuiSuperSelect
        options={options}
        valueOfSelected={value}
        onChange={(value) => onChange(value)}
      />
      {value !== "0" && (
        <CreateOutlet value={value} paymentMethod={getAllPaymentMethod} />
      )}
    </div>
  );
};

export default SelectRestaurant;
