import React from "react";
import { EuiFormRow, EuiFieldNumber } from "@elastic/eui";
import { Controller } from "react-hook-form";

const NumberField = (props) => {
  const { label, name, placeholder, rules, errors, control, fullWidth } = props;
  return (
    <EuiFormRow
      label={label}
      isInvalid={errors[name]}
      error={errors[name] ? errors[name].message : ""}
      fullWidth={fullWidth ? true : false}
    >
      <Controller
        {...props}
        as={EuiFieldNumber}
        name={name}
        control={control}
        rules={rules}
        fullWidth={true}
        placeholder={placeholder}
      />
    </EuiFormRow>
  );
};

export default NumberField;
