import React, { useEffect } from "react";
import { EuiTabbedContent } from "@elastic/eui";
import BasicInfo from "./BasicInfo";
import LocationServicability from "./locationServicability.js";
import PreparationTime from "./preparationTime";
import Charges from "./charges";
import PaymentSetting from "./paymentSetting";
import Timing from "./timing";
import OutletPaymentSettings from "./outlet-payment-settings";
import ThirdPartyRider from "./third_party_rider";
import { useDispatch } from "react-redux";

const EditOutletForm = ({ getOutletData }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch({
        type: "set-edit-outlet",
        payload: {
          editOutlet: null,
        },
      });
      dispatch({
        type: "set-restaurantId",
        payload: {
          restaurantId: null,
        },
      });
    };
  }, []);

  const tabs = [
    {
      id: "basic_information",
      name: "Basic Info",
      content: <BasicInfo getOutletData={getOutletData} />,
    },
    {
      id: "location_serviceability",
      name: "Location & Serviceability",
      content: <LocationServicability getOutletData={getOutletData} />,
    },
    {
      id: "preparation_time_and_rush_hour",
      name: "Preparation time & Rush hour",
      content: <PreparationTime getOutletData={getOutletData} />,
    },
    {
      id: "delivery_charge_and_other_charge",
      name: "Delivery charge & Other charge",
      content: <Charges getOutletData={getOutletData} />,
    },
    {
      id: "payment_setting",
      name: "Payment Settings",
      content: <PaymentSetting getOutletData={getOutletData} />,
    },
    {
      id: "timing",
      name: "Timing",
      content: <Timing getOutletData={getOutletData} />,
    },
    {
      id: "payment_mode_setting",
      name: "Outlet payment option setting",
      content: <OutletPaymentSettings outletId={getOutletData.outlet_id} />,
    },
    {
      id: "third_party_rider_setting",
      name: "Third party rider setting",
      content: <ThirdPartyRider getOutletData={getOutletData} />,
    },
  ];
  return <EuiTabbedContent tabs={tabs} initialSelectedTab={tabs[0]} />;
};

export default EditOutletForm;
