import format from "date-fns/format";
import addMonths from "date-fns/addMonths";
import subMonths from "date-fns/subMonths";
import subDays from "date-fns/subDays";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import endOfWeek from "date-fns/endOfWeek";
import startOfWeek from "date-fns/startOfWeek";
import subWeeks from "date-fns/subWeeks";

export const getCurrentMonthString = () => {
  return format(new Date(), "MMMM");
};

export const getNextMonthString = () => {
  return format(addMonths(new Date(), 1), "MMMM");
};

export const getPreviousMonthString = () => {
  return format(subMonths(new Date(), 1), "MMMM");
};

export const getTodayDate = () => {
  return format(new Date(), "dd-MM-y");
};

export const getYesterdayDate = () => {
  return format(subDays(new Date(), 1), "dd-MM-y");
};

export const getDateByDate = (date) => {
  return format(new Date(date), "dd-MM-y");
};

export const getLastMonthStartAndEndDate = () => {
  return [
    startOfMonth(subMonths(new Date(), 1)),
    endOfMonth(subMonths(new Date(), 1)),
  ];
};

export const getThisMonthStartAndEndDate = () => {
  return [startOfMonth(new Date()), endOfMonth(new Date())];
};

export const getTodayYesterdayDate = () => {
  return [subDays(new Date(), 0), subDays(new Date(), 1)];
};
export const getWeekStartAndEndDate = () => {
  return [
    startOfWeek(new Date(), { weekStartsOn: 1 }),
    endOfWeek(new Date(), { weekStartsOn: 1 }),
  ];
};
export const getPastWeekStartAndEndDate = () => {
  return [
    startOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 }),
    endOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 }),
  ];
};
