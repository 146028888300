import { baseApiUrl } from "../envConfig";
import { store } from "../reduxStore/store";
import API from "../api/axios/API";

export const getAllRestaurants = async () => {
  const restaurant = await API.get(`restaurants/`);

  return restaurant;
};

export const addOutletIntoRestaurant = async ({ data }) => {
  // var token = store.getState().auth.token;
  // console.log("token", token);
  // var restaurantID = store.getState().restaurant.restaurantId;
  // const outlet = await fetch(
  //   `${baseApiUrl}restaurants/${restaurantID}/outlets`,
  //   {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${token}`,
  //       Accept: "application/json",
  //     },
  //     body: JSON.stringify(data),
  //   }
  // );
  // const outletJson = await outlet.json();
  const outletJson = await API.post(`restaurants/:restaurantID/outlets`, data);
  return outletJson;
};

export const editOutletIntoRestaurant = async ({ data }) => {
  // var token = store.getState().auth.token;
  // console.log("token", token);
  // console.log("data",data)
  // var restaurantID = store.getState().restaurant.restaurantId;
  // const outlet = await fetch(
  //   `${baseApiUrl}restaurants/${restaurantID}/outlets/${data.id}`,
  //   {
  //     method: "PUT",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${token}`,
  //       Accept: "application/json",
  //     },
  //     body: JSON.stringify(data),
  //   }
  // );
  // const outletJson = await outlet.json();
  const outletJson = await API.put(
    `restaurants/:restaurantID/outlets/${data.id}`,
    data
  );
  return outletJson;
};

export const getAllOutletIntoRestaurant = async (key, id) => {
  const outletJson = await API.get(`restaurants/${id}/outlets`);

  return outletJson;
};

export const addRestaurant = async (data) => {
  const restaurantJson = await API.post(`restaurants/`, data);

  return restaurantJson;
};

export const editRestaurant = async (data) => {
  // var token = store.getState().auth.token;
  // console.log("token", token);
  // var restaurantID = store.getState().restaurant.restaurantId;
  // const outlet = await fetch(
  //   `${baseApiUrl}restaurants/${data.id}`,
  //   {
  //     method: "PUT",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${token}`,
  //       Accept: "application/json",
  //     },
  //     body: JSON.stringify(data),
  //   }
  // );
  // const outletJson = await outlet.json();

  const outletJson = await API.put(`restaurants/${data.id}`, data);
  return outletJson;
};
