import React, { useEffect, useState } from "react";
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiLoadingContent,
  EuiPanel,
  EuiSwitch,
  EuiText,
  EuiToolTip,
} from "@elastic/eui";
import { getTodayDate, getYesterdayDate } from "../../../helper/dateTime";
import { getTotalDeliveryCostByOutlet } from "../../../api/outlet";

export const DeliveryCost = (props) => {
  const [state, setState] = useState({
    isToday: true,
    apiDataLoaded: false,
    todayCost: 0,
    yesterdayCost: 0,
    todayDate: new Date(),
    yesterdayDate: new Date(),
  });

  useEffect(() => {
    getTotalDeliveryCostByOutlet(props.outletId).then((res) => {
      if (!res.apiError) {
        const details = res.body.details;
        setState({
          ...state,
          apiDataLoaded: true,
          todayCost: details.today.cost,
          yesterdayCost: details.yesterday.cost,
          todayDate: new Date(),
          yesterdayDate: new Date(),
        });
      }
    });
  }, [props.outletId]);

  return (
    <EuiPanel>
      <EuiFlexGroup responsive justifyContent="spaceBetween">
        <EuiFlexItem grow={false}>
          <EuiText>
            <h4>Delivery Cost</h4>
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiFlexGroup responsive={false} gutterSize="none">
            <EuiFlexItem
              grow={false}
              style={{ marginRight: 10 }}
              className="flex-vertical-center"
            >
              <EuiText size="s">
                <p>Yesterday</p>
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem grow={false} className="flex-vertical-center">
              <EuiSwitch
                label=""
                checked={state.isToday}
                onChange={(e) =>
                  setState({ ...state, isToday: e.target.checked })
                }
              />
            </EuiFlexItem>
            <EuiFlexItem grow={false} className="flex-vertical-center">
              <EuiText size="s">
                <p>Today</p>
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem
              grow={false}
              className="flex-vertical-center mr-left-10"
            >
              <EuiToolTip
                position="bottom"
                content={state.isToday ? getTodayDate() : getYesterdayDate()}
              >
                <EuiIcon tabIndex="0" type="iInCircle" />
              </EuiToolTip>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>

      {!state.apiDataLoaded && <EuiLoadingContent lines={4} />}
      {state.apiDataLoaded && (
        <EuiFlexGroup justifyContent="spaceAround">
          <EuiFlexItem grow={false}>
            <EuiPanel>
              <EuiText>
                <h4>Cost To Deliver</h4>
              </EuiText>
              <EuiText>
                <h3>
                  ₹{state.isToday ? state.todayCost : state.yesterdayCost}
                </h3>
              </EuiText>
            </EuiPanel>
          </EuiFlexItem>
        </EuiFlexGroup>
      )}
    </EuiPanel>
  );
};
