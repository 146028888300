import React from "react";
import { Controller, useFieldArray } from "react-hook-form";
import TimePicker from "react-time-picker";

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiButtonIcon,
  EuiButton,
} from "@elastic/eui";
import "./style.css";
const TimeSlot = ({ setValue, control, name, watch, errors }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: name,
  });

  return (
    <>
      {fields.length < 6 && (
        <EuiFlexGroup
          style={{
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <EuiButton
            fill
            iconType="plusInCircleFilled"
            onClick={() => {
              append({});
              // addDays();
            }}
          >
            Add
          </EuiButton>
        </EuiFlexGroup>
      )}
      {fields.map((item, index) => (
        <EuiFlexGroup
          alignItems="center"
          key={item.id}
          style={{ marginTop: 10 }}
        >
          <EuiFlexItem grow={2}> Slot : {index + 1}/6 </EuiFlexItem>
          <EuiFlexItem grow={8}>
            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiFormRow>
                  <Controller
                    render={({ onChange }) => (
                      <TimePicker
                        value={
                          watch(`${name}[${index}].start_time`)
                            ? watch(`${name}[${index}].start_time`)
                            : undefined
                        }
                        onChange={(time) => {
                          setValue(`${name}[${index}].start_time`, time);
                        }}
                        format="hh:mm a"
                        amPmAriaLabel="Select AM/PM"
                        closeClock={true}
                        disableClock={true}
                        required={true}
                      />
                    )}
                    name={`${name}[${index}].start_time`}
                    control={control}
                    fullWidth={true}
                  />
                </EuiFormRow>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiFormRow>
                  <Controller
                    render={({ onChange }) => (
                      <TimePicker
                        value={
                          watch(`${name}[${index}].end_time`)
                            ? watch(`${name}[${index}].end_time`)
                            : undefined
                        }
                        onChange={(time) => {
                          setValue(`${name}[${index}].end_time`, time);
                        }}
                        format="hh:mm a"
                        amPmAriaLabel="Select AM/PM"
                        closeClock={true}
                        disableClock={true}
                        required={true}
                      />
                    )}
                    name={`${name}[${index}].end_time`}
                    control={control}
                    fullWidth={true}
                  />
                </EuiFormRow>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiFlexGroup alignItems={"center"}>
              <EuiFlexItem>
                {fields.length > 0 && (
                  <EuiButtonIcon
                    iconType="minusInCircle"
                    onClick={() => {
                      remove(index);
                    }}
                  />
                )}
              </EuiFlexItem>
              <EuiFlexItem></EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      ))}
    </>
  );
};

export default TimeSlot;
