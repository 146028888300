import * as types from "../types/user";

const initialState = {
  editUser: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.EDIT_USER:
      return {
        ...state,
        editUser: action.payload.editUser,
      };
    default:
      return state;
  }
};
