import React from "react";
import { EuiSwitch } from "@elastic/eui";
import { Controller } from "react-hook-form";

const SwitchField = (props) => {
  const { name, defaultValue, control, setValue } = props;
  return (
    <Controller
      render={({ onChange, value }) => (
        <EuiSwitch
          onChange={(e) => {
            onChange(e.target.checked);
            setValue(name, e.target.checked);
          }}
          checked={value}
          compressed
        />
      )}
      name={name}
      control={control}
      defaultValue={defaultValue}
    />
  );
};

export default SwitchField;
