import React from "react";
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiPanel,
  EuiSwitch,
  EuiText,
  EuiToolTip,
  EuiIcon,
} from "@elastic/eui";

export const RevenueCard = (props) => {
  return (
    <EuiPanel>
      <EuiFlexGroup justifyContent="spaceBetween">
        <EuiFlexItem grow={false}>
          <EuiText>
            <h4>Total</h4>
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiFlexGroup responsive={false} gutterSize="none">
            <EuiFlexItem
              grow={false}
              style={{ marginRight: 10 }}
              className="flex-vertical-center"
            >
              <EuiText size="s">
                <p>{props.preSwitchText}</p>
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem grow={false} className="flex-vertical-center">
              <EuiSwitch
                label=""
                checked={props.isSwitchActive}
                onChange={(e) => props.onChangeSwitch(e)}
              />
            </EuiFlexItem>
            <EuiFlexItem grow={false} className="flex-vertical-center">
              <EuiText size="s">
                <p>{props.nextSwitchText}</p>
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem
              grow={false}
              className="flex-vertical-center mr-left-10"
            >
              <EuiToolTip position="bottom" content={props.toolTipText}>
                <EuiIcon tabIndex="0" type="iInCircle" />
              </EuiToolTip>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiText>
        <h2>₹{props.totalAmount}</h2>
      </EuiText>
      <EuiText>
        <p>{props.totalOrders} Orders</p>
      </EuiText>

      <EuiHorizontalRule margin="xs" />

      <EuiFlexGroup responsive={false}>
        <EuiFlexItem grow={5}>
          <EuiText>
            <h6>Delivered</h6>
          </EuiText>
          <EuiText>
            <h3>₹{props.deliveredAmount}</h3>
          </EuiText>
          <EuiText>
            <p>{props.deliveredOrders} Orders</p>
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem grow={5}>
          <EuiText>
            <h6>Cancelled</h6>
          </EuiText>
          <EuiText>
            <h3>₹{props.cancelledAmount}</h3>
          </EuiText>
          <EuiText>
            <p>{props.cancelledOrders} Orders</p>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPanel>
  );
};
