import React, { useState, useEffect } from "react";
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiRadioGroup,
  EuiCard,
  EuiText,
  EuiForm,
  EuiButton,
} from "@elastic/eui";
import ComboBoxField from "../../Form/ComboBoxField";
import { Controller, useForm } from "react-hook-form";
import { currencyData } from "../countrySymbolAndInforamtion";
import TextField from "../../Form/TextField";
import SelectField from "../../Form/SelectField";
import CheckBoxField from "../../Form/CheckBoxField";
import { useSelector } from "react-redux";
import { baseApiUrl } from "../../../envConfig";
import { store } from "../../../reduxStore/store";

const PaymentSetting = ({ getOutletData }) => {
  const { register, handleSubmit, errors, control, watch, reset, setValue } =
    useForm();
  const restaurantID = useSelector((state) => state.restaurant.restaurantId);
  var token = store.getState().auth.token;
  let defaultValue = {};
  let paymentProvider = [];
  let cardProvider = [];
  const [loader, setLoader] = useState(false);
  const slub = [
    {
      value: "0",
      inputDisplay: "0%",
    },
    {
      value: "5",
      inputDisplay: "5%",
    },
    {
      value: "12",
      inputDisplay: "12%",
    },
    {
      value: "18",
      inputDisplay: "18%",
    },
    {
      value: "28",
      inputDisplay: "28%",
    },
  ];
  const gst_applicable = [
    {
      id: "get_no",
      label: "No",
    },
    {
      id: "get_yes",
      label: "Yes",
    },
  ];
  const payment_provider_options = [
    {
      label: "paytm",
    },
    {
      label: "stripe",
    },
  ];
  const optionsPaytm = [
    {
      value: "paytm",
      inputDisplay: "Paytm",
    },
  ];

  useEffect(() => {
    if (getOutletData) {
      const outletData = getOutletData;

      defaultValue.gst_applicable = outletData.settings.gst_applicable
        ? "get_yes"
        : "get_no";
      defaultValue.delivery_charges_gst_slab =
        outletData.settings.delivery_charges_gst_slab.toString();
      defaultValue.gst_number = outletData.settings.gst_number;
      defaultValue.currency_symbol = [
        {
          value: outletData.settings.currency_symbol,
          label: outletData.settings.currency_symbol,
        },
      ];
      defaultValue.currency_code = [
        {
          value: outletData.settings.currency_code,
          label: outletData.settings.currency_code,
        },
      ];

      //set payment provider
      if (outletData.payment_settings.paytm) {
        paymentProvider.push({
          label: "paytm",
        });
      }

      if (outletData.payment_settings.stripe) {
        paymentProvider.push({
          label: "stripe",
        });
      }

      defaultValue.payment_provider = paymentProvider;

      if (outletData.payment_settings) {
        if (outletData.payment_settings.paytm) {
          for (const [key, value] of Object.entries(
            outletData.payment_settings.paytm
          )) {
            if (key === "PAYTM_MERCHANT_KEY") {
              defaultValue.paytm_merchent_key = value;
            }
            if (key === "PAYTM_MERCHANT_ID") {
              defaultValue.paytm_merchent_id = value;
            }
            if (key === "PAYTM_INDUSTRY_TYPE_ID") {
              defaultValue.paytm_industry_type_id = value;
            }
            if (key === "PAYTM_WEBSITE") {
              defaultValue.paytm_website = value;
            }
            if (key === "PAYTM_BASE_API_URL") {
              defaultValue.paytm_base_api_url = value;
            }
          }
        }
        if (outletData.payment_settings.stripe) {
          for (const [key, value] of Object.entries(
            outletData.payment_settings.stripe
          )) {
            if (key === "STRIPE_KEY") {
              defaultValue.stripe_key = value;
            }
            if (key === "STRIPE_SECRET") {
              defaultValue.stripe_secret = value;
            }
            if (key === "STRIPE_WEBHOOK") {
              defaultValue.stripe_webhook = value;
            }
            if (key === "STRIPE_WEBHOOK_SECRET") {
              defaultValue.stripe_webhook_secret = value;
            }
          }
        }
      }
      console.log(
        "cash-on-delivery",
        outletData.payment_availabilities["cash-on-delivery"]
      );
      if (outletData.payment_availabilities["cash-on-delivery"]) {
        if (outletData.payment_availabilities["cash-on-delivery"][0] == 1) {
          defaultValue["cash-on-delivery"] = true;
        } else {
          defaultValue["cash-on-delivery"] = false;
        }
      } else {
        defaultValue["cash-on-delivery"] = false;
      }

      if (paymentProvider.length > 0) {
        paymentProvider.map((item) => {
          if (item.label === "paytm") {
            cardProvider.push({
              value: "paytm",
              inputDisplay: "Paytm",
            });
          } else {
            cardProvider.push({
              value: "stripe",
              inputDisplay: "Stripe",
            });
          }
        });
      }
      defaultValue.cardProvider = cardProvider;
      defaultValue.credit_card =
        cardProvider.length === 1 ? cardProvider[0].value : null;
      if (outletData.payment_availabilities) {
        if (outletData.payment_availabilities.paytm) {
          outletData.payment_availabilities.paytm.map((item, index) => {
            if (item === "CREDIT_CARD/DEBIT_CARD") {
              defaultValue.credit_card = "paytm";
            }
          });
        }
        if (outletData.payment_availabilities.stripe) {
          outletData.payment_availabilities.stripe.map((item, index) => {
            if (item === "CREDIT_CARD/DEBIT_CARD") {
              defaultValue.credit_card = "stripe";
            }
          });
        }
      }
      reset({
        ...defaultValue,
      });
    }
  }, [getOutletData]);
  let currencyDataSymbol = [];
  let currencyDataCode = [];
  for (const [key1, value1] of Object.entries(currencyData)) {
    currencyDataSymbol.push({
      value: value1.symbol_native,
      label: `${value1.name} - ${value1.symbol} - ${value1.symbol_native} `,
    });
  }

  for (const [key1, value1] of Object.entries(currencyData)) {
    currencyDataCode.push({
      value: value1.code,
      label: value1.code,
    });
  }
  const [credit, setCredit] = useState(null);
  const onChangePaymentProvider = (selectedProvider) => {
    setValue("payment_provider", selectedProvider);
    console.log("selectedProvider", selectedProvider);
    let paymentOptions = [];
    selectedProvider.map((item) => {
      if (item.label === "paytm") {
        paymentOptions.push({
          value: "paytm",
          inputDisplay: "Paytm",
        });
      }
      if (item.label === "stripe") {
        paymentOptions.push({
          value: "stripe",
          inputDisplay: "Stripe",
        });
      }
    });

    const data = paymentOptions.length > 0 ? paymentOptions[0].value : "paytm";
    console.log("paymentOptions", paymentOptions);
    setValue("credit_card", data);
    setCredit(paymentOptions);
    watch("cardProvider", paymentOptions);
  };
  const onSubmit = async (data) => {
    data.id = null;
    if (getOutletData) {
      data.id = getOutletData.outlet_id;
    }
    const paytm = data.payment_provider.find((item) => {
      if (item.label === "paytm") {
        return "paytm";
      }
    });
    const stripe = data.payment_provider.find((item) => {
      if (item.label === "stripe") {
        return "stripe";
      }
    });
    let payment_settings = {};
    if (paytm) {
      if (paytm.label === "paytm") {
        payment_settings.paytm = {
          PAYTM_MERCHANT_KEY: data.paytm_merchent_key,
          PAYTM_MERCHANT_ID: data.paytm_merchent_id,
          PAYTM_INDUSTRY_TYPE_ID: data.paytm_industry_type_id,
          PAYTM_WEBSITE: data.paytm_website,
          PAYTM_BASE_API_URL: data.paytm_base_api_url,
        };
      }
    }
    if (stripe) {
      if (stripe.label === "stripe") {
        payment_settings.stripe = {
          STRIPE_KEY: data.stripe_key,
          STRIPE_SECRET: data.stripe_secret,
          STRIPE_WEBHOOK: data.stripe_webhook,
          STRIPE_WEBHOOK_SECRET: data.stripe_webhook_secret,
        };
      }
    }
    let paytmData = [];
    data.payment_provider.find((item) => {
      if (item.label === "paytm") {
        paytmData.push("NET_BANKING", "BALANCE", "UPI", "UPI_INTENT");
      }
    });
    let stripeData = [];
    if (data.credit_card === "stripe") {
      stripeData.push("CREDIT_CARD/DEBIT_CARD");
    } else {
      paytmData.push("CREDIT_CARD/DEBIT_CARD");
    }
    data.settings = {
      currency_symbol: data.currency_symbol[0].value,
      currency_code: data.currency_code[0].value,
      gst_applicable: data.gst_applicable === "get_no" ? 0 : 1,
      gst_number: data.gst_applicable === "get_yes" ? data.gst_number : null,
      delivery_charges_gst_slab:
        data.gst_applicable === "get_yes"
          ? data.delivery_charges_gst_slab
          : null,
    };
    console.log("cash-on-delivery 1", data["cash-on-delivery"]);
    data.payment_availabilities = {
      paytm: paytmData,
      stripe: stripeData,
      "cash-on-delivery": [data["cash-on-delivery"] ? 1 : 0],
    };
    data.payment_settings = payment_settings;
    console.log("payment setting ", JSON.stringify(data));
    setLoader(true);
    const charges = await fetch(
      `${baseApiUrl}restaurants/${restaurantID}/outlets/${data.id}?section=payment_settings`,
      {
        method: "put",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    const chargesJson = await charges.json();

    if (chargesJson.success) {
      alert("successfully edited");
    } else {
      alert("Please try later");
    }
    setLoader(false);
  };
  return (
    <EuiForm
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      style={{ marginTop: 20 }}
    >
      <EuiFlexGroup alignItems="center" style={{ marginTop: "10px" }}>
        <EuiFlexItem grow={false}>
          <EuiFormRow isInvalid={errors.gst_applicable} fullWidth={true}>
            <Controller
              render={({ onChange }) => (
                <EuiRadioGroup
                  options={gst_applicable}
                  idSelected={
                    watch("gst_applicable") ? watch("gst_applicable") : "get_no"
                  }
                  onChange={(id) =>
                    setValue(
                      "gst_applicable",
                      id === "get_no"
                        ? "get_no"
                        : id === "get_yes"
                        ? "get_yes"
                        : "get_no"
                    )
                  }
                  legend={{
                    children: <span> Do you want to apply GST or not ? </span>,
                  }}
                />
              )}
              name="gst_applicable"
              defaultValue={
                watch("gst_applicable") ? watch("gst_applicable") : "get_no"
              }
              control={control}
              fullWidth={true}
            />
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>
      {watch("gst_applicable") === "get_yes" && (
        <>
          <TextField
            errors={errors}
            label="Gst number"
            name="gst_number"
            placeholder="Please enter gst number"
            rules={{ required: "Please enter gst number" }}
            control={control}
            watch={watch}
          />
          <SelectField
            errors={errors}
            label="Delivery charge GST Slub"
            name="delivery_charges_gst_slab"
            options={slub}
            valueOfSelected={
              watch("delivery_charges_gst_slab")
                ? watch("delivery_charges_gst_slab")
                : slub[0].value
            }
            onChangeData={(value) =>
              setValue("delivery_charges_gst_slab", value)
            }
            placeholder="Please select gst slub"
            rules={{
              validate: (value) =>
                (watch("delivery_charges_gst_slab") &&
                  watch("delivery_charges_gst_slab").length > 0) ||
                "Please select delivery slub",
            }}
            control={control}
            watch={watch}
            defaultValue={
              watch("delivery_charges_gst_slab")
                ? watch("delivery_charges_gst_slab")
                : slub[0].value
            }
          />
        </>
      )}

      <ComboBoxField
        options={currencyDataSymbol}
        selectedOptions={
          watch("currency_symbol") ? watch("currency_symbol") : []
        }
        onChangeData={(value) => setValue("currency_symbol", value)}
        errors={errors}
        label="Currency Symbol"
        name="currency_symbol"
        placeholder="Please enter your currency symbol"
        rules={{
          validate: (value) =>
            (watch("currency_symbol") && watch("currency_symbol").length > 0) ||
            "Please select currency symbol",
        }}
        control={control}
        watch={watch}
        singleSelection={{ asPlainText: true }}
      />
      <ComboBoxField
        options={currencyDataCode}
        selectedOptions={watch("currency_code") ? watch("currency_code") : []}
        onChangeData={(value) => {
          setValue("currency_code", value);
          delete errors["currency_code"];
        }}
        errors={errors}
        label="Currency Code"
        name="currency_code"
        placeholder="Please enter your currency code"
        rules={{
          validate: (value) =>
            (watch("currency_code") && watch("currency_code").length > 0) ||
            "Please select currency code",
        }}
        control={control}
        watch={watch}
        singleSelection={{ asPlainText: true }}
      />
      <ComboBoxField
        options={payment_provider_options}
        selectedOptions={
          watch("payment_provider")
            ? watch("payment_provider")
            : payment_provider_options
        }
        onChangeData={onChangePaymentProvider}
        errors={errors}
        label="Select payment provider"
        name="payment_provider"
        placeholder="Select one or more payment provider"
        rules={{
          validate: (value) =>
            (watch("payment_provider") &&
              watch("payment_provider").length > 0) ||
            "Please select atleast one payment provider",
        }}
        control={control}
        watch={watch}
      />
      <EuiFormRow fullWidth>
        <EuiFlexGroup>
          {watch("payment_provider") &&
            watch("payment_provider").map((item) => {
              if (item.label === "paytm") {
                return (
                  <>
                    <EuiFlexItem
                      key={item.id}
                      style={{
                        width: "40%",
                        minWidth: "40%",
                        flex: "0 1 40%",
                      }}
                    >
                      <EuiCard style={{ margin: 10 }}>
                        <EuiText style={{ textAlign: "center" }}>
                          {" "}
                          <b> Paytm</b>{" "}
                        </EuiText>
                        <TextField
                          errors={errors}
                          label="Paytm merchent key"
                          name="paytm_merchent_key"
                          placeholder="Please enter paytm merchent key"
                          rules={{
                            required: "Please enter paytm merchent key",
                          }}
                          control={control}
                          watch={watch}
                        />
                        <TextField
                          errors={errors}
                          label="Paytm merchent id"
                          name="paytm_merchent_id"
                          placeholder="Please enter paytm merchent id"
                          rules={{ required: "Please enter paytm merchent id" }}
                          control={control}
                          watch={watch}
                        />
                        <TextField
                          errors={errors}
                          label="Paytm industry type id"
                          name="paytm_industry_type_id"
                          placeholder="Please enter paytm industry type id"
                          rules={{
                            required: "Please enter paytm industry type id",
                          }}
                          control={control}
                          watch={watch}
                        />
                        <TextField
                          errors={errors}
                          label="Paytm website"
                          name="paytm_website"
                          placeholder="Please enter paytm website"
                          rules={{ required: "Please enter paytm website" }}
                          control={control}
                          watch={watch}
                        />
                        <TextField
                          errors={errors}
                          label="Paytm base api url"
                          name="paytm_base_api_url"
                          placeholder="Please enter paytm base api url"
                          rules={{
                            required: "Please enter paytm base api url",
                          }}
                          control={control}
                          watch={watch}
                        />
                      </EuiCard>
                    </EuiFlexItem>
                  </>
                );
              }
              if (item.label === "stripe") {
                return (
                  <>
                    <EuiFlexItem
                      key={item.id}
                      style={{
                        width: "40%",
                        minWidth: "40%",
                        flex: "0 1 40%",
                      }}
                    >
                      <EuiCard style={{ margin: 10 }}>
                        <EuiText style={{ textAlign: "center" }}>
                          {" "}
                          <b> Stripe </b>{" "}
                        </EuiText>
                        <TextField
                          errors={errors}
                          label="Stripe key"
                          name="stripe_key"
                          placeholder="Please enter stripe key"
                          rules={{ required: "Please enter stripe key" }}
                          control={control}
                          watch={watch}
                        />
                        <TextField
                          errors={errors}
                          label="Stripe secret"
                          name="stripe_secret"
                          placeholder="Please enter stripe secret"
                          rules={{ required: "Please enter stripe secret" }}
                          control={control}
                          watch={watch}
                        />
                        <TextField
                          errors={errors}
                          label="Stripe webhook"
                          name="stripe_webhook"
                          placeholder="Please enter stripe webhook"
                          rules={{ required: "Please enter stripe webhook" }}
                          control={control}
                          watch={watch}
                        />
                        <TextField
                          errors={errors}
                          label="Stripe webhook secret"
                          name="stripe_webhook_secret"
                          placeholder="Please enter webhook secret"
                          rules={{ required: "Please enter webhook secret" }}
                          control={control}
                          watch={watch}
                        />
                      </EuiCard>
                    </EuiFlexItem>
                  </>
                );
              }
            })}
        </EuiFlexGroup>
      </EuiFormRow>

      <EuiFormRow>
        <EuiFlexGroup direction="column">
          {watch("payment_provider") && watch("payment_provider").length > 0 && (
            <EuiFlexItem>
              <SelectField
                errors={errors}
                label="Credit card / Dabit card"
                name="credit_card"
                options={
                  credit
                    ? credit
                    : watch("cardProvider")
                    ? watch("cardProvider")
                    : []
                }
                valueOfSelected={
                  watch("credit_card")
                    ? watch("credit_card")
                    : watch("cardProvider")
                }
                onChangeData={(value) => setValue("credit_card", value)}
                placeholder="Please select credit card / dabit card"
                rules={{ required: "Please select credit card / dabit card" }}
                control={control}
                watch={watch}
                defaultValue={
                  watch("credit_card")
                    ? watch("credit_card")
                    : watch("cardProvider")
                }
              />
            </EuiFlexItem>
          )}

          {watch("payment_provider") &&
            watch("payment_provider").map((item) => {
              if (item.label === "paytm") {
                return (
                  <>
                    <EuiFlexItem key={item.id}>
                      <SelectField
                        errors={errors}
                        label="Net banking"
                        name="net_banking"
                        options={optionsPaytm}
                        valueOfSelected={optionsPaytm[0].value}
                        placeholder="Please select net banking"
                        rules={{ required: "Please select net banking" }}
                        control={control}
                        watch={watch}
                        defaultValue={optionsPaytm[0].value}
                      />
                      <SelectField
                        errors={errors}
                        label="Balance"
                        name="balance"
                        options={optionsPaytm}
                        valueOfSelected={optionsPaytm[0].value}
                        placeholder="Please select balance"
                        rules={{ required: "Please select balance" }}
                        control={control}
                        watch={watch}
                        defaultValue={optionsPaytm[0].value}
                      />

                      <SelectField
                        errors={errors}
                        label="UPI"
                        name="upi"
                        options={optionsPaytm}
                        valueOfSelected={optionsPaytm[0].value}
                        placeholder="Please select upi"
                        rules={{ required: "Please select upi" }}
                        control={control}
                        watch={watch}
                        defaultValue={optionsPaytm[0].value}
                      />

                      <SelectField
                        errors={errors}
                        label="Upi intent"
                        name="upi_intent"
                        options={optionsPaytm}
                        valueOfSelected={optionsPaytm[0].value}
                        placeholder="Please select upi intent"
                        rules={{ required: "Please select upi intent" }}
                        control={control}
                        watch={watch}
                        defaultValue={optionsPaytm[0].value}
                      />
                    </EuiFlexItem>
                  </>
                );
              }
            })}

          <EuiFormRow style={{ marginLeft: 15 }}>
            <EuiFlexGroup alignItems="center">
              <EuiFlexItem grow={false}>
                <CheckBoxField
                  name="cash-on-delivery"
                  defaultValue={false}
                  control={control}
                  setValue={setValue}
                />
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiText>Case on delivery</EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFormRow>
        </EuiFlexGroup>
      </EuiFormRow>
      <EuiButton isLoading={loader} type="submit" fill>
        Update Payment Settings
      </EuiButton>
    </EuiForm>
  );
};

export default PaymentSetting;
