import React from "react";

import { useQuery } from "react-query";
import {
  EuiTable,
  EuiTableHeader,
  EuiTableBody,
  EuiTableHeaderCell,
  EuiCheckbox,
  EuiTableRowCell,
  EuiTableRow,
} from "@elastic/eui";
import {
  getOutletList,
  getPermissionAboutUserByOutlet,
} from "../../api/outletAPICall";
import { baseApiUrl } from "../../envConfig";
import { useSelector } from "react-redux";
import { queryCache } from "react-query";

const UserOutletPermission = ({ user, value, selectedUserId }) => {
  console.log("permission user", user);
  console.log("permission value", value);
  const restaurantID = useSelector((state) => state.restaurant.restaurantId);
  const token = useSelector((state) => state.auth.token);

  const {
    isLoading,
    error,
    data: outletData,
  } = useQuery(`getAllOutlet${value}${selectedUserId}`, getOutletList);

  const {
    isLoading: permissionAccessLoading,
    error: permissionError,
    data: permission,
  } = useQuery(
    [`getPermissionAboutUserByOutlet${value}${selectedUserId}`, selectedUserId],
    getPermissionAboutUserByOutlet,
    {
      skip: !outletData,
    }
  );
  if (isLoading || permissionAccessLoading) {
    return <h1> Loading... </h1>;
  }
  if (error || permissionError) {
    return <h1> Error </h1>;
  }
  console.log("outletData", outletData);
  let object = {};
  console.log("outlet info", outletData.outlets);
  outletData.outlets.map((item) => {
    object[item.outlet_id] = false;
  });

  permission.userPermission.map((item) => {
    object[item.outlet.outlet_id] = true;
  });

  const onChangePermission = async (e, outlet_id) => {
    if (e.target.checked) {
      await fetch(
        `${baseApiUrl}restaurants/${restaurantID}/outlets/${outlet_id}/users/${selectedUserId}/link`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        }
      );
    } else {
      await fetch(
        `${baseApiUrl}restaurants/${restaurantID}/outlets/${outlet_id}/users/${selectedUserId}/un-link`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        }
      );
    }
    queryCache.invalidateQueries(
      `getPermissionAboutUserByOutlet${value}${selectedUserId}`
    );
  };
  return (
    <>
      <EuiTable>
        <EuiTableHeader>
          <EuiTableHeaderCell key="access_module">
            {" "}
            Outlet List{" "}
          </EuiTableHeaderCell>
          <EuiTableHeaderCell key="access_module">
            {" "}
            permission{" "}
          </EuiTableHeaderCell>
        </EuiTableHeader>
        {/* {  console.log("outletData",outletData)} */}
        {outletData.outlets.map((item, index) => {
          let outelnames = Object.values(item.translations.name);
          return (
            <EuiTableRow key={item.outlet_id}>
              <EuiTableRowCell> {outelnames[0]} </EuiTableRowCell>
              <EuiTableRowCell>
                <EuiCheckbox
                  checked={object[item.outlet_id]}
                  onClick={(e) => onChangePermission(e, item.outlet_id)}
                />
              </EuiTableRowCell>
            </EuiTableRow>
          );
        })}

        {/* <EuiTableBody>  </EuiTableBody> */}
      </EuiTable>
    </>
  );
};

export default UserOutletPermission;
