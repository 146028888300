import * as types from "../types/language";

const initialState = {
  language: "en",
  availableLanguages: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };
    case types.SET_AVAILABLE_LANGUAGES:
      return {
        ...state,
        availableLanguages: action.payload,
      };
    case types.SET_ALL_AVAILABLE_LANGUAGES:
      return {
        ...state,
        availableLanguages: action.payload.availableLanguages,
      };
    default:
      return state;
  }
};
