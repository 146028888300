import React, { useEffect, useState, useRef } from "react";
import {
  EuiFlexGroup,
  EuiText,
  EuiFlexItem,
  EuiComboBox,
  EuiFormRow,
  EuiDatePicker,
  EuiCheckboxGroup,
  EuiButton,
  EuiCheckbox,
  EuiForm,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiModalFooter,
  EuiButtonEmpty,
  EuiCopy,
  EuiTextColor,
  EuiButtonIcon,
} from "@elastic/eui";
import { getOutletList } from "../../api/plans/fetchOutletList";
import { submitPlan } from "../../api/plans/submitPlan";
import { useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import NumberField from "../Form/NumberField";
import TextField from "../Form/TextField";
import { isEmpty } from "lodash";

const RestaurantPlans = (props) => {
  const { selectedRestaurant } = props;
  const dispatch = useDispatch();
  const [outletOptions, setOutletOptions] = useState([]);
  const [selectedOutlets, setSelectedOutlets] = useState([]);
  const [joinDate, setJoinDate] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [url, setUrl] = useState("");
  const [errorMessage, setErrorMessage] = useState({});
  const [showError, setShowError] = useState(false);

  const montlyRef = useRef(null);
  const quaterlyRef = useRef(null);
  const halfYearlyRef = useRef(null);
  const yearlyRef = useRef(null);

  const onWheel = () => {
    montlyRef && montlyRef.current && montlyRef.current.blur();
    quaterlyRef && quaterlyRef.current && quaterlyRef.current.blur();
    halfYearlyRef && halfYearlyRef.current && halfYearlyRef.current.blur();
    yearlyRef && yearlyRef.current && yearlyRef.current.blur();
  };

  const { register, handleSubmit, errors, control, watch, reset, setValue } =
    useForm({});

  useEffect(() => {
    let outletArray = [];
    dispatch(getOutletList(selectedRestaurant[0].id)).then((res) => {
      if (res.success) {
        res.outlets.map((outlet) => {
          let outletKey = Object.keys(outlet.translations.name)[0];
          let outletName = outlet.translations.name[outletKey];
          outletArray.push({
            ...outlet,
            id: outlet.outlet_id,
            label: outletName,
          });
        });

        setOutletOptions(outletArray);
      } else {
        alert("Error in fetching outlets");
      }
    });
  }, [selectedRestaurant]);

  const checkboxes = [
    {
      id: "monthly",
      label: "Monthly (28 days)",
    },
    {
      id: "quaterly",
      label: "Quaterly (90 days)",
    },
    {
      id: "half_quaterly",
      label: "Half Quaterly (180 days)",
    },
    {
      id: "yearly",
      label: "Yearly (365 days)",
    },
  ];

  const [checkboxIdToSelectedMap, setCheckboxIdToSelectedMap] = useState({});

  const onChange = (selectedOptions) => {
    setSelectedOutlets(selectedOptions);
  };

  const handleChange = (date) => {
    setJoinDate(date);
  };

  const onDurationChange = (optionId) => {
    const newCheckboxIdToSelectedMap = {
      ...checkboxIdToSelectedMap,
      ...{
        [optionId]: !checkboxIdToSelectedMap[optionId],
      },
    };
    setCheckboxIdToSelectedMap(newCheckboxIdToSelectedMap);
  };

  const onSubmit = (data) => {
    let innerObj = {};
    let plans = [];
    if (data.monthly) {
      plans.push({
        duration: "28",
        amount: data.monthly_charges,
      });
    }

    if (data.quaterly) {
      plans.push({
        duration: "90",
        amount: data.quaterly_charges,
      });
    }

    if (data.haly_yearly) {
      plans.push({
        duration: "180",
        amount: data.half_yearly_charges,
      });
    }

    if (data.yearly) {
      plans.push({
        duration: "365",
        amount: data.yearly_charges,
      });
    }

    innerObj["plans"] = plans;

    let outlet_ids = [];
    selectedOutlets.map((outlet) => {
      outlet_ids.push(outlet.id);
    });

    innerObj["outlet_ids"] = outlet_ids;

    dispatch(submitPlan(selectedRestaurant[0].id, innerObj)).then((res) => {
      if (res.success) {
        setUrl(res.plans);
        setIsModalVisible(true);
      } else {
        if (res.errors) {
          setErrorMessage(res.errors);
          setShowError(true);
        }
        alert("Fail to submit data");
      }
    });
  };

  const closeModal = () => {
    setIsModalVisible(false);
    setSelectedOutlets([]);
    reset({
      monthly: undefined,
      quaterly: undefined,
      half_yearly: undefined,
      yearly: undefined,
    });
  };

  let modal;

  if (isModalVisible) {
    modal = (
      <EuiModal onClose={closeModal} initialFocus="[name=popswitch]">
        <EuiModalHeader>
          <EuiModalHeaderTitle>
            <h1>Modal title</h1>
          </EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody>
          <TextField
            errors={errors}
            label="Sharable url"
            name="url"
            control={control}
            watch={watch}
            disabled={true}
            defaultValue={url}
            append={
              <EuiCopy textToCopy={url}>
                {(copy) => <EuiText onClick={copy}>Copy</EuiText>}
              </EuiCopy>
            }
          />
        </EuiModalBody>

        <EuiModalFooter>
          <EuiButton type="submit" form="modalFormId" onClick={closeModal} fill>
            Close
          </EuiButton>
        </EuiModalFooter>
      </EuiModal>
    );
  }

  // display error from server side
  const displayErrorServer = () => {
    let errorsList = [];
    if (!isEmpty(errorMessage)) {
      for (var key in errorMessage) {
        errorsList.push(
          <EuiTextColor color="danger">{errorMessage[key]}</EuiTextColor>
        );
      }
    }
    return errorsList;
  };

  return (
    <>
      {modal}
      <EuiForm component="form" onSubmit={handleSubmit(onSubmit)}>
        <EuiFlexItem>
          <EuiFormRow label="Outlets">
            <EuiComboBox
              placeholder="Select outlet"
              options={outletOptions}
              selectedOptions={selectedOutlets}
              onChange={onChange}
              isClearable={true}
            />
          </EuiFormRow>

          {/* <EuiFormRow label="Join Date">
                        <EuiDatePicker
                            selected={joinDate}
                            onChange={handleChange}
                            onClear={() => handleChange(null)}
                            placeholder="Joining date"
                        />
                    </EuiFormRow> */}

          {/* <EuiFlexItem style={{display: 'flex', flexDirection: 'row'}}>
                        <EuiFlexItem>
                            <EuiFormRow label="Plan Duration">
                                <EuiCheckboxGroup
                                    options={checkboxes}
                                    idToSelectedMap={checkboxIdToSelectedMap}
                                    onChange={(id) => onDurationChange(id)}
                                />
                            </EuiFormRow>
                        </EuiFlexItem>
                    </EuiFlexItem> */}

          {/* Monthly plan */}
          <EuiFlexGroup alignItems="center" style={{ padding: "10px 0px" }}>
            <EuiFlexItem grow={false}>
              <Controller
                render={({ onChange, value }) => (
                  <EuiCheckbox
                    onChange={(e) => {
                      onChange(e.target.checked);
                      setValue("monthly", e.target.checked);
                    }}
                    checked={value}
                    compressed
                  />
                )}
                name="monthly"
                control={control}
              />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiText>Monthly (28 days)</EuiText>
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
              {watch("monthly") ? (
                <NumberField
                  errors={errors}
                  name="monthly_charges"
                  placeholder="Monthly Charges"
                  rules={{ required: "Please enter monthly charges" }}
                  control={control}
                  watch={watch}
                  inputRef={montlyRef}
                  onWheel={onWheel}
                />
              ) : (
                ""
              )}
            </EuiFlexItem>
          </EuiFlexGroup>

          {/* Quaterly plan */}
          <EuiFlexGroup alignItems="center" style={{ padding: "10px 0px" }}>
            <EuiFlexItem grow={false}>
              <Controller
                render={({ onChange, value }) => (
                  <EuiCheckbox
                    onChange={(e) => {
                      onChange(e.target.checked);
                      setValue("quaterly", e.target.checked);
                    }}
                    checked={value}
                    compressed
                  />
                )}
                name="quaterly"
                control={control}
              />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiText>Quaterly (90 days)</EuiText>
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
              {watch("quaterly") ? (
                <NumberField
                  errors={errors}
                  name="quaterly_charges"
                  placeholder="Quaterly Charges"
                  rules={{ required: "Please enter quaterly charges" }}
                  control={control}
                  watch={watch}
                  inputRef={quaterlyRef}
                  onWheel={onWheel}
                />
              ) : (
                ""
              )}
            </EuiFlexItem>
          </EuiFlexGroup>

          {/* Half yearly plan */}
          <EuiFlexGroup alignItems="center" style={{ padding: "10px 0px" }}>
            <EuiFlexItem grow={false}>
              <Controller
                render={({ onChange, value }) => (
                  <EuiCheckbox
                    onChange={(e) => {
                      onChange(e.target.checked);
                      setValue("half_yearly", e.target.checked);
                    }}
                    checked={value}
                    compressed
                  />
                )}
                name="half_yearly"
                control={control}
              />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiText>Half Yearly (180 days)</EuiText>
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
              {watch("half_yearly") ? (
                <NumberField
                  errors={errors}
                  name="half_yearly_charges"
                  placeholder="Half yearly Charges"
                  rules={{ required: "Please enter half yearly charges" }}
                  control={control}
                  watch={watch}
                  inputRef={halfYearlyRef}
                  onWheel={onWheel}
                />
              ) : (
                ""
              )}
            </EuiFlexItem>
          </EuiFlexGroup>

          {/* Yearly plan */}
          <EuiFlexGroup alignItems="center" style={{ padding: "10px 0px" }}>
            <EuiFlexItem grow={false}>
              <Controller
                render={({ onChange, value }) => (
                  <EuiCheckbox
                    onChange={(e) => {
                      onChange(e.target.checked);
                      setValue("yearly", e.target.checked);
                    }}
                    checked={value}
                    compressed
                  />
                )}
                name="yearly"
                control={control}
              />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiText>Yearly (365 days)</EuiText>
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
              {watch("yearly") ? (
                <NumberField
                  errors={errors}
                  name="yearly_charges"
                  placeholder="Yearly Charges"
                  rules={{ required: "Please enter yearly charges" }}
                  control={control}
                  watch={watch}
                  inputRef={yearlyRef}
                  onWheel={onWheel}
                />
              ) : (
                ""
              )}
            </EuiFlexItem>
          </EuiFlexGroup>

          {!isEmpty(errorMessage) && showError && (
            <EuiFlexGroup
              direction="column"
              style={{
                backgroundColor: "#f8e9e9",
                margin: "10px",
                padding: "15px",
                borderRadius: "5px",
              }}
            >
              <EuiFlexItem
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <EuiTextColor color="danger" style={{ paddingBottom: "10px" }}>
                  <b>Please resolve below validations</b>
                </EuiTextColor>
                <EuiButtonIcon
                  color={"warning"}
                  onClick={() => setShowError(false)}
                  iconType="cross"
                  aria-label="cross"
                />
              </EuiFlexItem>
              {displayErrorServer()}
            </EuiFlexGroup>
          )}

          <EuiButton type="submit" fill style={{ width: "200px" }}>
            Submit
          </EuiButton>
        </EuiFlexItem>
      </EuiForm>
    </>
  );
};

export default RestaurantPlans;
