import React, { useEffect } from "react";
import { MainLayout } from "../components/layout/mainLayout";
import { UniversalOrder } from "../components/dashboardComponents/universalOrder";
import {
  EuiText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiSpacer,
} from "@elastic/eui";
import { OutletOrders } from "../components/dashboardComponents/outletOrders";
import { VisitorCard } from "../components/dashboardComponents/visitorCard";
import { DeliveryCost } from "../components/dashboardComponents/deliveryCost";
import { CompareWidget } from "../components/dashboardComponents/compareWidget";
import { useSelector } from "react-redux";

export const DashboardPage = () => {
  const outletList = useSelector((state) => state.outlet.outletList);

  return (
    <>
      <MainLayout>
        <EuiText>
          <h2>Dashboard</h2>
        </EuiText>
        {/* <EuiFlexGroup style={{display: 'none !important'}}>
          <EuiFlexItem grow={10}>
            <UniversalOrder />
          </EuiFlexItem>
        </EuiFlexGroup> */}

        {outletList.map((outlet) => (
          <div key={outlet.id}>
            <EuiText>
              <h3>
                {outlet.name} | {outlet.address}
              </h3>
            </EuiText>
            <EuiSpacer size="xs" />
            <OutletOrders outletId={outlet.id} />
            <EuiSpacer size="m" />
            <EuiFlexGroup>
              <EuiFlexItem>
                <VisitorCard outletId={outlet.id} />
              </EuiFlexItem>
              <EuiFlexItem>
                <DeliveryCost outletId={outlet.id} />
              </EuiFlexItem>
            </EuiFlexGroup>
            <CompareWidget outletId={outlet.id} />
            <EuiHorizontalRule />
          </div>
        ))}
      </MainLayout>
    </>
  );
};
