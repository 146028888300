import * as types from "../types/plans";

const initialState = {
  restaurantList: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_RESTAURANT_LIST:
      return {
        ...state,
        restaurantId: action.payload.restaurantId,
      };
    default:
      return state;
  }
};
