import superagent from "../config/superagent";

export const loginAPI = async (email, password, device_id, platform) => {
  let apiRes;
  try {
    apiRes = await superagent
      .post("login")
      .send({
        email: email,
        password: password,
        device_id: device_id,
        platform: platform,
      });
    apiRes.apiError = false;
  } catch (err) {
    apiRes = err;
    apiRes.apiError = true;
  }
  return apiRes;
};

export const logoutAPI = async () => {
  let apiRes;
  try {
    apiRes = await superagent.post("en/logout");
    apiRes.apiError = false;
  } catch (err) {
    apiRes = err;
    apiRes.apiError = true;
  }
  return apiRes;
};
