import React, { useEffect, useState } from "react";
import {
  EuiForm,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiCopy,
  EuiText,
  EuiButtonEmpty,
} from "@elastic/eui";
import TextField from "../../Form/TextField";
import SwitchField from "../../Form/SwitchField";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { baseApiUrl } from "../../../envConfig";
import { store } from "../../../reduxStore/store";
const ThirdPartyRider = ({ getOutletData }) => {
  const { handleSubmit, errors, control, watch, reset, setValue } = useForm();
  const [loader, setLoader] = useState(false);
  const restaurantID = useSelector((state) => state.restaurant.restaurantId);
  var token = store.getState().auth.token;
  useEffect(() => {
    if (getOutletData) {
      const outletData = getOutletData;
      let defaultValue = {};
      defaultValue.elt_api_key =
        outletData.third_party_rider_setting &&
        outletData.third_party_rider_setting.elt_api_key;
      defaultValue.elt_primary_address_id =
        outletData.third_party_rider_setting &&
        outletData.third_party_rider_setting.elt_primary_address_id;
      defaultValue.call_back_url =
        outletData.third_party_rider_setting &&
        outletData.third_party_rider_setting.call_back_url;
      defaultValue.elt_integration_available =
        outletData.third_party_rider_setting &&
        outletData.third_party_rider_setting.elt_integration_available;
      console.log("defaultValue", defaultValue);
      // set form default value
      reset({
        ...defaultValue,
      });
    }
  }, [getOutletData]);

  // on submit this method is call
  const onSubmit = async (data) => {
    data.id = null;
    if (getOutletData) {
      data.id = getOutletData.outlet_id;
    }

    data.elt_integration_available = data.elt_integration_available ? 1 : 0;

    delete data.call_back_url;
    // update ELT information api call
    setLoader(true);
    try {
      const ELTRider = await fetch(
        `${baseApiUrl}restaurants/${restaurantID}/outlets/${data.id}/third-party-rider-setting`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      const ELTRiderJson = await ELTRider.json();
      if (ELTRiderJson.success) {
        alert("successfully edited");
      } else {
        alert("Please try later");
      }
    } catch (e) {
      alert("Please try later");
      setLoader(false);
    }

    setLoader(false);
  };
  return (
    <EuiForm
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      style={{ marginTop: 20 }}
    >
      <TextField
        errors={errors}
        label="Api Key"
        name="elt_api_key"
        placeholder="Enter ELT api key"
        rules={{ required: "Please Enter ELT api key" }}
        control={control}
        watch={watch}
      />
      <TextField
        errors={errors}
        label="Primary address id"
        name="elt_primary_address_id"
        placeholder="Enter primary address id"
        rules={{ required: "Please enter primary address id" }}
        control={control}
        watch={watch}
      />
      <TextField
        errors={errors}
        label="Url"
        name="call_back_url"
        placeholder="Enter url"
        rules={{ required: "Please enter url" }}
        control={control}
        watch={watch}
        disabled={true}
        append={
          <EuiCopy textToCopy={watch("call_back_url")}>
            {(copy) => <EuiText onClick={copy}>Copy</EuiText>}
          </EuiCopy>
        }
      />

      <EuiFlexGroup
        alignItems="center"
        style={{ marginTop: 5, marginBottom: 5 }}
      >
        <EuiFlexItem grow={false}>
          <SwitchField
            name="elt_integration_available"
            defaultValue={
              watch("elt_integration_available")
                ? watch("elt_integration_available")
                : false
            }
            control={control}
            setValue={setValue}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiText>ELT on/off</EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiButton
        style={{ marginTop: 10 }}
        isLoading={loader}
        type="submit"
        fill
      >
        Update third party riders
      </EuiButton>
    </EuiForm>
  );
};

export default ThirdPartyRider;
