import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EuiText, EuiButton, EuiDataGrid } from "@elastic/eui";
import { useHistory } from "react-router-dom";
import { useQuery, queryCache } from "react-query";
import { getAllOutletIntoRestaurant } from "../../../api/restaurantsAPICall";
import { baseApiUrl } from "../../../envConfig";
import API from "../../../api/axios/API";
const ListOutlet = (restaurantId) => {
  //  const listOutlet = JSON.parse(localStorage.getItem("form_list"));
  //  console.log("LocalStorage data", listOutlet);

  const {
    isLoading: loading,
    error,
    data: getAllOutlet,
  } = useQuery(
    ["getAllOutlet", restaurantId.restaurantID],
    getAllOutletIntoRestaurant
  );

  const dispatch = useDispatch();
  let history = useHistory();
  const token = useSelector((state) => state.auth.token);
  const columns = [
    {
      id: "name",
    },
    {
      id: "contact_number",
    },
    {
      id: "city",
    },
    {
      id: "state",
    },
    {
      id: "iso_country_code",
    },
    {
      id: "edit",
    },
    {
      id: "delete",
    },
  ];

  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });

  const handleDeleteOutlet = async (id) => {
    if (window.confirm("Are you sure you want to delete this record ?")) {
      const restaurantJson = await API.delete(
        `restaurants/${restaurantId.restaurantID}/outlets/${id}`
      );
      if (restaurantJson.success) {
        window.alert("Deleted successfully");
        queryCache.invalidateQueries("getAllOutlet");
      } else {
        window.alert("Please try later");
      }
    }
  };

  const onChangeItemsPerPage = useCallback(
    (pageSize) =>
      setPagination((pagination) => ({
        ...pagination,
        pageSize,
        pageIndex: 0,
      })),
    [setPagination]
  );
  const onChangePage = useCallback(
    (pageIndex) =>
      setPagination((pagination) => ({ ...pagination, pageIndex })),
    [setPagination]
  );

  const [sortingColumns, setSortingColumns] = useState([]);

  const onSort = useCallback(
    (sortingColumns) => {
      setSortingColumns(sortingColumns);
    },
    [setSortingColumns]
  );

  const [visibleColumns, setVisibleColumns] = useState(() =>
    columns.map(({ id }) => id)
  );
  console.log("getAllOutlet", getAllOutlet);
  let mappedItems =
    getAllOutlet && getAllOutlet.outlets
      ? getAllOutlet.outlets.map((item) => {
          let outelnames = Object.values(item.translations.name);
          let city = Object.values(item.outlet_address.translations.city);
          let state = Object.values(item.outlet_address.translations.state);
          return {
            name: (
              <>{<EuiText key={item.id + "name"}>{outelnames[0]}</EuiText>}</>
            ),
            contact_number: (
              <>
                {
                  <EuiText>
                    {item.outlet_address
                      ? `${item.outlet_address.country_code} ${item.outlet_address.contact_number}`
                      : ""}
                  </EuiText>
                }
              </>
            ),
            city: (
              <>
                {
                  <EuiText key={item.id + "city"}>
                    {item.outlet_address ? city[0] : ""}
                  </EuiText>
                }
              </>
            ),
            state: (
              <>
                {
                  <EuiText key={item.id + "state"}>
                    {item.outlet_address ? state[0] : ""}
                  </EuiText>
                }
              </>
            ),
            iso_country_code: (
              <>
                {
                  <EuiText key={item.id + "iso_country_code"}>
                    {item.outlet_address
                      ? item.outlet_address.iso_country_code
                      : ""}
                  </EuiText>
                }
              </>
            ),
            edit: (
              <>
                <EuiButton
                  iconType="cross"
                  onClick={() => handleEditOutlet(item.outlet_id)}
                >
                  Edit
                </EuiButton>
              </>
            ),
            delete: (
              <>
                <EuiButton
                  iconType="cross"
                  onClick={() => handleDeleteOutlet(item.outlet_id)}
                >
                  Delete
                </EuiButton>
              </>
            ),
          };
        })
      : [];

  const renderCellValue = useMemo(() => {
    return ({ rowIndex, columnId, setCellProps }) => {
      return mappedItems.hasOwnProperty(rowIndex)
        ? mappedItems[rowIndex][columnId]
        : null;
    };
  }, [mappedItems]);

  const handleEditOutlet = async (id) => {
    const editOutletJson = await API.get(
      `restaurants/${restaurantId.restaurantID}/outlets/${id}`
    );

    console.log("editOutlet json ", editOutletJson);
    dispatch({
      type: "set-edit-outlet",
      payload: {
        editOutlet: editOutletJson.outlet,
      },
    });
    dispatch({
      type: "set-restaurantId",
      payload: {
        restaurantId: editOutletJson.outlet.restaurant_id,
      },
    });
    history.push("/edit-outlet");
  };

  if (loading) {
    return <h1>Loading</h1>;
  }
  if (error) {
    return <h1> Error</h1>;
  }
  return (
    <>
      <EuiDataGrid
        aria-label="Restaurant List Datagrid"
        columns={columns}
        columnVisibility={{ visibleColumns, setVisibleColumns }}
        rowCount={mappedItems.length}
        renderCellValue={renderCellValue}
        sorting={{ columns: sortingColumns, onSort }}
        pagination={{
          ...pagination,
          pageSizeOptions: [10, 50, 100],
          onChangeItemsPerPage: onChangeItemsPerPage,
          onChangePage: onChangePage,
        }}
      />
    </>
  );
};

export default ListOutlet;
