import React from "react";
import { EuiFormRow, EuiRadioGroup } from "@elastic/eui";
import { Controller } from "react-hook-form";

const RadioField = (props) => {
  const { label, name, options, idSelected, onChangeData, control, fullWidth } =
    props;
  return (
    <EuiFormRow fullWidth={true}>
      <Controller
        render={({ onChange }) => (
          <EuiRadioGroup
            options={options}
            idSelected={idSelected}
            onChange={onChangeData}
            legend={{
              children: <span> {label} </span>,
            }}
          />
        )}
        name={name}
        control={control}
        fullWidth={fullWidth ? true : false}
      />
    </EuiFormRow>
  );
};

export default RadioField;
