import React from "react";
import { MainLayout } from "../components/layout/mainLayout";
import EditOutlet from "../components/restaurants/editOutlet/editOutlet";

const EditOutlets = () => {
  return (
    <MainLayout>
      <EditOutlet />
    </MainLayout>
  );
};

export default EditOutlets;
