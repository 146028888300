import API from "../axios/API";
import * as types from "../../reduxStore/types/plans";

export const getRestaurantList = () => {
  return async (dispatch, getState) => {
    let restaurantResponse;

    try {
      restaurantResponse = await API.get("restaurants/");
    } catch (error) {
      restaurantResponse = error;
    }

    return restaurantResponse;
  };
};
