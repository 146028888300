import React, { useState } from "react";
import {
  EuiTable,
  EuiTableHeader,
  EuiTableBody,
  EuiTableHeaderCell,
  EuiRadio,
  EuiTableRowCell,
  EuiTableRow,
  EuiButtonEmpty,
} from "@elastic/eui";
import { useDispatch, useSelector } from "react-redux";
import { baseApiUrl } from "../../envConfig";

const UserListBasedOnRestaurant = (props) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const restaurantID = useSelector((state) => state.restaurant.restaurantId);
  const column = [
    {
      id: "radioSelect",
      width: "32px",
    },
    {
      id: "name",
      label: "Name",
    },
    {
      id: "number",
      label: "Number",
    },
    {
      id: "email",
      label: "Email",
    },
    {
      id: "edit",
      label: "Edit",
    },
    {
      id: "delete",
      label: "Delete",
    },
  ];

  const onChange = (id) => {
    // setState(parseInt(e.target.id))
    props.setSelectedUser(id);
  };

  const renderHeaderCell = () => {
    const headers = [];

    column.map((column) => {
      headers.push(
        <EuiTableHeaderCell width={column.width} key={column.id}>
          {" "}
          {column.label}
        </EuiTableHeaderCell>
      );
    });

    return headers.length ? headers : null;
  };
  const editUser = async (id) => {
    // await API.get('restaurants/:restaurantId/users')
    const allUser = await fetch(
      `${baseApiUrl}restaurants/${restaurantID}/users`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      }
    );
    const allUserJson = await allUser.json();
    console.log("allUser", allUserJson);
    let filterUser = null;
    filterUser =
      allUserJson &&
      allUserJson.users &&
      allUserJson.users.filter((item, index) => {
        if (item.user.id === id) {
          return item.user;
        }
      })[0];

    dispatch({
      type: "edit_user",
      payload: {
        editUser: filterUser,
      },
    });
    props.openFlyOut();
  };
  const deleteUser = async (id) => {
    if (window.confirm("Are you sure you want to delete this record ?")) {
      const deleteUser = await fetch(
        `${baseApiUrl}restaurants/${restaurantID}/users/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        }
      );
      const deleteJsonUser = await deleteUser.json();
      if (deleteJsonUser.success) {
        alert("Delted succefully");
      } else {
        alert("Please try late");
      }
    }
  };
  const renderRows = () => {
    const rows = props.users["users"].map((item) => {
      let user = item["user"];
      return (
        <EuiTableRow key={user["id"]}>
          <EuiTableRowCell width="32px">
            <EuiRadio
              id={user["id"]}
              checked={props.selectedUser === user["id"]}
              onChange={(e) => onChange(user["id"])}
            />
          </EuiTableRowCell>
          <EuiTableRowCell>{user["name"]}</EuiTableRowCell>
          <EuiTableRowCell>{user["mobile_number"]}</EuiTableRowCell>
          <EuiTableRowCell>{user["email"]}</EuiTableRowCell>
          <EuiTableRowCell
            width="32px"
            style={{ textAlign: "center" }}
            onClick={() => editUser(user["id"])}
          >
            {" "}
            <EuiButtonEmpty iconType="documentEdit"> Edit </EuiButtonEmpty>{" "}
          </EuiTableRowCell>
          <EuiTableRowCell
            width="32px"
            style={{ textAlign: "center" }}
            onClick={() => deleteUser(user["id"])}
          >
            <EuiButtonEmpty iconType="cross"> Delete </EuiButtonEmpty>
          </EuiTableRowCell>
        </EuiTableRow>
      );
    });

    return rows;
  };
  return (
    <>
      {props.users && (
        <EuiTable>
          <EuiTableHeader>{renderHeaderCell()}</EuiTableHeader>
          <EuiTableBody width="32px">{renderRows()}</EuiTableBody>
        </EuiTable>
      )}
    </>
  );
};

export default UserListBasedOnRestaurant;
