import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  EuiFlexGroup,
  EuiText,
  EuiForm,
  EuiFlexItem,
  EuiFormRow,
  EuiFieldText,
  EuiButton,
  EuiRadioGroup,
  EuiCheckbox,
  EuiCard,
  EuiToolTip,
} from "@elastic/eui";
import uuid from "uuid";
import { useForm, Controller } from "react-hook-form";
import { useMutation } from "react-query";
import {
  addOutletIntoRestaurant,
  editOutletIntoRestaurant,
} from "../../../api/restaurantsAPICall";
import { allCountry } from "../allCountry";
import { currencyData } from "../countrySymbolAndInforamtion";
import TimeSlot from "../../Form/TimeSlot";
import NumberField from "../../Form/NumberField";
import TextAreaField from "../../Form/TextAreaField";
import RadioField from "../../Form/RadioField";
import SelectField from "../../Form/SelectField";
import KitchenTimeSlot from "../../Form/kitchenTimeSlot";
import TextField from "../../Form/TextField";
import CheckBoxField from "../../Form/CheckBoxField";
import SwitchField from "../../Form/SwitchField";
import ComboBoxField from "../../Form/ComboBoxField";
import { formatedData } from "./formatedData";
import { formatedDataForKitchen } from "./formatedKitchenData";
import MobileNumber from "../../Form/MobileNumber";
import { isEmpty } from "lodash";

const CreateOutlet = ({ value }) => {
  const languages = useSelector((state) => state.language.availableLanguages);
  const [mutate, info] = useMutation(addOutletIntoRestaurant);
  const [editmutate, editinfo] = useMutation(editOutletIntoRestaurant);
  const editOutlet = useSelector((state) => state.outlet.editOutlet);
  const [loader, setLoader] = useState(false);
  console.log(value);
  const defaultValue = {};
  let paymentProvider = [];
  let cardProvider = [];
  const [timeSlotError, setTimeSlotError] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch({
        type: "set-edit-outlet",
        payload: {
          editOutlet: null,
        },
      });
    };
  }, []);

  useEffect(() => {
    if (editOutlet) {
      console.log("editOutlet", editOutlet);
      const outletData = editOutlet;
      let translationObject = {
        name: {},
        city: {},
        state: {},
        address_line_1: {},
        address_line_2: {},
      };

      for (const languageId in outletData.translations.name) {
        translationObject.name[languageId] =
          outletData.translations.name[languageId];
      }

      for (const languageId in outletData.outlet_address.translations.city) {
        translationObject.city[languageId] =
          outletData.outlet_address.translations.city[languageId];
      }
      for (const languageId in outletData.outlet_address.translations.state) {
        translationObject.state[languageId] =
          outletData.outlet_address.translations.state[languageId];
      }
      for (const languageId in outletData.outlet_address.translations
        .address_line_1) {
        translationObject.address_line_1[languageId] =
          outletData.outlet_address.translations.address_line_1[languageId];
      }
      for (const languageId in outletData.outlet_address.translations
        .address_line_2) {
        translationObject.address_line_2[languageId] =
          outletData.outlet_address.translations.address_line_2[languageId];
      }

      defaultValue.translations = translationObject;
      defaultValue.fssai_licence =
        outletData &&
        outletData.fssai_licence &&
        outletData.fssai_licence.licence;
      // defaultValue.contact_number = outletData.outlet_address.contact_number;
      defaultValue.country_code = outletData?.outlet_address?.country_code
        ? outletData.outlet_address.country_code
        : "+91";
      defaultValue.contact_number = outletData.outlet_address.contact_number;
      defaultValue.iso_country_code = outletData?.outlet_address
        ?.iso_country_code
        ? outletData.outlet_address.iso_country_code
        : "IN";

      defaultValue.city = outletData.outlet_address.city;
      defaultValue.state = outletData.outlet_address.state;
      // defaultValue.country_code = outletData.outlet_address.country;
      defaultValue.contact_number = outletData.outlet_address.contact_number
        ? `${outletData.outlet_address.country_code}${outletData.outlet_address.contact_number}`
        : outletData.outlet_address.contact_number;
      defaultValue.latitude = outletData.outlet_address.latitude;
      defaultValue.longitude = outletData.outlet_address.longitude;
      defaultValue.service_radius =
        outletData.outlet_address.service_radius / 1000;
      defaultValue.gst_applicable = outletData.settings.gst_applicable;
      defaultValue.gst_number = outletData.settings.gst_applicable
        ? outletData.settings.gst_number
        : null;
      defaultValue.delivery_charges_gst_slab = outletData.settings
        .gst_applicable
        ? outletData.settings.delivery_charges_gst_slab.toString()
        : null;
      defaultValue.kitchen_preparation_time =
        outletData.settings.kitchen_preparation_time;
      defaultValue.delivery_charge_threshold =
        outletData.settings.delivery_charge_threshold;
      defaultValue.delivery_charge_above_threshold =
        outletData.settings.delivery_charge_above_threshold;
      defaultValue.delivery_charge_below_threshold =
        outletData.settings.delivery_charge_below_threshold;
      defaultValue.packaging_charge = outletData.settings.packaging_charge;
      defaultValue.convinience_charge = outletData.settings.convenience_charge;
      defaultValue.kitchen_preparation_time =
        outletData.settings.kitchen_preparation_time;
      defaultValue.vegetarian_flag = outletData.settings.vegetarian_flag
        ? true
        : false;
      defaultValue.non_vegetarian_flag = outletData.settings.not_vegetarian_flag
        ? true
        : false;
      defaultValue.egg_flag = outletData.settings.egg_flag ? true : false;
      defaultValue.extra_delivery_charge =
        outletData.settings.extra_delivery_charge;
      defaultValue.normal_delivery_radius =
        outletData.outlet_address.normal_delivery_radius / 1000;
      defaultValue.prize_per_kilometer = defaultValue.normal_delivery_radius
        ? true
        : false;
      defaultValue.currency_symbol = [
        {
          value: outletData.settings.currency_symbol,
          label: outletData.settings.currency_symbol,
        },
      ];
      defaultValue.currency_code = [
        {
          value: outletData.settings.currency_code,
          label: outletData.settings.currency_code,
        },
      ];
      defaultValue.delivery = outletData.settings.delivery ? true : false;
      defaultValue.dine_in = outletData.settings.dine_in ? true : false;
      defaultValue.takeaway = outletData.settings.takeaway ? true : false;

      if (outletData.payment_settings.paytm) {
        paymentProvider.push({
          label: "paytm",
        });
      }
      if (outletData.payment_settings.stripe) {
        paymentProvider.push({
          label: "stripe",
        });
      }

      //payment provider value

      defaultValue.payment_provider = paymentProvider;

      if (outletData.payment_settings) {
        if (outletData.payment_settings.paytm) {
          for (const [key, value] of Object.entries(
            outletData.payment_settings.paytm
          )) {
            if (key === "PAYTM_MERCHANT_KEY") {
              defaultValue.paytm_merchent_key = value;
            }
            if (key === "PAYTM_MERCHANT_ID") {
              defaultValue.paytm_merchent_id = value;
            }
            if (key === "PAYTM_INDUSTRY_TYPE_ID") {
              defaultValue.paytm_industry_type_id = value;
            }
            if (key === "PAYTM_WEBSITE") {
              defaultValue.paytm_website = value;
            }
            if (key === "PAYTM_BASE_API_URL") {
              defaultValue.paytm_base_api_url = value;
            }
          }
        }
        if (outletData.payment_settings.stripe) {
          for (const [key, value] of Object.entries(
            outletData.payment_settings.stripe
          )) {
            if (key === "STRIPE_KEY") {
              defaultValue.stripe_key = value;
            }
            if (key === "STRIPE_SECRET") {
              defaultValue.stripe_secret = value;
            }
            if (key === "STRIPE_WEBHOOK") {
              defaultValue.stripe_webhook = value;
            }
            if (key === "STRIPE_WEBHOOK_SECRET") {
              defaultValue.stripe_webhook_secret = value;
            }
          }
        }
        if (outletData.payment_settings["cash_on_delivery"]) {
          defaultValue.case_on_delivery = true;
        } else {
          defaultValue.case_on_delivery = false;
        }
      }

      if (paymentProvider.length > 0) {
        paymentProvider.map((item) => {
          if (item.label === "paytm") {
            cardProvider.push({
              value: "paytm",
              inputDisplay: "Paytm",
            });
          } else {
            cardProvider.push({
              value: "stripe",
              inputDisplay: "Stripe",
            });
          }
        });
      }
      defaultValue.cardProvider = cardProvider;
      defaultValue.credit_card =
        cardProvider.length === 1 ? cardProvider[0].value : null;
      if (outletData.payment_configurations) {
        if (outletData.payment_configurations.paytm) {
          outletData.payment_configurations.paytm.map((item, index) => {
            if (item === "CREDIT_CARD/DEBIT_CARD") {
              defaultValue.credit_card = "paytm";
            }
          });
        }
        if (outletData.payment_configurations.stripe) {
          outletData.payment_configurations.stripe.map((item, index) => {
            if (item === "CREDIT_CARD/DEBIT_CARD") {
              defaultValue.credit_card = "stripe";
            }
          });
        }
      }
      console.log("credit-cart", defaultValue.credit_card);

      //set rush our
      defaultValue.kitchen_time_slot =
        outletData.settings.rush_hour_time_slot_setting === "no_time_slots"
          ? 6
          : outletData.settings.rush_hour_time_slot_setting ===
            "same_time_slot_for_all_days"
          ? 7
          : outletData.settings.rush_hour_time_slot_setting ===
            "different_time_slots_for_different_days"
          ? 8
          : 6;
      if (
        outletData.settings.rush_hour_time_slot_setting ===
        "same_time_slot_for_all_days"
      ) {
        let formatedTimeSlotInfoRushour = formatedDataForKitchen(
          outletData.settings.rush_hour_schedules
        );
        console.log("formatedTimeSlotInfoRushour", formatedTimeSlotInfoRushour);
        let fieldData = [];
        formatedTimeSlotInfoRushour &&
          formatedTimeSlotInfoRushour[1] &&
          formatedTimeSlotInfoRushour[1].map((item, index) => {
            fieldData.push({
              id: uuid.v4(),
              start_time: item.start_time,
              end_time: item.end_time,
              preparation_time_in_rush: item.preparation_time_in_rush,
            });
          });
        defaultValue.kitchen_time = fieldData;
      }
      defaultValue.kitchen_time_weak = {
        sunday: [],
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: [],
      };
      if (
        outletData.settings.rush_hour_time_slot_setting ===
        "different_time_slots_for_different_days"
      ) {
        let formatedTimeSlotInfo = formatedDataForKitchen(
          outletData.settings.rush_hour_schedules
        );
        console.log("formatedTimeSlotInfo", formatedTimeSlotInfo);
        for (const key in formatedTimeSlotInfo) {
          if (key == 1) {
            formatedTimeSlotInfo[1].map((item) =>
              defaultValue.kitchen_time_weak.sunday.push({
                id: uuid.v4(),
                start_time: item.start_time,
                end_time: item.end_time,
                preparation_time_in_rush: item.preparation_time_in_rush,
              })
            );
          }
          if (key == 2) {
            formatedTimeSlotInfo[2].forEach((item) =>
              defaultValue.kitchen_time_weak.monday.push({
                id: uuid.v4(),
                start_time: item.start_time,
                end_time: item.end_time,
                preparation_time_in_rush: item.preparation_time_in_rush,
              })
            );
          }

          if (key == 3) {
            formatedTimeSlotInfo[3].map((item) =>
              defaultValue.kitchen_time_weak.tuesday.push({
                id: uuid.v4(),
                start_time: item.start_time,
                end_time: item.end_time,
                preparation_time_in_rush: item.preparation_time_in_rush,
              })
            );
          }

          if (key == 4) {
            formatedTimeSlotInfo[4].map((item) =>
              defaultValue.kitchen_time_weak.wednesday.push({
                id: uuid.v4(),
                start_time: item.start_time,
                end_time: item.end_time,
                preparation_time_in_rush: item.preparation_time_in_rush,
              })
            );
          }

          if (key == 5) {
            formatedTimeSlotInfo[5].map((item) =>
              defaultValue.kitchen_time_weak.thursday.push({
                id: uuid.v4(),
                start_time: item.start_time,
                end_time: item.end_time,
                preparation_time_in_rush: item.preparation_time_in_rush,
              })
            );
          }

          if (key == 6) {
            formatedTimeSlotInfo[6].map((item) =>
              defaultValue.kitchen_time_weak.friday.push({
                id: uuid.v4(),
                start_time: item.start_time,
                end_time: item.end_time,
                preparation_time_in_rush: item.preparation_time_in_rush,
              })
            );
          }

          if (key == 7) {
            formatedTimeSlotInfo[7].map((item) =>
              defaultValue.kitchen_time_weak.saturday.push({
                id: uuid.v4(),
                start_time: item.start_time,
                end_time: item.end_time,
                preparation_time_in_rush: item.preparation_time_in_rush,
              })
            );
          }
        }
      }

      //set outlet time
      defaultValue.time_slot =
        outletData.settings.time_slot_setting === "same_time_slot_for_all_days"
          ? "outlet_same_day"
          : outletData.settings.time_slot_setting ===
            "different_time_slots_for_different_days"
          ? "outlet_differant_day"
          : "outlet_same_day";
      if (
        outletData.settings.time_slot_setting === "same_time_slot_for_all_days"
      ) {
        let formatedTimeSlotInfo = formatedData(outletData.settings.schedules);
        console.log("formatedTimeSlotInfo", formatedTimeSlotInfo);
        let fieldData = [];
        formatedTimeSlotInfo &&
          formatedTimeSlotInfo[1] &&
          formatedTimeSlotInfo[1].map((item, index) => {
            fieldData.push({
              id: uuid.v4(),
              start_time: item.start_time,
              end_time: item.end_time,
            });
          });

        defaultValue.time = fieldData;
      }
      defaultValue.weak = {
        sunday: [],
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: [],
      };
      if (
        outletData.settings.time_slot_setting ===
        "different_time_slots_for_different_days"
      ) {
        let formatedTimeSlotInfo = formatedData(outletData.settings.schedules);
        console.log("formatedTimeSlotInfo", formatedTimeSlotInfo);
        for (const key in formatedTimeSlotInfo) {
          if (key == 1) {
            formatedTimeSlotInfo[1].map((item) =>
              defaultValue.weak.sunday.push({
                id: uuid.v4(),
                start_time: item.start_time,
                end_time: item.end_time,
              })
            );
            console.log("sunday", defaultValue.weak.sunday);
          }
          console.log("key", key);
          if (key == 2) {
            formatedTimeSlotInfo[2].forEach((item) =>
              defaultValue.weak.monday.push({
                id: uuid.v4(),
                start_time: item.start_time,
                end_time: item.end_time,
              })
            );
            console.log("monday", defaultValue.weak.monday);
          }

          if (key == 3) {
            formatedTimeSlotInfo[3].map((item) =>
              defaultValue.weak.tuesday.push({
                id: uuid.v4(),
                start_time: item.start_time,
                end_time: item.end_time,
              })
            );
          }

          if (key == 4) {
            formatedTimeSlotInfo[4].map((item) =>
              defaultValue.weak.wednesday.push({
                id: uuid.v4(),
                start_time: item.start_time,
                end_time: item.end_time,
              })
            );
          }

          if (key == 5) {
            formatedTimeSlotInfo[5].map((item) =>
              defaultValue.weak.thursday.push({
                id: uuid.v4(),
                start_time: item.start_time,
                end_time: item.end_time,
              })
            );
          }

          if (key == 6) {
            formatedTimeSlotInfo[6].map((item) =>
              defaultValue.weak.friday.push({
                id: uuid.v4(),
                start_time: item.start_time,
                end_time: item.end_time,
              })
            );
          }

          if (key == 7) {
            formatedTimeSlotInfo[7].map((item) =>
              defaultValue.weak.saturday.push({
                id: uuid.v4(),
                start_time: item.start_time,
                end_time: item.end_time,
              })
            );
          }
          console.log("saturday", defaultValue.weak);
        }
      }

      // set delivery timer_for_delivery
      defaultValue.timer_for_delivery =
        outletData.settings.delivery_time_slot_setting === "no_time_slots"
          ? 9
          : outletData.settings.delivery_time_slot_setting ===
            "same_time_slot_for_all_days"
          ? 10
          : outletData.settings.delivery_time_slot_setting ===
            "different_time_slots_for_different_days"
          ? 11
          : 9;
      if (
        outletData.settings.delivery_time_slot_setting ===
        "same_time_slot_for_all_days"
      ) {
        let formatedTimeSlotInfoDelivery = formatedData(
          outletData.settings.delivery_schedule
        );
        let fieldData = [];
        formatedTimeSlotInfoDelivery &&
          formatedTimeSlotInfoDelivery[1] &&
          formatedTimeSlotInfoDelivery[1].map((item, index) => {
            fieldData.push({
              id: uuid.v4(),
              start_time: item.start_time,
              end_time: item.end_time,
            });
          });
        defaultValue.timeForDelivery = fieldData;
      }
      defaultValue.weakDelivery = {
        sunday: [],
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: [],
      };
      if (
        outletData.settings.delivery_time_slot_setting ===
        "different_time_slots_for_different_days"
      ) {
        let formatedTimeSlotInfoDelivery = formatedData(
          outletData.settings.delivery_schedule
        );
        for (const key in formatedTimeSlotInfoDelivery) {
          console.log(key);
          if (key == 7) {
            formatedTimeSlotInfoDelivery[7].map((item) =>
              defaultValue.weakDelivery.sunday.push({
                id: uuid.v4(),
                start_time: item.start_time,
                end_time: item.end_time,
              })
            );
            console.log(defaultValue.weakDelivery.sunday);
          }
          if (key == 1) {
            console.log("monday");
            formatedTimeSlotInfoDelivery[1].forEach((item) =>
              defaultValue.weakDelivery.monday.push({
                id: uuid.v4(),
                start_time: item.start_time,
                end_time: item.end_time,
              })
            );
          }
          if (key == 2) {
            console.log("tuesday");
            formatedTimeSlotInfoDelivery[2].map((item) =>
              defaultValue.weakDelivery.tuesday.push({
                id: uuid.v4(),
                start_time: item.start_time,
                end_time: item.end_time,
              })
            );
          }
          if (key == 3) {
            console.log("wednesday");
            formatedTimeSlotInfoDelivery[3].map((item) =>
              defaultValue.weakDelivery.wednesday.push({
                id: uuid.v4(),
                start_time: item.start_time,
                end_time: item.end_time,
              })
            );
          }
          if (key == 4) {
            console.log("thursday");
            formatedTimeSlotInfoDelivery[4].map((item) =>
              defaultValue.weakDelivery.thursday.push({
                id: uuid.v4(),
                start_time: item.start_time,
                end_time: item.end_time,
              })
            );
          }
          if (key == 5) {
            console.log("friday");
            formatedTimeSlotInfoDelivery[5].map((item) =>
              defaultValue.weakDelivery.friday.push({
                id: uuid.v4(),
                start_time: item.start_time,
                end_time: item.end_time,
              })
            );
          }

          if (key == 6) {
            console.log("saturday");
            formatedTimeSlotInfoDelivery[6].map((item) =>
              defaultValue.weakDelivery.saturday.push({
                id: uuid.v4(),
                start_time: item.start_time,
                end_time: item.end_time,
              })
            );
          }
        }
      }

      // set dinin
      defaultValue.timer_for_dine_in =
        outletData.settings.dine_in_time_slot_setting === "no_time_slots"
          ? 12
          : outletData.settings.dine_in_time_slot_setting ===
            "same_time_slot_for_all_days"
          ? 13
          : outletData.settings.dine_in_time_slot_setting ===
            "different_time_slots_for_different_days"
          ? 14
          : 12;
      if (
        outletData.settings.dine_in_time_slot_setting ===
        "same_time_slot_for_all_days"
      ) {
        let formatedTimeSlotInfoDinein = formatedData(
          outletData.settings.dine_in_schedule
        );
        let fieldData = [];
        formatedTimeSlotInfoDinein &&
          formatedTimeSlotInfoDinein[1] &&
          formatedTimeSlotInfoDinein[1].map((item, index) => {
            fieldData.push({
              id: uuid.v4(),
              start_time: item.start_time,
              end_time: item.end_time,
            });
          });
        defaultValue.timeForDineIn = fieldData;
      }
      defaultValue.weakDineIn = {
        sunday: [],
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: [],
      };
      if (
        outletData.settings.dine_in_time_slot_setting ===
        "different_time_slots_for_different_days"
      ) {
        let formatedTimeSlotInfoDinein = formatedData(
          outletData.settings.dine_in_schedule
        );
        for (const key in formatedTimeSlotInfoDinein) {
          console.log(key);
          if (key == 7) {
            formatedTimeSlotInfoDinein[7].map((item) =>
              defaultValue.weakDineIn.sunday.push({
                id: uuid.v4(),
                start_time: item.start_time,
                end_time: item.end_time,
              })
            );
          }
          if (key == 1) {
            formatedTimeSlotInfoDinein[1].forEach((item) =>
              defaultValue.weakDineIn.monday.push({
                id: uuid.v4(),
                start_time: item.start_time,
                end_time: item.end_time,
              })
            );
          }

          if (key == 2) {
            formatedTimeSlotInfoDinein[2].map((item) =>
              defaultValue.weakDineIn.tuesday.push({
                id: uuid.v4(),
                start_time: item.start_time,
                end_time: item.end_time,
              })
            );
          }

          if (key == 3) {
            formatedTimeSlotInfoDinein[3].map((item) =>
              defaultValue.weakDineIn.wednesday.push({
                id: uuid.v4(),
                start_time: item.start_time,
                end_time: item.end_time,
              })
            );
          }

          if (key == 4) {
            formatedTimeSlotInfoDinein[4].map((item) =>
              defaultValue.weakDineIn.thursday.push({
                id: uuid.v4(),
                start_time: item.start_time,
                end_time: item.end_time,
              })
            );
          }

          if (key == 5) {
            formatedTimeSlotInfoDinein[5].map((item) =>
              defaultValue.weakDineIn.friday.push({
                id: uuid.v4(),
                start_time: item.start_time,
                end_time: item.end_time,
              })
            );
          }

          if (key == 6) {
            formatedTimeSlotInfoDinein[6].map((item) =>
              defaultValue.weakDineIn.saturday.push({
                id: uuid.v4(),
                start_time: item.start_time,
                end_time: item.end_time,
              })
            );
          }
        }
      }

      // set takeaway
      defaultValue.timer_for_takeaway =
        outletData.settings.takeaway_time_slot_setting === "no_time_slots"
          ? 15
          : outletData.settings.takeaway_time_slot_setting ===
            "same_time_slot_for_all_days"
          ? 16
          : outletData.settings.takeaway_time_slot_setting ===
            "different_time_slots_for_different_days"
          ? 17
          : 15;
      if (
        outletData.settings.takeaway_time_slot_setting ===
        "same_time_slot_for_all_days"
      ) {
        let formatedTimeSlotInfoTakeaway = formatedData(
          outletData.settings.takeaway_schedule
        );
        let fieldData = [];
        formatedTimeSlotInfoTakeaway &&
          formatedTimeSlotInfoTakeaway[1] &&
          formatedTimeSlotInfoTakeaway[1].map((item, index) => {
            fieldData.push({
              id: uuid.v4(),
              start_time: item.start_time,
              end_time: item.end_time,
            });
          });
        defaultValue.timeForTakeAway = fieldData;
      }
      defaultValue.weakTakeAway = {
        sunday: [],
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: [],
      };
      if (
        outletData.settings.takeaway_time_slot_setting ===
        "different_time_slots_for_different_days"
      ) {
        let formatedTimeSlotInfoTakeaway = formatedData(
          outletData.settings.takeaway_schedule
        );
        for (const key in formatedTimeSlotInfoTakeaway) {
          console.log(key);
          if (key == 7) {
            formatedTimeSlotInfoTakeaway[7].map((item) =>
              defaultValue.weakTakeAway.sunday.push({
                id: uuid.v4(),
                start_time: item.start_time,
                end_time: item.end_time,
              })
            );
          }
          if (key == 1) {
            formatedTimeSlotInfoTakeaway[1].forEach((item) =>
              defaultValue.weakTakeAway.monday.push({
                id: uuid.v4(),
                start_time: item.start_time,
                end_time: item.end_time,
              })
            );
          }

          if (key == 2) {
            formatedTimeSlotInfoTakeaway[2].map((item) =>
              defaultValue.weakTakeAway.tuesday.push({
                id: uuid.v4(),
                start_time: item.start_time,
                end_time: item.end_time,
              })
            );
          }

          if (key == 3) {
            formatedTimeSlotInfoTakeaway[3].map((item) =>
              defaultValue.weakTakeAway.wednesday.push({
                id: uuid.v4(),
                start_time: item.start_time,
                end_time: item.end_time,
              })
            );
          }

          if (key == 4) {
            formatedTimeSlotInfoTakeaway[4].map((item) =>
              defaultValue.weakTakeAway.thursday.push({
                id: uuid.v4(),
                start_time: item.start_time,
                end_time: item.end_time,
              })
            );
          }

          if (key == 5) {
            formatedTimeSlotInfoTakeaway[5].map((item) =>
              defaultValue.weakTakeAway.friday.push({
                id: uuid.v4(),
                start_time: item.start_time,
                end_time: item.end_time,
              })
            );
          }

          if (key == 6) {
            formatedTimeSlotInfoTakeaway[6].map((item) =>
              defaultValue.weakTakeAway.saturday.push({
                id: uuid.v4(),
                start_time: item.start_time,
                end_time: item.end_time,
              })
            );
          }
        }
      }
      console.log("defaultValue", defaultValue);

      reset({
        ...defaultValue,
        time: defaultValue.time ? defaultValue.time : [],
        weak: defaultValue.weak
          ? defaultValue.weak
          : {
              sunday: [],
              monday: [],
              tuesday: [],
              wednesday: [],
              thursday: [],
              friday: [],
              saturday: [],
            },
        kitchen_time: defaultValue.kitchen_time
          ? defaultValue.kitchen_time
          : [],
        kitchen_time_weak: defaultValue.kitchen_time_weak
          ? defaultValue.kitchen_time_weak
          : {
              sunday: [],
              monday: [],
              tuesday: [],
              wednesday: [],
              thursday: [],
              friday: [],
              saturday: [],
            },
        timeForDelivery: defaultValue.timeForDelivery
          ? defaultValue.timeForDelivery
          : [],
        weakDelivery: defaultValue.weakDelivery
          ? defaultValue.weakDelivery
          : {
              sunday: [],
              monday: [],
              tuesday: [],
              wednesday: [],
              thursday: [],
              friday: [],
              saturday: [],
            },
        timeForDineIn: defaultValue.timeForDineIn
          ? defaultValue.timeForDineIn
          : [],
        weakDineIn: defaultValue.weakDineIn
          ? defaultValue.weakDineIn
          : {
              sunday: [],
              monday: [],
              tuesday: [],
              wednesday: [],
              thursday: [],
              friday: [],
              saturday: [],
            },
        timeForTakeAway: defaultValue.timeForTakeAway
          ? defaultValue.timeForTakeAway
          : [],
        weakTakeAway: defaultValue.weakTakeAway
          ? defaultValue.weakTakeAway
          : {
              sunday: [],
              monday: [],
              tuesday: [],
              wednesday: [],
              thursday: [],
              friday: [],
              saturday: [],
            },
      });
    }
  }, [editOutlet]);

  const { handleSubmit, errors, control, watch, reset, setValue, getValues } =
    useForm({
      defaultValues: { ...defaultValue },
    });

  const payment_provider_options = [
    {
      label: "paytm",
    },
    {
      label: "stripe",
    },
  ];
  const optionsPaytm = [
    {
      value: "paytm",
      inputDisplay: "Paytm",
    },
  ];
  const options = [
    {
      value: "paytm",
      inputDisplay: "Paytm",
    },
    {
      value: "stripe",
      inputDisplay: "Stripe",
    },
  ];
  const slub = [
    {
      value: "0",
      inputDisplay: "0%",
    },
    {
      value: "5",
      inputDisplay: "5%",
    },
    {
      value: "12",
      inputDisplay: "12%",
    },
    {
      value: "18",
      inputDisplay: "18%",
    },
    {
      value: "28",
      inputDisplay: "28%",
    },
  ];
  const gst_applicable = [
    {
      id: 0,
      label: "No",
    },
    {
      id: 1,
      label: "Yes",
    },
  ];
  const timeSlot = [
    {
      id: "outlet_same_day",
      label: "Available at same time for all days of the week",
    },
    {
      id: "outlet_differant_day",
      label: "Available at specific time for all days of the week",
    },
  ];

  const kitchen_timeSlot = [
    {
      id: 6,
      label: "There is no rush hour ",
    },
    {
      id: 7,
      label: "Add some rush hour on all days",
    },
    {
      id: 8,
      label: "Differant rush hour on differant days",
    },
  ];

  const deliveryTimeSlot = [
    {
      id: 9,
      label: "Delivery is available at  for all days of the week ",
    },
    {
      id: 10,
      label: "Delivery is available at same time for all days of the week",
    },
    {
      id: 11,
      label: "Delivery is available at specific time for all days of the week",
    },
  ];
  const dineInTimeSlot = [
    {
      id: 12,
      label: "Dine-in is available at  for all days of the week ",
    },
    {
      id: 13,
      label: "Dine-in is available at same time for all days of the week",
    },
    {
      id: 14,
      label: "Dine-in is available at specific time for all days of the week",
    },
  ];
  const pickUpTimeSlot = [
    {
      id: 15,
      label: "Pick-up is available at  for all days of the week ",
    },
    {
      id: 16,
      label: "Pick-up is available at same time for all days of the week",
    },
    {
      id: 17,
      label: "Pick-up is available at specific time for all days of the week",
    },
  ];
  let countryCode = [];
  for (const [key, value] of Object.entries(allCountry)) {
    countryCode.push({
      value: key,
      inputDisplay: value,
    });
  }
  let currencyDataSymbol = [];
  let currencyDataCode = [];
  for (const [key1, value1] of Object.entries(currencyData)) {
    currencyDataSymbol.push({
      value: value1.symbol_native,
      label: `${value1.name} - ${value1.symbol} - ${value1.symbol_native} `,
    });
  }
  for (const [key1, value1] of Object.entries(currencyData)) {
    currencyDataCode.push({
      value: value1.code,
      label: value1.code,
    });
  }
  const [paymentOptions, setPaymentOptions] = useState([
    {
      value: "paytm",
      inputDisplay: "Paytm",
    },
    {
      value: "stripe",
      inputDisplay: "Stripe",
    },
  ]);

  const onChangePaymentProvider = (selectedProvider) => {
    setValue("payment_provider", selectedProvider);

    let paymentOptions = [];
    watch("payment_provider").map((item) => {
      if (item.label === "paytm") {
        paymentOptions.push({
          value: "paytm",
          inputDisplay: "Paytm",
        });
      }
      if (item.label === "stripe") {
        paymentOptions.push({
          value: "stripe",
          inputDisplay: "Stripe",
        });
      }
    });

    const data = paymentOptions.length > 0 ? paymentOptions[0].value : "paytm";
    setValue("credit_card", data);
    setValue("cardProvider", paymentOptions);
    setPaymentOptions(paymentOptions);
  };

  // const storeDatatoLocalStorage = () => {
  //   const data = getValues();
  //   console.log(data);
  //   const getFormList = JSON.parse(localStorage.getItem("form_list"));
  //   if (editOutletId) {
  //     let index = getFormList.findIndex((item) => item.id === editOutletId);
  //     data.id = editOutletId;
  //     getFormList[index] = data;
  //     localStorage.setItem("form_list", JSON.stringify(getFormList));
  //   } else if (getFormList === null) {
  //     localStorage.setItem("form_list", JSON.stringify([]));
  //     const getFormData = JSON.parse(localStorage.getItem("form_list"));
  //     data.id = uuid.v4();
  //     getFormData.push(data);
  //     localStorage.setItem("form_list", JSON.stringify(getFormData));
  //   } else {
  //     data.id = uuid.v4();
  //     getFormList.push(data);
  //     localStorage.setItem("form_list", JSON.stringify(getFormList));
  //   }
  // };

  const onSubmit = async (data) => {
    const city = {};
    const state = {};

    const address_line_1 = {};
    const address_line_2 = {};
    const name = {};

    for (const property in data.translations.city) {
      city[parseInt(property)] = data.translations.city[property];
    }
    for (const property in data.translations.state) {
      state[parseInt(property)] = data.translations.state[property];
    }
    for (const property in data.translations.address_line_1) {
      address_line_1[parseInt(property)] =
        data.translations.address_line_1[property];
    }
    for (const property in data.translations.address_line_2) {
      address_line_2[parseInt(property)] =
        data.translations.address_line_2[property];
    }
    for (const property in data.translations.name) {
      name[parseInt(property)] = data.translations.name[property];
    }

    data.translations.city = city;
    data.translations.state = state;

    data.translations.address_line_1 = address_line_1;
    data.translations.address_line_2 = address_line_2;
    data.translations.name = name;

    data.address = {
      // contact_number: data.contact_number,
      country_code: data.selectedCountryNumber
        ? `+${data.selectedCountryNumber.dialCode}`
        : !isEmpty(editOutlet) && editOutlet.outlet_address.country_code
        ? editOutlet.outlet_address.country_code
        : "+91",
      contact_number: data.selectedCountryNumber
        ? data.selectedCountryNumber.number
        : !isEmpty(editOutlet) && editOutlet.outlet_address.contact_number
        ? editOutlet.outlet_address.contact_number
        : "",
      // country_code: !isEmpty(data.selectedCountryNumber) ? `+${data.selectedCountryNumber.dialCode}` : "+91",
      // contact_number: !isEmpty(data.selectedCountryNumber) ? data.selectedCountryNumber.number : editOutlet.outlet_address.contact_number,
      service_radius: data.service_radius,
      longitude: data.longitude,
      latitude: data.latitude,
      iso_country_code: data.country_code ? data.country_code : "IN",
      normal_delivery_radius: data.prize_per_kilometer
        ? data.normal_delivery_radius
        : null,
      translations: {
        city: data.translations.city,
        state: data.translations.state,
        address_line_1: data.translations.address_line_1,
        address_line_2: data.translations.address_line_2,
      },
    };

    delete data.translations.city;
    delete data.translations.state;
    delete data.translations.address_line_1;
    delete data.translations.address_line_2;

    data.settings = {
      gst_applicable: data.gst_applicable === 0 ? 0 : 1,
      gst_number: data.gst_applicable ? data.gst_number : null,
      delivery_charges_gst_slab: data.gst_applicable
        ? data.delivery_charges_gst_slab
        : null,
      kitchen_preparation_time: data.kitchen_preparation_time,
      delivery_charge_threshold: data.delivery_charge_threshold,
      delivery_charge_above_threshold: data.delivery_charge_above_threshold,
      delivery_charge_below_threshold: data.delivery_charge_below_threshold,
      extra_delivery_charge: data.prize_per_kilometer
        ? data.extra_delivery_charge
        : null,
      packaging_charge: data.packaging_charge,
      convenience_charge: data.convinience_charge,
      vegetarian_flag: data.vegetarian_flag ? 1 : 0,
      not_vegetarian_flag: data.non_vegetarian_flag ? 1 : 0,
      egg_flag: data.egg_flag ? 1 : 0,
      currency_symbol: data.currency_symbol[0].value,
      currency_code: data.currency_code[0].value,
      delivery: data.delivery ? 1 : 0,
      dine_in: data.dine_in ? 1 : 0,
      takeaway: data.takeaway ? 1 : 0,
    };
    console.log("currency_symbol", data.settings.currency_symbol);
    const paytm = data.payment_provider.find((item) => {
      if (item.label === "paytm") {
        return "paytm";
      }
    });
    const stripe = data.payment_provider.find((item) => {
      if (item.label === "stripe") {
        return "stripe";
      }
    });
    let payment_settings = {};
    if (paytm) {
      if (paytm.label === "paytm") {
        payment_settings.paytm = {
          PAYTM_MERCHANT_KEY: data.paytm_merchent_key,
          PAYTM_MERCHANT_ID: data.paytm_merchent_id,
          PAYTM_INDUSTRY_TYPE_ID: data.paytm_industry_type_id,
          PAYTM_WEBSITE: data.paytm_website,
          PAYTM_BASE_API_URL: data.paytm_base_api_url,
        };
      }
    }
    if (stripe) {
      if (stripe.label === "stripe") {
        payment_settings.stripe = {
          STRIPE_KEY: data.stripe_key,
          STRIPE_SECRET: data.stripe_secret,
          STRIPE_WEBHOOK: data.stripe_webhook,
          STRIPE_WEBHOOK_SECRET: data.stripe_webhook_secret,
        };
      }
    }
    let paytmData = [];
    data.payment_provider.find((item) => {
      if (item.label === "paytm") {
        paytmData.push("NET_BANKING", "BALANCE", "UPI", "UPI_INTENT");
      }
    });
    let stripeData = [];
    if (data.credit_card === "stripe") {
      stripeData.push("CREDIT_CARD/DEBIT_CARD");
    } else {
      paytmData.push("CREDIT_CARD/DEBIT_CARD");
    }
    data.payment_availabilities = {
      paytm: paytmData,
      stripe: stripeData,
      "cash-on-delivery": [data.case_on_delivery ? 1 : 0],
    };

    // rush our time set
    let rush_hour_schedules;
    if (data.kitchen_time_slot === 6 || data.kitchen_time_slot === true) {
      data.settings.rush_hour_schedules = null;
      data.settings.rush_hour_time_slot_setting = "no_time_slots";
    } else if (data.kitchen_time_slot === 7) {
      data.settings.rush_hour_time_slot_setting = "same_time_slot_for_all_days";
      rush_hour_schedules = [];

      let singleTimeSlot =
        data.kitchen_time &&
        data.kitchen_time.length > 0 &&
        data.kitchen_time.filter((item, index) => {
          if (
            item.start_time !== undefined &&
            item.end_time !== undefined &&
            item.preparation_time_in_rush !== undefined
          ) {
            return item;
          }
        });
      singleTimeSlot &&
        singleTimeSlot.length > 0 &&
        rush_hour_schedules.push({
          days_of_week: [1, 2, 3, 4, 5, 6, 7],
          time_slots: singleTimeSlot,
        });
      data.settings.rush_hour_schedules =
        rush_hour_schedules.length > 0 ? rush_hour_schedules : null;
      if (!data.settings.rush_hour_schedules) {
        data.settings.rush_hour_time_slot_setting = "no_time_slots";
      }
    } else if (data.kitchen_time_slot === 8) {
      data.settings.rush_hour_time_slot_setting =
        "different_time_slots_for_different_days";

      rush_hour_schedules = [];

      let sunday =
        data.kitchen_time_weak &&
        data.kitchen_time_weak.sunday &&
        data.kitchen_time_weak.sunday.filter((item, index) => {
          if (
            item.start_time !== undefined &&
            item.end_time !== undefined &&
            item.preparation_time_in_rush !== undefined
          ) {
            return item;
          }
        });

      sunday &&
        sunday.length > 0 &&
        rush_hour_schedules.push({
          days_of_week: [7],
          time_slots: sunday,
        });

      let monday =
        data.kitchen_time_weak &&
        data.kitchen_time_weak.monday &&
        data.kitchen_time_weak.monday.filter((item, index) => {
          if (
            item.start_time !== undefined &&
            item.end_time !== undefined &&
            item.preparation_time_in_rush !== undefined
          ) {
            return item;
          }
        });
      monday &&
        monday.length > 0 &&
        rush_hour_schedules.push({
          days_of_week: [1],
          time_slots: monday,
        });

      let tuesday =
        data.kitchen_time_weak &&
        data.kitchen_time_weak.tuesday &&
        data.kitchen_time_weak.tuesday.filter((item, index) => {
          if (
            item.start_time !== undefined &&
            item.end_time !== undefined &&
            item.preparation_time_in_rush !== undefined
          ) {
            return item;
          }
        });
      tuesday &&
        tuesday.length > 0 &&
        rush_hour_schedules.push({
          days_of_week: [2],
          time_slots: tuesday,
        });

      let wednesday =
        data.kitchen_time_weak &&
        data.kitchen_time_weak.wednesday &&
        data.kitchen_time_weak.wednesday.filter((item, index) => {
          if (
            item.start_time !== undefined &&
            item.end_time !== undefined &&
            item.preparation_time_in_rush !== undefined
          ) {
            return item;
          }
        });
      wednesday &&
        wednesday.length > 0 &&
        rush_hour_schedules.push({
          days_of_week: [3],
          time_slots: wednesday,
        });

      let thursday =
        data.kitchen_time_weak &&
        data.kitchen_time_weak.thursday &&
        data.kitchen_time_weak.thursday.filter((item, index) => {
          if (
            item.start_time !== undefined &&
            item.end_time !== undefined &&
            item.preparation_time_in_rush !== undefined
          ) {
            return item;
          }
        });
      thursday &&
        thursday.length > 0 &&
        rush_hour_schedules.push({
          days_of_week: [4],
          time_slots: thursday,
        });

      let friday =
        data.kitchen_time_weak &&
        data.kitchen_time_weak.friday &&
        data.kitchen_time_weak.friday.filter((item, index) => {
          if (
            item.start_time !== undefined &&
            item.end_time !== undefined &&
            item.preparation_time_in_rush !== undefined
          ) {
            return item;
          }
        });
      friday &&
        friday.length > 0 &&
        rush_hour_schedules.push({
          days_of_week: [5],
          time_slots: friday,
        });

      let saturday =
        data.kitchen_time_weak &&
        data.kitchen_time_weak.saturday &&
        data.kitchen_time_weak.saturday.filter((item, index) => {
          if (
            item.start_time !== undefined &&
            item.end_time !== undefined &&
            item.preparation_time_in_rush !== undefined
          ) {
            return item;
          }
        });
      saturday &&
        saturday.length > 0 &&
        rush_hour_schedules.push({
          days_of_week: [6],
          time_slots: saturday,
        });

      data.settings.rush_hour_schedules =
        rush_hour_schedules.length > 0 ? rush_hour_schedules : null;
      if (!data.settings.rush_hour_schedules) {
        data.settings.rush_hour_time_slot_setting = "no_time_slots";
      }
    } else {
      rush_hour_schedules = null;
      data.settings.rush_hour_time_slot_setting = "no_time_slots";
      data.settings.rush_hour_schedules = null;
    }

    //set delivery time slot
    let delivery_schedules;
    data.settings.delivery_time_slot_setting = null;
    if (data.delivery) {
      if (data.timer_for_delivery === 9 || data.timer_for_delivery === true) {
        data.settings.delivery_schedules = null;
        data.settings.delivery_time_slot_setting = "no_time_slots";
      } else if (data.timer_for_delivery === 10) {
        data.settings.delivery_time_slot_setting =
          "same_time_slot_for_all_days";

        delivery_schedules = [];

        let singleTimeSlot =
          data.timeForDelivery &&
          data.timeForDelivery.length > 0 &&
          data.timeForDelivery.filter((item, index) => {
            if (item.start_time !== undefined && item.end_time !== undefined) {
              return item;
            }
          });

        singleTimeSlot &&
          singleTimeSlot.length > 0 &&
          delivery_schedules.push({
            days_of_week: [1, 2, 3, 4, 5, 6, 7],
            time_slots: singleTimeSlot,
          });

        data.settings.delivery_schedules =
          delivery_schedules.length > 0 ? delivery_schedules : null;

        if (!data.settings.delivery_schedules) {
          data.settings.delivery_time_slot_setting = "no_time_slots";
        }
      } else {
        delivery_schedules = [];
        data.settings.delivery_time_slot_setting =
          "different_time_slots_for_different_days";

        let sunday =
          data.weakDelivery &&
          data.weakDelivery.sunday &&
          data.weakDelivery.sunday.filter((item, index) => {
            if (item.start_time !== undefined && item.end_time !== undefined) {
              return item;
            }
          });
        sunday &&
          sunday.length > 0 &&
          delivery_schedules.push({
            days_of_week: [7],
            time_slots: sunday,
          });

        let monday =
          data.weakDelivery &&
          data.weakDelivery.monday &&
          data.weakDelivery.monday.filter((item, index) => {
            if (item.start_time !== undefined && item.end_time !== undefined) {
              return item;
            }
          });
        monday &&
          monday.length > 0 &&
          delivery_schedules.push({
            days_of_week: [1],
            time_slots: monday,
          });

        let tuesday =
          data.weakDelivery &&
          data.weakDelivery.tuesday &&
          data.weakDelivery.tuesday.filter((item, index) => {
            if (item.start_time !== undefined && item.end_time !== undefined) {
              return item;
            }
          });
        tuesday &&
          tuesday.length > 0 &&
          delivery_schedules.push({
            days_of_week: [2],
            time_slots: tuesday,
          });

        let wednesday =
          data.weakDelivery &&
          data.weakDelivery.wednesday &&
          data.weakDelivery.wednesday.filter((item, index) => {
            if (item.start_time !== undefined && item.end_time !== undefined) {
              return item;
            }
          });
        wednesday &&
          wednesday.length > 0 &&
          delivery_schedules.push({
            days_of_week: [3],
            time_slots: wednesday,
          });

        let thursday =
          data.weakDelivery &&
          data.weakDelivery.thursday &&
          data.weakDelivery.thursday.filter((item, index) => {
            if (item.start_time !== undefined && item.end_time !== undefined) {
              return item;
            }
          });
        thursday &&
          thursday.length > 0 &&
          delivery_schedules.push({
            days_of_week: [4],
            time_slots: thursday,
          });

        let friday =
          data.weakDelivery &&
          data.weakDelivery.friday &&
          data.weakDelivery.friday.filter((item, index) => {
            if (item.start_time !== undefined && item.end_time !== undefined) {
              return item;
            }
          });
        friday &&
          friday.length > 0 &&
          delivery_schedules.push({
            days_of_week: [5],
            time_slots: friday,
          });

        let saturday =
          data.weakDelivery &&
          data.weakDelivery.saturday &&
          data.weakDelivery.saturday.filter((item, index) => {
            if (item.start_time !== undefined && item.end_time !== undefined) {
              return item;
            }
          });
        saturday &&
          saturday.length > 0 &&
          delivery_schedules.push({
            days_of_week: [6],
            time_slots: saturday,
          });
        data.settings.delivery_schedules =
          delivery_schedules.length > 0 ? delivery_schedules : null;
        if (!data.settings.delivery_schedules) {
          data.settings.delivery_time_slot_setting = "no_time_slots";
        }
      }
    }

    // set dining time slot
    data.settings.dine_in_time_slot_setting = null;
    let dine_in_schedules;
    if (data.dine_in) {
      if (data.timer_for_dine_in === 12 || data.timer_for_dine_in === true) {
        data.settings.dine_in_schedules = null;
        data.settings.dine_in_time_slot_setting = "no_time_slots";
      } else if (data.timer_for_dine_in === 13) {
        data.settings.dine_in_time_slot_setting = "same_time_slot_for_all_days";

        dine_in_schedules = [];

        let singleTimeSlot =
          data.timeForDineIn &&
          data.timeForDineIn.length > 0 &&
          data.timeForDineIn.filter((item, index) => {
            if (item.start_time !== undefined && item.end_time !== undefined) {
              return item;
            }
          });
        singleTimeSlot &&
          singleTimeSlot.length > 0 &&
          dine_in_schedules.push({
            days_of_week: [1, 2, 3, 4, 5, 6, 7],
            time_slots: singleTimeSlot,
          });

        data.settings.dine_in_schedules =
          dine_in_schedules.length > 0 ? dine_in_schedules : null;
        if (!data.settings.dine_in_schedules) {
          data.settings.dine_in_time_slot_setting = "no_time_slots";
        }
      } else {
        dine_in_schedules = [];
        data.settings.dine_in_time_slot_setting =
          "different_time_slots_for_different_days";

        let sunday =
          data.weakDineIn &&
          data.weakDineIn.sunday &&
          data.weakDineIn.sunday.filter((item, index) => {
            if (item.start_time !== undefined && item.end_time !== undefined) {
              return item;
            }
          });
        sunday &&
          sunday.length > 0 &&
          dine_in_schedules.push({
            days_of_week: [7],
            time_slots: sunday,
          });
        let monday =
          data.weakDineIn &&
          data.weakDineIn.monday &&
          data.weakDineIn.monday.filter((item, index) => {
            if (item.start_time !== undefined && item.end_time !== undefined) {
              return item;
            }
          });
        monday &&
          monday.length > 0 &&
          dine_in_schedules.push({
            days_of_week: [1],
            time_slots: monday,
          });

        let tuesday =
          data.weakDineIn &&
          data.weakDineIn.tuesday &&
          data.weakDineIn.tuesday.filter((item, index) => {
            if (item.start_time !== undefined && item.end_time !== undefined) {
              return item;
            }
          });
        tuesday &&
          tuesday.length > 0 &&
          dine_in_schedules.push({
            days_of_week: [2],
            time_slots: tuesday,
          });

        let wednesday =
          data.weakDineIn &&
          data.weakDineIn.wednesday &&
          data.weakDineIn.wednesday.filter((item, index) => {
            if (item.start_time !== undefined && item.end_time !== undefined) {
              return item;
            }
          });
        wednesday &&
          wednesday.length > 0 &&
          dine_in_schedules.push({
            days_of_week: [3],
            time_slots: wednesday,
          });

        let thursday =
          data.weakDineIn &&
          data.weakDineIn.thursday &&
          data.weakDineIn.thursday.filter((item, index) => {
            if (item.start_time !== undefined && item.end_time !== undefined) {
              return item;
            }
          });
        thursday &&
          thursday.length > 0 &&
          dine_in_schedules.push({
            days_of_week: [4],
            time_slots: thursday,
          });

        let friday =
          data.weakDineIn &&
          data.weakDineIn.friday &&
          data.weakDineIn.friday.filter((item, index) => {
            if (item.start_time !== undefined && item.end_time !== undefined) {
              return item;
            }
          });
        friday &&
          friday.length > 0 &&
          dine_in_schedules.push({
            days_of_week: [5],
            time_slots: friday,
          });

        let saturday =
          data.weakDineIn &&
          data.weakDineIn.saturday &&
          data.weakDineIn.saturday.filter((item, index) => {
            if (item.start_time !== undefined && item.end_time !== undefined) {
              return item;
            }
          });
        saturday &&
          saturday.length > 0 &&
          dine_in_schedules.push({
            days_of_week: [6],
            time_slots: saturday,
          });
        data.settings.dine_in_schedules =
          dine_in_schedules.length > 0 ? dine_in_schedules : null;
        if (!data.settings.dine_in_schedules) {
          data.settings.dine_in_time_slot_setting = "no_time_slots";
        }
      }
    }

    //set takeaway time slot

    let takeaway_schedules;
    data.settings.takeaway_time_slot_setting = null;
    if (data.takeaway) {
      if (data.timer_for_takeaway === 15 || data.timer_for_takeaway === true) {
        data.settings.takeaway_schedules = null;
        data.settings.takeaway_time_slot_setting = "no_time_slots";
      } else if (data.timer_for_takeaway === 16) {
        data.settings.takeaway_time_slot_setting =
          "same_time_slot_for_all_days";

        takeaway_schedules = [];

        let singleTimeSlot =
          data.timeForTakeAway &&
          data.timeForTakeAway.length > 0 &&
          data.timeForTakeAway.filter((item, index) => {
            if (item.start_time !== undefined && item.end_time !== undefined) {
              return item;
            }
          });
        singleTimeSlot &&
          singleTimeSlot.length > 0 &&
          takeaway_schedules.push({
            days_of_week: [1, 2, 3, 4, 5, 6, 7],
            time_slots: singleTimeSlot,
          });
        data.settings.takeaway_schedules =
          takeaway_schedules.length > 0 ? takeaway_schedules : null;
        if (!data.settings.takeaway_schedules) {
          data.settings.takeaway_time_slot_setting = "no_time_slots";
        }
      } else {
        takeaway_schedules = [];
        data.settings.takeaway_time_slot_setting =
          "different_time_slots_for_different_days";

        let sunday =
          data.weakTakeAway &&
          data.weakTakeAway.sunday &&
          data.weakTakeAway.sunday.filter((item, index) => {
            if (item.start_time !== undefined && item.end_time !== undefined) {
              return item;
            }
          });
        sunday &&
          sunday.length > 0 &&
          takeaway_schedules.push({
            days_of_week: [7],
            time_slots: sunday,
          });
        let monday =
          data.weakTakeAway &&
          data.weakTakeAway.monday &&
          data.weakTakeAway.monday.filter((item, index) => {
            if (item.start_time !== undefined && item.end_time !== undefined) {
              return item;
            }
          });
        monday &&
          monday.length > 0 &&
          takeaway_schedules.push({
            days_of_week: [1],
            time_slots: monday,
          });

        let tuesday =
          data.weakTakeAway &&
          data.weakTakeAway.tuesday &&
          data.weakTakeAway.tuesday.filter((item, index) => {
            if (item.start_time !== undefined && item.end_time !== undefined) {
              return item;
            }
          });
        tuesday &&
          tuesday.length > 0 &&
          takeaway_schedules.push({
            days_of_week: [2],
            time_slots: tuesday,
          });

        let wednesday =
          data.weakTakeAway &&
          data.weakTakeAway.wednesday &&
          data.weakTakeAway.wednesday.filter((item, index) => {
            if (item.start_time !== undefined && item.end_time !== undefined) {
              return item;
            }
          });
        wednesday &&
          wednesday.length > 0 &&
          takeaway_schedules.push({
            days_of_week: [3],
            time_slots: wednesday,
          });

        let thursday =
          data.weakTakeAway &&
          data.weakTakeAway.thursday &&
          data.weakTakeAway.thursday.filter((item, index) => {
            if (item.start_time !== undefined && item.end_time !== undefined) {
              return item;
            }
          });
        thursday &&
          thursday.length > 0 &&
          takeaway_schedules.push({
            days_of_week: [4],
            time_slots: thursday,
          });

        let friday =
          data.weakTakeAway &&
          data.weakTakeAway.friday &&
          data.weakTakeAway.friday.filter((item, index) => {
            if (item.start_time !== undefined && item.end_time !== undefined) {
              return item;
            }
          });
        friday &&
          friday.length > 0 &&
          takeaway_schedules.push({
            days_of_week: [5],
            time_slots: friday,
          });

        let saturday =
          data.weakTakeAway &&
          data.weakTakeAway.saturday &&
          data.weakTakeAway.saturday.filter((item, index) => {
            if (item.start_time !== undefined && item.end_time !== undefined) {
              return item;
            }
          });
        saturday &&
          saturday.length > 0 &&
          takeaway_schedules.push({
            days_of_week: [6],
            time_slots: saturday,
          });
        data.settings.takeaway_schedules =
          takeaway_schedules.length > 0 ? takeaway_schedules : null;
        if (!data.settings.takeaway_schedules) {
          data.settings.takeaway_time_slot_setting = "no_time_slots";
        }
      }
    }

    // set outlet time
    let schedules;
    console.log("data.time", data.time);
    if (data.time_slot === "outlet_same_day") {
      data.settings.time_slot_setting = "same_time_slot_for_all_days";
      schedules = [];

      let singleTimeSlot =
        data.time &&
        data.time.length > 0 &&
        data.time.filter((item, index) => {
          if (item.start_time !== undefined && item.end_time !== undefined) {
            return item;
          }
        });
      singleTimeSlot &&
        singleTimeSlot.length > 0 &&
        schedules.push({
          days_of_week: [1, 2, 3, 4, 5, 6, 7],
          time_slots: singleTimeSlot,
        });

      data.settings.schedules = schedules.length > 0 ? schedules : null;
      if (!data.settings.schedules) {
        setTimeSlotError(true);
        return false;
      }
    } else if (data.time_slot === "outlet_differant_day") {
      schedules = [];
      data.settings.time_slot_setting =
        "different_time_slots_for_different_days";

      let sunday =
        data.weak &&
        data.weak.sunday &&
        data.weak.sunday.filter((item, index) => {
          if (item.start_time !== undefined && item.end_time !== undefined) {
            return item;
          }
        });
      sunday &&
        sunday.length > 0 &&
        schedules.push({
          days_of_week: [7],
          time_slots: sunday,
        });
      let monday =
        data.weak &&
        data.weak.monday &&
        data.weak.monday.filter((item, index) => {
          if (item.start_time !== undefined && item.end_time !== undefined) {
            return item;
          }
        });
      monday &&
        monday.length > 0 &&
        schedules.push({
          days_of_week: [1],
          time_slots: monday,
        });

      let tuesday =
        data.weak &&
        data.weak.tuesday &&
        data.weak.tuesday.filter((item, index) => {
          if (item.start_time !== undefined && item.end_time !== undefined) {
            return item;
          }
        });
      tuesday &&
        tuesday.length > 0 &&
        schedules.push({
          days_of_week: [2],
          time_slots: tuesday,
        });

      let wednesday =
        data.weak &&
        data.weak.wednesday &&
        data.weak.wednesday.filter((item, index) => {
          if (item.start_time !== undefined && item.end_time !== undefined) {
            return item;
          }
        });
      wednesday &&
        wednesday.length > 0 &&
        schedules.push({
          days_of_week: [3],
          time_slots: wednesday,
        });

      let thursday =
        data.weak &&
        data.weak.thursday &&
        data.weak.thursday.filter((item, index) => {
          if (item.start_time !== undefined && item.end_time !== undefined) {
            return item;
          }
        });
      thursday &&
        thursday.length > 0 &&
        schedules.push({
          days_of_week: [4],
          time_slots: thursday,
        });

      let friday =
        data.weak &&
        data.weak.friday &&
        data.weak.friday.filter((item, index) => {
          if (item.start_time !== undefined && item.end_time !== undefined) {
            return item;
          }
        });
      friday &&
        friday.length > 0 &&
        schedules.push({
          days_of_week: [5],
          time_slots: friday,
        });

      let saturday =
        data.weak &&
        data.weak.saturday &&
        data.weak.saturday.filter((item, index) => {
          if (item.start_time !== undefined && item.end_time !== undefined) {
            return item;
          }
        });
      saturday &&
        saturday.length > 0 &&
        schedules.push({
          days_of_week: [6],
          time_slots: saturday,
        });

      data.settings.schedules = schedules.length > 0 ? schedules : null;
      if (!data.settings.schedules) {
        setTimeSlotError(true);
        return false;
      }
    } else {
      data.settings.schedules = null;
      data.settings.time_slot_setting = "no_time_slots";
    }
    setTimeSlotError(false);
    data.payment_settings = payment_settings;
    console.log("AllData ", data);
    console.log(JSON.stringify(data));
    setLoader(true);
    if (editOutlet) {
      data.id = editOutlet.outlet_id;
      const editOutletInfo = await editmutate({ data });
      if (editOutletInfo.success) {
        alert("Edit outlet successfully..");
      } else {
        alert("Please try later");
      }
    } else {
      const addOutlet = await mutate({ data });
      if (addOutlet.success) {
        alert("Add outlet successfully..");
      } else if (addOutlet.message) {
        alert(addOutlet.message);
      } else {
        alert("please try later");
      }
    }
    setLoader(false);
  };
  return (
    <>
      <EuiForm
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        style={{ marginTop: 20 }}
      >
        {languages &&
          languages.map((item, index) => (
            <TextField
              errors={errors}
              isInvalid={
                errors.translations &&
                errors.translations.name &&
                errors.translations.name.hasOwnProperty(item.language_id)
              }
              error={
                errors.translations &&
                errors.translations.name &&
                errors.translations.name.hasOwnProperty(item.language_id) &&
                errors.translations.name[item.language_id]
                  ? errors.translations.name[item.language_id].message
                  : ""
              }
              label={`${item.language_label} : Name`}
              name={`translations.name.${item.language_id}`}
              placeholder={`Enter the name of the outlet in ${item.language_label}`}
              rules={{ required: "Please enter name" }}
              control={control}
              watch={watch}
            />
          ))}
        {/* <NumberField
          label="Contact number"
          errors={errors}
          name="contact_number"
          placeholder="Enter the contact number of the outlet"
          rules={{
            required: "Please Enter your contact number",
            minLength: {
              value: 10,
              message: " length should be 10",
            },
            maxLength: {
              value: 10,
              message: " length should be 10",
            },
            validate: (value) => value >= 0 || "Please enter positive value",
          }}
          min={0}
          minlength={10}
          maxlength={10}
          control={control}
          watch={watch}
        /> */}

        <EuiFlexItem style={{ width: "100%" }}>
          <MobileNumber
            watch={watch}
            setValue={setValue}
            label={"Contact number"}
            isInvalid={errors["contact_number"]}
            errors={errors}
            placeholder="Enter the contact number of the outlet"
            name={"contact_number"}
            control={control}
            rules={true}
          />
        </EuiFlexItem>

        {languages &&
          languages.map((item, index) => (
            <Fragment
              style={{ marginTop: 20, marginBottom: 20 }}
              key={item.language_id}
            >
              <TextAreaField
                errors={errors}
                isInvalid={
                  errors.translations &&
                  errors.translations.address_line_1 &&
                  errors.translations.address_line_1.hasOwnProperty(
                    item.language_id
                  )
                }
                error={
                  errors.translations &&
                  errors.translations.address_line_1 &&
                  errors.translations.address_line_1.hasOwnProperty(
                    item.language_id
                  ) &&
                  errors.translations.address_line_1[item.language_id]
                    ? errors.translations.address_line_1[item.language_id]
                        .message
                    : ""
                }
                label={`${item.language_label} : Address Line 1`}
                name={`translations.address_line_1.${item.language_id}`}
                placeholder="Write address line 1"
                rules={{ required: "Please write address line 1" }}
                control={control}
                watch={watch}
                rows={4}
              />

              <TextAreaField
                errors={errors}
                isInvalid={
                  errors.translations &&
                  errors.translations.address_line_2 &&
                  errors.translations.address_line_2.hasOwnProperty(
                    item.language_id
                  )
                }
                error={
                  errors.translations &&
                  errors.translations.address_line_2 &&
                  errors.translations.address_line_2.hasOwnProperty(
                    item.language_id
                  ) &&
                  errors.translations.address_line_2[item.language_id]
                    ? errors.translations.address_line_2[item.language_id]
                        .message
                    : ""
                }
                label={`${item.language_label} : Address Line 2`}
                name={`translations.address_line_2.${item.language_id}`}
                placeholder="Write address line 1"
                rules={{ required: "Please write address line 1" }}
                control={control}
                watch={watch}
                rows={4}
              />

              <TextField
                errors={errors}
                isInvalid={
                  errors.translations &&
                  errors.translations.city &&
                  errors.translations.city.hasOwnProperty(item.language_id)
                }
                error={
                  errors.translations &&
                  errors.translations.city &&
                  errors.translations.city.hasOwnProperty(item.language_id) &&
                  errors.translations.city[item.language_id]
                    ? errors.translations.city[item.language_id].message
                    : ""
                }
                label={`${item.language_label} : City`}
                name={`translations.city.${item.language_id}`}
                placeholder="Please Enter city"
                rules={{ required: "Please enter city" }}
                control={control}
                watch={watch}
              />

              <TextField
                errors={errors}
                isInvalid={
                  errors.translations &&
                  errors.translations.state &&
                  errors.translations.state.hasOwnProperty(item.language_id)
                }
                error={
                  errors.translations &&
                  errors.translations.state &&
                  errors.translations.state.hasOwnProperty(item.language_id) &&
                  errors.translations.state[item.language_id]
                    ? errors.translations.state[item.language_id].message
                    : ""
                }
                label={`${item.language_label} : State`}
                name={`translations.state.${item.language_id}`}
                placeholder="Please Enter state"
                rules={{ required: "Please enter state" }}
                control={control}
                watch={watch}
              />
            </Fragment>
          ))}
        <SelectField
          errors={errors}
          label="Country"
          name="iso_country_code"
          options={countryCode}
          valueOfSelected={
            watch("iso_country_code") ? watch("iso_country_code") : "IN"
          }
          onChangeData={(value) => setValue("iso_country_code", value)}
          placeholder="Please enter your country"
          // rules={{ required: "Please Enter your country" }}
          control={control}
          watch={watch}
          defaultValue={
            watch("iso_country_code") ? watch("iso_country_code") : "IN"
          }
        />

        <EuiFlexGroup alignItems="center" style={{ marginTop: "10px" }}>
          <EuiFlexItem grow={false}>
            <EuiFormRow isInvalid={errors.gst_applicable} fullWidth={true}>
              <Controller
                render={({ onChange }) => (
                  <EuiRadioGroup
                    options={gst_applicable}
                    idSelected={
                      watch("gst_applicable") ? watch("gst_applicable") : 0
                    }
                    onChange={(id) =>
                      setValue(
                        "gst_applicable",
                        id === 0 ? 0 : id === 1 ? 1 : 0
                      )
                    }
                    legend={{
                      children: (
                        <span> Do you want to apply GST or not ? </span>
                      ),
                    }}
                  />
                )}
                name="gst_applicable"
                defaultValue={
                  watch("gst_applicable") ? watch("gst_applicable") : 0
                }
                control={control}
                fullWidth={true}
              />
            </EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup>
        {watch("gst_applicable") === 1 && (
          <>
            <TextField
              errors={errors}
              label="Gst number"
              name="gst_number"
              placeholder="Please enter gst number"
              rules={{ required: "Please enter gst number" }}
              control={control}
              watch={watch}
            />
            <SelectField
              errors={errors}
              label="Delivery charge GST Slug"
              name="delivery_charges_gst_slab"
              options={slub}
              valueOfSelected={
                watch("delivery_charges_gst_slab")
                  ? watch("delivery_charges_gst_slab")
                  : slub[0].value
              }
              onChangeData={(value) =>
                setValue("delivery_charges_gst_slab", value)
              }
              placeholder="Please select gst slub"
              rules={{
                required: "Please enter delivery charge gst slub",
              }}
              control={control}
              watch={watch}
              defaultValue={
                watch("delivery_charges_gst_slab")
                  ? watch("delivery_charges_gst_slab")
                  : slub[0].value
              }
            />
          </>
        )}

        <TextField
          errors={errors}
          label="FSSAI Licence"
          name="fssai_licence"
          placeholder="Please enter fssai licence"
          control={control}
          watch={watch}
        />

        <NumberField
          label="Latitude"
          errors={errors}
          name="latitude"
          placeholder="Please Enter latitude"
          rules={{
            required: "Please enter latitude",
          }}
          step="any"
          control={control}
        />

        <NumberField
          label="Longitude"
          errors={errors}
          name="longitude"
          placeholder="Please Enter longitude"
          rules={{
            required: "Please enter longitude",
          }}
          step="any"
          control={control}
        />
        <NumberField
          label="Radius"
          errors={errors}
          name="service_radius"
          placeholder="Please Enter radius"
          rules={{
            required: "Please enter radius",
          }}
          prepend={
            <EuiToolTip content="content">
              <EuiText size="s">In km</EuiText>
            </EuiToolTip>
          }
          step="any"
          control={control}
        />

        <NumberField
          label="Kitchen Prep Time Slots"
          errors={errors}
          name="kitchen_preparation_time"
          placeholder="Please enter kitchen preparation time"
          rules={{
            required: "Please enter kitchen preparation time",
            validate: (value) => value > 0 || "Please enter positive value",
          }}
          step="any"
          min={0}
          control={control}
        />

        <RadioField
          name="kitchen_time_slot"
          label=" Decide kitchen rush hour slot"
          options={kitchen_timeSlot}
          idSelected={
            watch("kitchen_time_slot") === 6
              ? 6
              : watch("kitchen_time_slot") === 7
              ? 7
              : watch("kitchen_time_slot") === 8
              ? 8
              : 6
          }
          onChangeData={(id) =>
            setValue(
              "kitchen_time_slot",
              id === 6 ? 6 : id === 7 ? 7 : id === 8 ? 8 : 6
            )
          }
          control={control}
          defaultValue={6}
        />

        <EuiFlexGroup style={{ width: "80%" }}>
          <EuiFlexItem>
            {watch("kitchen_time_slot") === 7 && (
              <>
                <EuiFlexGroup
                  style={{
                    background: "#9895CA",
                    marginTop: "20px",
                    marginBottom: "30px",
                  }}
                >
                  <EuiFlexItem grow={2}>Days</EuiFlexItem>
                  <EuiFlexItem grow={4}>Start Time</EuiFlexItem>
                  <EuiFlexItem grow={4}>End Time</EuiFlexItem>
                  <EuiFlexItem grow={4}>Kitchen Prepation Time</EuiFlexItem>
                </EuiFlexGroup>

                <KitchenTimeSlot
                  control={control}
                  setValue={setValue}
                  name="kitchen_time"
                  errors={errors}
                  watch={watch}
                />
              </>
            )}
            {watch("kitchen_time_slot") === 8 && (
              <>
                <EuiFlexGroup
                  style={{
                    background: "#9895CA",
                    marginTop: "20px",
                    marginBottom: "30px",
                  }}
                >
                  <EuiFlexItem grow={2}>Days</EuiFlexItem>
                  <EuiFlexItem grow={4}>Start Time</EuiFlexItem>
                  <EuiFlexItem grow={4}>End Time</EuiFlexItem>
                  <EuiFlexItem grow={4}>Kitchen Prepation Time</EuiFlexItem>
                  <EuiFlexItem grow={1}></EuiFlexItem>
                </EuiFlexGroup>

                <EuiFlexGroup
                  style={{
                    marginBottom: 30,
                    paddingBottom: 40,
                    borderBottom: "1px solid black",
                  }}
                >
                  <EuiFlexGroup
                    style={{
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <EuiFlexItem
                      style={{
                        textAlign: "left",
                      }}
                    >
                      <strong> Sunday </strong>{" "}
                    </EuiFlexItem>

                    <KitchenTimeSlot
                      control={control}
                      setValue={setValue}
                      name="kitchen_time_weak.sunday"
                      watch={watch}
                    />
                  </EuiFlexGroup>
                </EuiFlexGroup>

                <EuiFlexGroup
                  style={{
                    marginBottom: 30,
                    paddingBottom: 40,
                    borderBottom: "1px solid black",
                  }}
                >
                  <EuiFlexGroup
                    style={{
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <EuiFlexItem
                      style={{
                        textAlign: "left",
                        marginLeft: "3%",
                      }}
                    >
                      <strong> Monday </strong>
                    </EuiFlexItem>
                    <KitchenTimeSlot
                      control={control}
                      setValue={setValue}
                      name="kitchen_time_weak.monday"
                      watch={watch}
                    />
                  </EuiFlexGroup>
                </EuiFlexGroup>

                <EuiFlexGroup
                  style={{
                    marginBottom: 30,
                    paddingBottom: 40,
                    borderBottom: "1px solid black",
                  }}
                >
                  <EuiFlexGroup
                    style={{
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <EuiFlexItem
                      style={{
                        textAlign: "left",
                        marginLeft: "3%",
                      }}
                    >
                      {" "}
                      <strong> Tuesday </strong>{" "}
                    </EuiFlexItem>

                    <KitchenTimeSlot
                      control={control}
                      setValue={setValue}
                      name="kitchen_time_weak.tuesday"
                      watch={watch}
                    />
                  </EuiFlexGroup>
                </EuiFlexGroup>

                <EuiFlexGroup
                  style={{
                    marginBottom: 30,
                    paddingBottom: 40,
                    borderBottom: "1px solid black",
                  }}
                >
                  <EuiFlexGroup
                    style={{
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <EuiFlexItem
                      style={{
                        textAlign: "left",
                        marginLeft: "3%",
                      }}
                    >
                      {" "}
                      <strong> Wednesday </strong>{" "}
                    </EuiFlexItem>

                    <KitchenTimeSlot
                      control={control}
                      setValue={setValue}
                      name="kitchen_time_weak.wednesday"
                      watch={watch}
                    />
                  </EuiFlexGroup>
                </EuiFlexGroup>

                <EuiFlexGroup
                  style={{
                    marginBottom: 30,
                    paddingBottom: 40,
                    borderBottom: "1px solid black",
                    justifyContent: "center",
                  }}
                >
                  <EuiFlexGroup
                    style={{
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <EuiFlexItem
                      style={{
                        textAlign: "left",
                        marginLeft: "3%",
                      }}
                    >
                      <strong> Thursday </strong>
                    </EuiFlexItem>
                    <KitchenTimeSlot
                      control={control}
                      setValue={setValue}
                      name="kitchen_time_weak.thursday"
                      watch={watch}
                    />
                  </EuiFlexGroup>
                </EuiFlexGroup>

                <EuiFlexGroup
                  style={{
                    marginBottom: 30,
                    paddingBottom: 40,
                    borderBottom: "1px solid black",
                  }}
                >
                  <EuiFlexGroup
                    style={{
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <EuiFlexItem
                      style={{
                        textAlign: "left",
                        marginLeft: "3%",
                      }}
                    >
                      <strong> Friday </strong>
                    </EuiFlexItem>
                    <KitchenTimeSlot
                      control={control}
                      setValue={setValue}
                      name="kitchen_time_weak.friday"
                      watch={watch}
                    />
                  </EuiFlexGroup>
                </EuiFlexGroup>

                <EuiFlexGroup
                  style={{
                    marginBottom: 30,
                    paddingBottom: 40,
                    borderBottom: "1px solid black",
                    justifyContent: "center",
                  }}
                >
                  <EuiFlexGroup
                    style={{
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <EuiFlexItem
                      style={{
                        textAlign: "left",
                        marginLeft: "3%",
                      }}
                    >
                      <strong> Saturday </strong>
                    </EuiFlexItem>
                    <KitchenTimeSlot
                      control={control}
                      setValue={setValue}
                      name="kitchen_time_weak.saturday"
                      watch={watch}
                    />
                  </EuiFlexGroup>
                </EuiFlexGroup>
              </>
            )}
          </EuiFlexItem>
        </EuiFlexGroup>

        <NumberField
          label="Delivery charge thresold"
          errors={errors}
          name="delivery_charge_threshold"
          placeholder="Please enter delivery charge thresold"
          rules={{
            required: "Please enter delivery charge thresold",
          }}
          step="any"
          control={control}
        />

        <NumberField
          label="Above thresold"
          errors={errors}
          name="delivery_charge_above_threshold"
          placeholder="Please enter delivery above thresold"
          rules={{
            required: "Please enter delivery above thresold",
          }}
          step="any"
          control={control}
        />

        <NumberField
          label="Below thresold"
          errors={errors}
          name="delivery_charge_below_threshold"
          placeholder="Please enter delivery below thresold"
          rules={{
            required: "Please enter delivery below thresold",
          }}
          step="any"
          control={control}
        />

        <EuiFormRow>
          <EuiFlexGroup alignItems="center">
            <EuiFlexItem grow={false}>
              <Controller
                render={({ onChange, value }) => (
                  <EuiCheckbox
                    onChange={(e) => {
                      onChange(e.target.checked);
                      setValue("prize_per_kilometer", e.target.checked);
                    }}
                    checked={value}
                    compressed
                  />
                )}
                name="prize_per_kilometer"
                control={control}
                defaultValue={false}
              />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiText>Charge additional prize per kilometer ?</EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFormRow>

        {watch("prize_per_kilometer") && (
          <Fragment style={{ marginLeft: 20 }}>
            <NumberField
              label="Normal delivery radius"
              errors={errors}
              name="normal_delivery_radius"
              placeholder="Please enter normal delivery radius"
              rules={{
                required: "Please enter normal delivery radius",
                validate: (value) => value > 0 || "Please enter positive value",
              }}
              prepend={
                <EuiToolTip content="content">
                  <EuiText size="s">In km</EuiText>
                </EuiToolTip>
              }
              step="any"
              min={0}
              control={control}
            />

            <NumberField
              label="Extra Charge"
              errors={errors}
              name="extra_delivery_charge"
              placeholder="Please enter extra charge"
              rules={{
                required: "Please enter normal delivery radius",
                validate: (value) => value >= 0 || "Please enter positive value",
              }}
              step="any"
              min={0}
              control={control}
            />
          </Fragment>
        )}

        <NumberField
          label="Packaging charge per order"
          errors={errors}
          name="packaging_charge"
          placeholder="Please enter packaging charge"
          rules={{
            required: "Please enter packaging charge",
          }}
          step="any"
          control={control}
        />

        <NumberField
          label="Convinience Charge"
          errors={errors}
          name="convinience_charge"
          placeholder="Please enter delivery charge thresold"
          rules={{
            required: "Please enter delivery charge thresold",
          }}
          step="any"
          control={control}
        />

        <EuiFormRow fullWidth={true}>
          <>
            <EuiFlexGroup alignItems="center">
              <EuiFlexItem grow={false}>
                <CheckBoxField
                  name="vegetarian_flag"
                  defaultValue={false}
                  control={control}
                  setValue={setValue}
                />
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiText>Vegetarian</EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiFlexGroup alignItems="center">
              <EuiFlexItem grow={false}>
                <CheckBoxField
                  name="non_vegetarian_flag"
                  defaultValue={false}
                  control={control}
                  setValue={setValue}
                />
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiText>Non-vegetarian</EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiFlexGroup alignItems="center">
              <EuiFlexItem grow={false}>
                <CheckBoxField
                  name="egg_flag"
                  defaultValue={false}
                  control={control}
                  setValue={setValue}
                />
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiText>Egg</EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
          </>
        </EuiFormRow>

        <ComboBoxField
          options={currencyDataSymbol}
          selectedOptions={
            watch("currency_symbol") ? watch("currency_symbol") : []
          }
          onChangeData={(value) => setValue("currency_symbol", value)}
          errors={errors}
          label="Currency Symbol"
          name="currency_symbol"
          placeholder="Please enter your currency symbol"
          rules={{
            validate: (value) =>
              (watch("currency_symbol") &&
                watch("currency_symbol").length > 0) ||
              "Please select currency symbol",
          }}
          control={control}
          watch={watch}
          singleSelection={{ asPlainText: true }}
        />

        <ComboBoxField
          options={currencyDataCode}
          selectedOptions={watch("currency_code") ? watch("currency_code") : []}
          onChangeData={(value) => {
            setValue("currency_code", value);
            delete errors["currency_code"];
          }}
          errors={errors}
          label="Currency Code"
          name="currency_code"
          placeholder="Please enter your currency code"
          rules={{
            validate: (value) =>
              (watch("currency_code") && watch("currency_code").length > 0) ||
              "Please select currency code",
          }}
          control={control}
          watch={watch}
          singleSelection={{ asPlainText: true }}
        />

        <ComboBoxField
          options={payment_provider_options}
          selectedOptions={
            watch("payment_provider") ? watch("payment_provider") : undefined
          }
          onChangeData={onChangePaymentProvider}
          errors={errors}
          label="Select payment provider"
          name="payment_provider"
          placeholder="Select one or more payment provider"
          rules={{
            validate: (value) =>
              (watch("payment_provider") &&
                watch("payment_provider").length > 0) ||
              "Please select atleast one payment provider",
          }}
          control={control}
          watch={watch}
        />

        <EuiFormRow fullWidth>
          <EuiFlexGroup>
            {watch("payment_provider") &&
              watch("payment_provider").map((item) => {
                if (item.label === "paytm") {
                  return (
                    <>
                      <EuiFlexItem
                        key={item.id}
                        style={{
                          width: "30%",
                          minWidth: "30%",
                          flex: "0 1 30%",
                        }}
                      >
                        <EuiCard style={{ margin: 10 }}>
                          <EuiText style={{ textAlign: "center" }}>
                            <b> Paytm</b>
                          </EuiText>
                          <TextField
                            errors={errors}
                            label="Paytm merchent key"
                            name="paytm_merchent_key"
                            placeholder="Please enter paytm merchent key"
                            rules={{
                              required: "Please enter paytm merchent key",
                            }}
                            control={control}
                            watch={watch}
                          />
                          <TextField
                            errors={errors}
                            label="Paytm merchent id"
                            name="paytm_merchent_id"
                            placeholder="Please enter paytm merchent id"
                            rules={{
                              required: "Please enter paytm merchent id",
                            }}
                            control={control}
                            watch={watch}
                          />
                          <TextField
                            errors={errors}
                            label="Paytm industry type id"
                            name="paytm_industry_type_id"
                            placeholder="Please enter paytm industry type id"
                            rules={{
                              required: "Please enter paytm industry type id",
                            }}
                            control={control}
                            watch={watch}
                          />
                          <TextField
                            errors={errors}
                            label="Paytm website"
                            name="paytm_website"
                            placeholder="Please enter paytm website"
                            rules={{ required: "Please enter paytm website" }}
                            control={control}
                            watch={watch}
                          />
                          <TextField
                            errors={errors}
                            label="Paytm base api url"
                            name="paytm_base_api_url"
                            placeholder="Please enter paytm base api url"
                            rules={{
                              required: "Please enter paytm base api url",
                            }}
                            control={control}
                            watch={watch}
                          />
                        </EuiCard>
                      </EuiFlexItem>
                    </>
                  );
                }
                if (item.label === "stripe") {
                  return (
                    <>
                      <EuiFlexItem
                        key={item.id}
                        style={{
                          width: "30%",
                          minWidth: "30%",
                          flex: "0 1 30%",
                        }}
                      >
                        <EuiCard style={{ margin: 10 }}>
                          <EuiText style={{ textAlign: "center" }}>
                            {" "}
                            <b> Stripe </b>{" "}
                          </EuiText>
                          <TextField
                            errors={errors}
                            label="Stripe key"
                            name="stripe_key"
                            placeholder="Please enter stripe key"
                            rules={{ required: "Please enter stripe key" }}
                            control={control}
                            watch={watch}
                          />
                          <TextField
                            errors={errors}
                            label="Stripe secret"
                            name="stripe_secret"
                            placeholder="Please enter stripe secret"
                            rules={{ required: "Please enter stripe secret" }}
                            control={control}
                            watch={watch}
                          />
                          <TextField
                            errors={errors}
                            label="Stripe webhook"
                            name="stripe_webhook"
                            placeholder="Please enter stripe webhook"
                            rules={{ required: "Please enter stripe webhook" }}
                            control={control}
                            watch={watch}
                          />
                          <TextField
                            errors={errors}
                            label="Stripe webhook secret"
                            name="stripe_webhook_secret"
                            placeholder="Please enter webhook secret"
                            rules={{ required: "Please enter webhook secret" }}
                            control={control}
                            watch={watch}
                          />
                        </EuiCard>
                      </EuiFlexItem>
                    </>
                  );
                }
              })}
          </EuiFlexGroup>
        </EuiFormRow>

        <EuiFormRow>
          <EuiFlexGroup direction="column">
            {watch("payment_provider") && watch("payment_provider").length > 0 && (
              <EuiFlexItem>
                <SelectField
                  errors={errors}
                  label="Credit card / Dabit card"
                  name="credit_card"
                  options={paymentOptions}
                  valueOfSelected={
                    watch("credit_card")
                      ? watch("credit_card")
                      : paymentOptions[0].value
                  }
                  onChangeData={(value) => setValue("credit_card", value)}
                  placeholder="Please select credit card / dabit card"
                  rules={{ required: "Please select credit card / dabit card" }}
                  control={control}
                  watch={watch}
                  defaultValue={
                    watch("credit_card")
                      ? watch("credit_card")
                      : paymentOptions[0].value
                  }
                />
              </EuiFlexItem>
            )}

            {watch("payment_provider") &&
              watch("payment_provider").map((item) => {
                if (item.label === "paytm") {
                  return (
                    <>
                      <EuiFlexItem key={item.id}>
                        <SelectField
                          errors={errors}
                          label="Net banking"
                          name="net_banking"
                          options={optionsPaytm}
                          valueOfSelected={optionsPaytm[0].value}
                          placeholder="Please select net banking"
                          rules={{ required: "Please select net banking" }}
                          control={control}
                          watch={watch}
                          defaultValue={optionsPaytm[0].value}
                        />

                        <SelectField
                          errors={errors}
                          label="Balance"
                          name="balance"
                          options={optionsPaytm}
                          valueOfSelected={optionsPaytm[0].value}
                          placeholder="Please select balance"
                          rules={{ required: "Please select balance" }}
                          control={control}
                          watch={watch}
                          defaultValue={optionsPaytm[0].value}
                        />

                        <SelectField
                          errors={errors}
                          label="UPI"
                          name="upi"
                          options={optionsPaytm}
                          valueOfSelected={optionsPaytm[0].value}
                          placeholder="Please select upi"
                          rules={{ required: "Please select upi" }}
                          control={control}
                          watch={watch}
                          defaultValue={optionsPaytm[0].value}
                        />

                        <SelectField
                          errors={errors}
                          label="Upi intent"
                          name="upi_intent"
                          options={optionsPaytm}
                          valueOfSelected={optionsPaytm[0].value}
                          placeholder="Please select upi intent"
                          rules={{ required: "Please select upi intent" }}
                          control={control}
                          watch={watch}
                          defaultValue={optionsPaytm[0].value}
                        />
                      </EuiFlexItem>
                    </>
                  );
                }
              })}
          </EuiFlexGroup>
        </EuiFormRow>

        <EuiFormRow>
          <>
            <EuiFlexGroup alignItems="center">
              <EuiFlexItem grow={false}>
                <CheckBoxField
                  name="case_on_delivery"
                  defaultValue={false}
                  control={control}
                  setValue={setValue}
                />
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiText>Case on delivery</EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
          </>
        </EuiFormRow>

        <EuiFormRow
          fullWidth={true}
          label="Module options for the restaurant :-"
        >
          <>
            <EuiFlexGroup alignItems="center">
              <EuiFlexItem grow={false}>
                <SwitchField
                  name="delivery"
                  defaultValue={false}
                  control={control}
                  setValue={setValue}
                />
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiText>Delivery</EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>

            {watch("delivery") === true && (
              <>
                <EuiFlexGroup alignItems="center" style={{ marginTop: "10px" }}>
                  <EuiFlexItem grow={false}>
                    <EuiFormRow
                      isInvalid={errors.timer_for_banner}
                      fullWidth={true}
                    >
                      <Controller
                        render={({ onChange }) => (
                          <EuiRadioGroup
                            options={deliveryTimeSlot}
                            idSelected={
                              watch("timer_for_delivery") === 9
                                ? 9
                                : watch("timer_for_delivery") === 10
                                ? 10
                                : watch("timer_for_delivery") === 11
                                ? 11
                                : 9
                            }
                            onChange={(id) =>
                              setValue(
                                "timer_for_delivery",
                                id === 9
                                  ? 9
                                  : id === 10
                                  ? 10
                                  : id === 11
                                  ? 11
                                  : 9
                              )
                            }
                            legend={{
                              children: (
                                <span> Decide this delivery lifetime </span>
                              ),
                            }}
                          />
                        )}
                        name="timer_for_delivery"
                        defaultValue={9}
                        control={control}
                        fullWidth={true}
                      />
                    </EuiFormRow>
                  </EuiFlexItem>
                </EuiFlexGroup>
                <EuiFlexGroup style={{ width: "80%" }}>
                  <EuiFlexItem>
                    {watch("timer_for_delivery") === 10 && (
                      <>
                        <EuiFlexGroup
                          style={{
                            background: "#9895CA",
                            marginTop: "20px",
                            marginBottom: "30px",
                          }}
                        >
                          <EuiFlexItem grow={2}>Days</EuiFlexItem>
                          <EuiFlexItem grow={4}>Start Time</EuiFlexItem>
                          <EuiFlexItem grow={4}>End Time</EuiFlexItem>
                        </EuiFlexGroup>
                        <TimeSlot
                          control={control}
                          setValue={setValue}
                          name="timeForDelivery"
                          errors={errors}
                          watch={watch}
                        />
                      </>
                    )}
                    {watch("timer_for_delivery") === 11 && (
                      <>
                        <EuiFlexGroup
                          style={{
                            background: "#9895CA",
                            marginTop: "20px",
                            marginBottom: "30px",
                          }}
                          columns={3}
                        >
                          <EuiFlexItem grow={2}>Days</EuiFlexItem>
                          <EuiFlexItem grow={4}>Start Time</EuiFlexItem>
                          <EuiFlexItem grow={4}>End Time</EuiFlexItem>
                        </EuiFlexGroup>

                        <EuiFlexGroup
                          style={{
                            marginBottom: 30,
                            paddingBottom: 40,
                            borderBottom: "1px solid black",
                          }}
                        >
                          <EuiFlexGroup
                            style={{
                              flexDirection: "column",
                              textAlign: "center",
                            }}
                          >
                            <EuiFlexItem
                              style={{
                                textAlign: "left",
                                marginLeft: "3%",
                              }}
                            >
                              <strong> Sunday </strong>
                            </EuiFlexItem>

                            <TimeSlot
                              control={control}
                              setValue={setValue}
                              name={`weakDelivery.sunday`}
                              errors={errors}
                              watch={watch}
                            />
                          </EuiFlexGroup>
                        </EuiFlexGroup>

                        <EuiFlexGroup
                          style={{
                            marginBottom: 30,
                            paddingBottom: 40,
                            borderBottom: "1px solid black",
                          }}
                        >
                          <EuiFlexGroup
                            style={{
                              flexDirection: "column",
                              textAlign: "center",
                            }}
                          >
                            <EuiFlexItem
                              style={{
                                textAlign: "left",
                                marginLeft: "3%",
                              }}
                            >
                              {" "}
                              <strong> Monday </strong>{" "}
                            </EuiFlexItem>
                            <TimeSlot
                              control={control}
                              setValue={setValue}
                              name={`weakDelivery.monday`}
                              errors={errors}
                              watch={watch}
                            />
                          </EuiFlexGroup>
                        </EuiFlexGroup>

                        <EuiFlexGroup
                          style={{
                            marginBottom: 30,
                            paddingBottom: 40,
                            borderBottom: "1px solid black",
                          }}
                        >
                          <EuiFlexGroup
                            style={{
                              flexDirection: "column",
                              textAlign: "center",
                            }}
                          >
                            <EuiFlexItem
                              style={{
                                textAlign: "left",
                                marginLeft: "3%",
                              }}
                            >
                              {" "}
                              <strong> Tuesday </strong>{" "}
                            </EuiFlexItem>
                            <TimeSlot
                              control={control}
                              setValue={setValue}
                              name={`weakDelivery.tuesday`}
                              errors={errors}
                              watch={watch}
                            />
                          </EuiFlexGroup>
                        </EuiFlexGroup>

                        <EuiFlexGroup
                          style={{
                            marginBottom: 30,
                            paddingBottom: 40,
                            borderBottom: "1px solid black",
                          }}
                        >
                          <EuiFlexGroup
                            style={{
                              flexDirection: "column",
                              textAlign: "center",
                            }}
                          >
                            <EuiFlexItem
                              style={{
                                textAlign: "left",
                                marginLeft: "3%",
                              }}
                            >
                              {" "}
                              <strong> Wednesday </strong>{" "}
                            </EuiFlexItem>
                            <TimeSlot
                              control={control}
                              setValue={setValue}
                              name={`weakDelivery.wednesday`}
                              errors={errors}
                              watch={watch}
                            />
                          </EuiFlexGroup>
                        </EuiFlexGroup>

                        <EuiFlexGroup
                          style={{
                            marginBottom: 30,
                            paddingBottom: 40,
                            borderBottom: "1px solid black",
                            justifyContent: "center",
                          }}
                        >
                          <EuiFlexGroup
                            style={{
                              flexDirection: "column",
                              textAlign: "center",
                            }}
                          >
                            <EuiFlexItem
                              style={{
                                textAlign: "left",
                                marginLeft: "3%",
                              }}
                            >
                              {" "}
                              <strong> Thursday </strong>{" "}
                            </EuiFlexItem>
                            <TimeSlot
                              control={control}
                              setValue={setValue}
                              name={`weakDelivery.thursday`}
                              errors={errors}
                              watch={watch}
                            />
                          </EuiFlexGroup>
                        </EuiFlexGroup>

                        <EuiFlexGroup
                          style={{
                            marginBottom: 30,
                            paddingBottom: 40,
                            borderBottom: "1px solid black",
                          }}
                        >
                          <EuiFlexGroup
                            style={{
                              flexDirection: "column",
                              textAlign: "center",
                            }}
                          >
                            <EuiFlexItem
                              style={{
                                textAlign: "left",
                                marginLeft: "3%",
                              }}
                            >
                              {" "}
                              <strong> Friday </strong>{" "}
                            </EuiFlexItem>
                            <TimeSlot
                              control={control}
                              setValue={setValue}
                              name={`weakDelivery.friday`}
                              errors={errors}
                              watch={watch}
                            />
                          </EuiFlexGroup>
                        </EuiFlexGroup>

                        <EuiFlexGroup
                          style={{
                            marginBottom: 30,
                            paddingBottom: 40,
                            borderBottom: "1px solid black",
                          }}
                        >
                          <EuiFlexGroup
                            style={{
                              flexDirection: "column",
                              textAlign: "center",
                            }}
                          >
                            <EuiFlexItem
                              style={{
                                textAlign: "left",
                                marginLeft: "3%",
                              }}
                            >
                              {" "}
                              <strong> Saturday </strong>{" "}
                            </EuiFlexItem>
                            <TimeSlot
                              control={control}
                              setValue={setValue}
                              name={`weakDelivery.saturday`}
                              errors={errors}
                              watch={watch}
                            />
                          </EuiFlexGroup>
                        </EuiFlexGroup>
                      </>
                    )}
                  </EuiFlexItem>
                </EuiFlexGroup>
              </>
            )}

            <EuiFlexGroup alignItems="center">
              <EuiFlexItem grow={false}>
                <SwitchField
                  name="dine_in"
                  defaultValue={false}
                  control={control}
                  setValue={setValue}
                />
              </EuiFlexItem>

              <EuiFlexItem grow={false}>
                <EuiText>Dine-in</EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>

            {watch("dine_in") === true && (
              <>
                <EuiFlexGroup alignItems="center" style={{ marginTop: "10px" }}>
                  <EuiFlexItem grow={false}>
                    <EuiFormRow
                      isInvalid={errors.timer_for_banner}
                      fullWidth={true}
                    >
                      <Controller
                        render={({ onChange }) => (
                          <EuiRadioGroup
                            options={dineInTimeSlot}
                            idSelected={
                              watch("timer_for_dine_in") === 12
                                ? 12
                                : watch("timer_for_dine_in") === 13
                                ? 13
                                : watch("timer_for_dine_in") === 14
                                ? 14
                                : 12
                            }
                            onChange={(id) =>
                              setValue(
                                "timer_for_dine_in",
                                id === 12
                                  ? 12
                                  : id === 13
                                  ? 13
                                  : id === 14
                                  ? 14
                                  : 12
                              )
                            }
                            legend={{
                              children: (
                                <span> Decide this dine-in lifetime </span>
                              ),
                            }}
                          />
                        )}
                        name="timer_for_dine_in"
                        defaultValue={12}
                        control={control}
                        fullWidth={true}
                      />
                    </EuiFormRow>
                  </EuiFlexItem>
                </EuiFlexGroup>
                <EuiFlexGroup style={{ width: "80%" }}>
                  <EuiFlexItem>
                    {watch("timer_for_dine_in") === 13 && (
                      <>
                        <EuiFlexGroup
                          style={{
                            background: "#9895CA",
                            marginTop: "20px",
                            marginBottom: "30px",
                          }}
                        >
                          <EuiFlexItem grow={2}>Days</EuiFlexItem>
                          <EuiFlexItem grow={4}>Start Time</EuiFlexItem>
                          <EuiFlexItem grow={4}>End Time</EuiFlexItem>
                        </EuiFlexGroup>
                        <TimeSlot
                          control={control}
                          setValue={setValue}
                          name="timeForDineIn"
                          errors={errors}
                          watch={watch}
                        />
                      </>
                    )}
                    {watch("timer_for_dine_in") === 14 && (
                      <>
                        <EuiFlexGroup
                          style={{
                            background: "#9895CA",
                            marginTop: "20px",
                            marginBottom: "30px",
                          }}
                          columns={3}
                        >
                          <EuiFlexItem grow={2}>Days</EuiFlexItem>
                          <EuiFlexItem grow={4}>Start Time</EuiFlexItem>
                          <EuiFlexItem grow={4}>End Time</EuiFlexItem>
                        </EuiFlexGroup>

                        <EuiFlexGroup
                          style={{
                            marginBottom: 30,
                            paddingBottom: 40,
                            borderBottom: "1px solid black",
                          }}
                        >
                          <EuiFlexGroup
                            style={{
                              flexDirection: "column",
                              textAlign: "center",
                            }}
                          >
                            <EuiFlexItem
                              style={{
                                textAlign: "left",
                                marginLeft: "3%",
                              }}
                            >
                              <strong> Sunday </strong>
                            </EuiFlexItem>

                            <TimeSlot
                              control={control}
                              setValue={setValue}
                              name={`weakDineIn.sunday`}
                              errors={errors}
                              watch={watch}
                            />
                          </EuiFlexGroup>
                        </EuiFlexGroup>

                        <EuiFlexGroup
                          style={{
                            marginBottom: 30,
                            paddingBottom: 40,
                            borderBottom: "1px solid black",
                          }}
                        >
                          <EuiFlexGroup
                            style={{
                              flexDirection: "column",
                              textAlign: "center",
                            }}
                          >
                            <EuiFlexItem
                              style={{
                                textAlign: "left",
                                marginLeft: "3%",
                              }}
                            >
                              {" "}
                              <strong> Monday </strong>{" "}
                            </EuiFlexItem>
                            <TimeSlot
                              control={control}
                              setValue={setValue}
                              name={`weakDineIn.monday`}
                              errors={errors}
                              watch={watch}
                            />
                          </EuiFlexGroup>
                        </EuiFlexGroup>

                        <EuiFlexGroup
                          style={{
                            marginBottom: 30,
                            paddingBottom: 40,
                            borderBottom: "1px solid black",
                          }}
                        >
                          <EuiFlexGroup
                            style={{
                              flexDirection: "column",
                              textAlign: "center",
                            }}
                          >
                            <EuiFlexItem
                              style={{
                                textAlign: "left",
                                marginLeft: "3%",
                              }}
                            >
                              {" "}
                              <strong> Tuesday </strong>{" "}
                            </EuiFlexItem>
                            <TimeSlot
                              control={control}
                              setValue={setValue}
                              name={`weakDineIn.tuesday`}
                              errors={errors}
                              watch={watch}
                            />
                          </EuiFlexGroup>
                        </EuiFlexGroup>

                        <EuiFlexGroup
                          style={{
                            marginBottom: 30,
                            paddingBottom: 40,
                            borderBottom: "1px solid black",
                          }}
                        >
                          <EuiFlexGroup
                            style={{
                              flexDirection: "column",
                              textAlign: "center",
                            }}
                          >
                            <EuiFlexItem
                              style={{
                                textAlign: "left",
                                marginLeft: "3%",
                              }}
                            >
                              {" "}
                              <strong> Wednesday </strong>{" "}
                            </EuiFlexItem>
                            <TimeSlot
                              control={control}
                              setValue={setValue}
                              name={`weakDineIn.wednesday`}
                              errors={errors}
                              watch={watch}
                            />
                          </EuiFlexGroup>
                        </EuiFlexGroup>

                        <EuiFlexGroup
                          style={{
                            marginBottom: 30,
                            paddingBottom: 40,
                            borderBottom: "1px solid black",
                            justifyContent: "center",
                          }}
                        >
                          <EuiFlexGroup
                            style={{
                              flexDirection: "column",
                              textAlign: "center",
                            }}
                          >
                            <EuiFlexItem
                              style={{
                                textAlign: "left",
                                marginLeft: "3%",
                              }}
                            >
                              {" "}
                              <strong> Thursday </strong>{" "}
                            </EuiFlexItem>
                            <TimeSlot
                              control={control}
                              setValue={setValue}
                              name={`weakDineIn.thursday`}
                              errors={errors}
                              watch={watch}
                            />
                          </EuiFlexGroup>
                        </EuiFlexGroup>

                        <EuiFlexGroup
                          style={{
                            marginBottom: 30,
                            paddingBottom: 40,
                            borderBottom: "1px solid black",
                          }}
                        >
                          <EuiFlexGroup
                            style={{
                              flexDirection: "column",
                              textAlign: "center",
                            }}
                          >
                            <EuiFlexItem
                              style={{
                                textAlign: "left",
                                marginLeft: "3%",
                              }}
                            >
                              {" "}
                              <strong> Friday </strong>{" "}
                            </EuiFlexItem>
                            <TimeSlot
                              control={control}
                              setValue={setValue}
                              name={`weakDineIn.friday`}
                              errors={errors}
                              watch={watch}
                            />
                          </EuiFlexGroup>
                        </EuiFlexGroup>

                        <EuiFlexGroup
                          style={{
                            marginBottom: 30,
                            paddingBottom: 40,
                            borderBottom: "1px solid black",
                          }}
                        >
                          <EuiFlexGroup
                            style={{
                              flexDirection: "column",
                              textAlign: "center",
                            }}
                          >
                            <EuiFlexItem
                              style={{
                                textAlign: "left",
                                marginLeft: "3%",
                              }}
                            >
                              {" "}
                              <strong> Saturday </strong>{" "}
                            </EuiFlexItem>
                            <TimeSlot
                              control={control}
                              setValue={setValue}
                              name={`weakDineIn.saturday`}
                              errors={errors}
                              watch={watch}
                            />
                          </EuiFlexGroup>
                        </EuiFlexGroup>
                      </>
                    )}
                  </EuiFlexItem>
                </EuiFlexGroup>
              </>
            )}

            <EuiFlexGroup alignItems="center">
              <EuiFlexItem grow={false}>
                <SwitchField
                  name="takeaway"
                  defaultValue={false}
                  control={control}
                  setValue={setValue}
                />
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiText>Pick-up</EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
            {watch("takeaway") === true && (
              <>
                <EuiFlexGroup alignItems="center" style={{ marginTop: "10px" }}>
                  <EuiFlexItem grow={false}>
                    <EuiFormRow
                      isInvalid={errors.timer_for_banner}
                      fullWidth={true}
                    >
                      <Controller
                        render={({ onChange }) => (
                          <EuiRadioGroup
                            options={pickUpTimeSlot}
                            idSelected={
                              watch("timer_for_takeaway") === 15
                                ? 15
                                : watch("timer_for_takeaway") === 16
                                ? 16
                                : watch("timer_for_takeaway") === 17
                                ? 17
                                : 15
                            }
                            onChange={(id) =>
                              setValue(
                                "timer_for_takeaway",
                                id === 15
                                  ? 15
                                  : id === 16
                                  ? 16
                                  : id === 17
                                  ? 17
                                  : 15
                              )
                            }
                            legend={{
                              children: (
                                <span> Decide this pick-up lifetime </span>
                              ),
                            }}
                          />
                        )}
                        name="timer_for_takeaway"
                        defaultValue={15}
                        control={control}
                        fullWidth={true}
                      />
                    </EuiFormRow>
                  </EuiFlexItem>
                </EuiFlexGroup>
                <EuiFlexGroup style={{ width: "80%" }}>
                  <EuiFlexItem>
                    {watch("timer_for_takeaway") === 16 && (
                      <>
                        <EuiFlexGroup
                          style={{
                            background: "#9895CA",
                            marginTop: "20px",
                            marginBottom: "30px",
                          }}
                        >
                          <EuiFlexItem grow={2}>Days</EuiFlexItem>
                          <EuiFlexItem grow={4}>Start Time</EuiFlexItem>
                          <EuiFlexItem grow={4}>End Time</EuiFlexItem>
                        </EuiFlexGroup>
                        <TimeSlot
                          control={control}
                          setValue={setValue}
                          name="timeForTakeAway"
                          errors={errors}
                          watch={watch}
                        />
                      </>
                    )}
                    {watch("timer_for_takeaway") === 17 && (
                      <>
                        <EuiFlexGroup
                          style={{
                            background: "#9895CA",
                            marginTop: "20px",
                            marginBottom: "30px",
                          }}
                          columns={3}
                        >
                          <EuiFlexItem grow={2}>Days</EuiFlexItem>
                          <EuiFlexItem grow={4}>Start Time</EuiFlexItem>
                          <EuiFlexItem grow={4}>End Time</EuiFlexItem>
                        </EuiFlexGroup>

                        <EuiFlexGroup
                          style={{
                            marginBottom: 30,
                            paddingBottom: 40,
                            borderBottom: "1px solid black",
                          }}
                        >
                          <EuiFlexGroup
                            style={{
                              flexDirection: "column",
                              textAlign: "center",
                            }}
                          >
                            <EuiFlexItem
                              style={{
                                textAlign: "left",
                                marginLeft: "3%",
                              }}
                            >
                              <strong> Sunday </strong>
                            </EuiFlexItem>

                            <TimeSlot
                              control={control}
                              setValue={setValue}
                              name={`weakTakeAway.sunday`}
                              errors={errors}
                              watch={watch}
                            />
                          </EuiFlexGroup>
                        </EuiFlexGroup>

                        <EuiFlexGroup
                          style={{
                            marginBottom: 30,
                            paddingBottom: 40,
                            borderBottom: "1px solid black",
                          }}
                        >
                          <EuiFlexGroup
                            style={{
                              flexDirection: "column",
                              textAlign: "center",
                            }}
                          >
                            <EuiFlexItem
                              style={{
                                textAlign: "left",
                                marginLeft: "3%",
                              }}
                            >
                              {" "}
                              <strong> Monday </strong>{" "}
                            </EuiFlexItem>
                            <TimeSlot
                              control={control}
                              setValue={setValue}
                              name={`weakTakeAway.monday`}
                              errors={errors}
                              watch={watch}
                            />
                          </EuiFlexGroup>
                        </EuiFlexGroup>

                        <EuiFlexGroup
                          style={{
                            marginBottom: 30,
                            paddingBottom: 40,
                            borderBottom: "1px solid black",
                          }}
                        >
                          <EuiFlexGroup
                            style={{
                              flexDirection: "column",
                              textAlign: "center",
                            }}
                          >
                            <EuiFlexItem
                              style={{
                                textAlign: "left",
                                marginLeft: "3%",
                              }}
                            >
                              {" "}
                              <strong> Tuesday </strong>{" "}
                            </EuiFlexItem>
                            <TimeSlot
                              control={control}
                              setValue={setValue}
                              name={`weakTakeAway.tuesday`}
                              errors={errors}
                              watch={watch}
                            />
                          </EuiFlexGroup>
                        </EuiFlexGroup>

                        <EuiFlexGroup
                          style={{
                            marginBottom: 30,
                            paddingBottom: 40,
                            borderBottom: "1px solid black",
                          }}
                        >
                          <EuiFlexGroup
                            style={{
                              flexDirection: "column",
                              textAlign: "center",
                            }}
                          >
                            <EuiFlexItem
                              style={{
                                textAlign: "left",
                                marginLeft: "3%",
                              }}
                            >
                              {" "}
                              <strong> Wednesday </strong>{" "}
                            </EuiFlexItem>
                            <TimeSlot
                              control={control}
                              setValue={setValue}
                              name={`weakTakeAway.wednesday`}
                              errors={errors}
                              watch={watch}
                            />
                          </EuiFlexGroup>
                        </EuiFlexGroup>

                        <EuiFlexGroup
                          style={{
                            marginBottom: 30,
                            paddingBottom: 40,
                            borderBottom: "1px solid black",
                            justifyContent: "center",
                          }}
                        >
                          <EuiFlexGroup
                            style={{
                              flexDirection: "column",
                              textAlign: "center",
                            }}
                          >
                            <EuiFlexItem
                              style={{
                                textAlign: "left",
                                marginLeft: "3%",
                              }}
                            >
                              {" "}
                              <strong> Thursday </strong>{" "}
                            </EuiFlexItem>
                            <TimeSlot
                              control={control}
                              setValue={setValue}
                              name={`weakTakeAway.thursday`}
                              errors={errors}
                              watch={watch}
                            />
                          </EuiFlexGroup>
                        </EuiFlexGroup>

                        <EuiFlexGroup
                          style={{
                            marginBottom: 30,
                            paddingBottom: 40,
                            borderBottom: "1px solid black",
                          }}
                        >
                          <EuiFlexGroup
                            style={{
                              flexDirection: "column",
                              textAlign: "center",
                            }}
                          >
                            <EuiFlexItem
                              style={{
                                textAlign: "left",
                                marginLeft: "3%",
                              }}
                            >
                              {" "}
                              <strong> Friday </strong>{" "}
                            </EuiFlexItem>
                            <TimeSlot
                              control={control}
                              setValue={setValue}
                              name={`weakTakeAway.friday`}
                              errors={errors}
                              watch={watch}
                            />
                          </EuiFlexGroup>
                        </EuiFlexGroup>

                        <EuiFlexGroup
                          style={{
                            marginBottom: 30,
                            paddingBottom: 40,
                            borderBottom: "1px solid black",
                          }}
                        >
                          <EuiFlexGroup
                            style={{
                              flexDirection: "column",
                              textAlign: "center",
                            }}
                          >
                            <EuiFlexItem
                              style={{
                                textAlign: "left",
                                marginLeft: "3%",
                              }}
                            >
                              {" "}
                              <strong> Saturday </strong>{" "}
                            </EuiFlexItem>
                            <TimeSlot
                              control={control}
                              setValue={setValue}
                              name={`weakTakeAway.saturday`}
                              errors={errors}
                              watch={watch}
                            />
                          </EuiFlexGroup>
                        </EuiFlexGroup>
                      </>
                    )}
                  </EuiFlexItem>
                </EuiFlexGroup>
              </>
            )}
          </>
        </EuiFormRow>

        <EuiFlexGroup alignItems="center" style={{ marginTop: "10px" }}>
          <EuiFlexItem grow={false}>
            <EuiFormRow isInvalid={errors.time_slot} fullWidth={true}>
              <Controller
                render={({ onChange }) => (
                  <EuiRadioGroup
                    options={timeSlot}
                    idSelected={
                      watch("time_slot") === "outlet_differant_day"
                        ? "outlet_differant_day"
                        : "outlet_same_day"
                    }
                    onChange={(id) =>
                      setValue(
                        "time_slot",
                        id === "outlet_differant_day"
                          ? "outlet_differant_day"
                          : "outlet_same_day"
                      )
                    }
                    legend={{
                      children: <span> Decide Outlet Timing </span>,
                    }}
                  />
                )}
                name="time_slot"
                defaultValue={
                  watch("time_slot") === "outlet_differant_day"
                    ? "outlet_differant_day"
                    : "outlet_same_day"
                }
                control={control}
                fullWidth={true}
              />
            </EuiFormRow>
          </EuiFlexItem>
          {console.log("time slot", watch("time_slot"))}
        </EuiFlexGroup>
        <EuiFlexGroup style={{ width: "80%" }}>
          <EuiFlexItem>
            {(watch("time_slot") === "outlet_same_day" ||
              watch("time_slot") === undefined) && (
              <>
                <EuiFlexGroup
                  style={{
                    background: "#9895CA",
                    marginTop: "20px",
                    marginBottom: "30px",
                  }}
                >
                  <EuiFlexItem grow={2}>Days</EuiFlexItem>
                  <EuiFlexItem grow={4}>Start Time</EuiFlexItem>
                  <EuiFlexItem grow={4}>End Time</EuiFlexItem>
                </EuiFlexGroup>

                <TimeSlot
                  control={control}
                  setValue={setValue}
                  name="time"
                  watch={watch}
                />
              </>
            )}
            {watch("time_slot") === "outlet_differant_day" && (
              <>
                <EuiFlexGroup
                  style={{
                    background: "#9895CA",
                    marginTop: "20px",
                    marginBottom: "30px",
                  }}
                >
                  <EuiFlexItem grow={2}>Days</EuiFlexItem>
                  <EuiFlexItem grow={4}>Start Time</EuiFlexItem>
                  <EuiFlexItem grow={4}>End Time</EuiFlexItem>
                </EuiFlexGroup>

                <EuiFlexGroup
                  style={{
                    marginBottom: 30,
                    paddingBottom: 40,
                    borderBottom: "1px solid black",
                  }}
                >
                  <EuiFlexGroup
                    style={{
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <EuiFlexItem
                      style={{
                        textAlign: "left",
                        marginLeft: "3%",
                      }}
                    >
                      <strong> Sunday </strong>{" "}
                    </EuiFlexItem>
                    <TimeSlot
                      control={control}
                      setValue={setValue}
                      name="weak.sunday"
                      watch={watch}
                    />
                  </EuiFlexGroup>
                </EuiFlexGroup>

                <EuiFlexGroup
                  style={{
                    marginBottom: 30,
                    paddingBottom: 40,
                    borderBottom: "1px solid black",
                  }}
                >
                  <EuiFlexGroup
                    style={{
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <EuiFlexItem
                      style={{
                        textAlign: "left",
                        marginLeft: "3%",
                      }}
                    >
                      <strong> Monday </strong>
                    </EuiFlexItem>
                    <TimeSlot
                      control={control}
                      setValue={setValue}
                      name="weak.monday"
                      watch={watch}
                    />
                  </EuiFlexGroup>
                </EuiFlexGroup>

                <EuiFlexGroup
                  style={{
                    marginBottom: 30,
                    paddingBottom: 40,
                    borderBottom: "1px solid black",
                  }}
                >
                  <EuiFlexGroup
                    style={{
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <EuiFlexItem
                      style={{
                        textAlign: "left",
                        marginLeft: "3%",
                      }}
                    >
                      {" "}
                      <strong> Tuesday </strong>{" "}
                    </EuiFlexItem>

                    <TimeSlot
                      control={control}
                      setValue={setValue}
                      name="weak.tuesday"
                      watch={watch}
                    />
                  </EuiFlexGroup>
                </EuiFlexGroup>

                <EuiFlexGroup
                  style={{
                    marginBottom: 30,
                    paddingBottom: 40,
                    borderBottom: "1px solid black",
                  }}
                >
                  <EuiFlexGroup
                    style={{
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <EuiFlexItem
                      style={{
                        textAlign: "left",
                        marginLeft: "3%",
                      }}
                    >
                      {" "}
                      <strong> Wednesday </strong>{" "}
                    </EuiFlexItem>

                    <TimeSlot
                      control={control}
                      setValue={setValue}
                      name="weak.wednesday"
                      watch={watch}
                    />
                  </EuiFlexGroup>
                </EuiFlexGroup>

                <EuiFlexGroup
                  style={{
                    marginBottom: 30,
                    paddingBottom: 40,
                    borderBottom: "1px solid black",
                    justifyContent: "center",
                  }}
                >
                  <EuiFlexGroup
                    style={{
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <EuiFlexItem
                      style={{
                        textAlign: "left",
                        marginLeft: "3%",
                      }}
                    >
                      <strong> Thursday </strong>
                    </EuiFlexItem>
                    <TimeSlot
                      control={control}
                      setValue={setValue}
                      name="weak.thursday"
                      watch={watch}
                    />
                  </EuiFlexGroup>
                </EuiFlexGroup>

                <EuiFlexGroup
                  style={{
                    marginBottom: 30,
                    paddingBottom: 40,
                    borderBottom: "1px solid black",
                  }}
                >
                  <EuiFlexGroup
                    style={{
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <EuiFlexItem
                      style={{
                        textAlign: "left",
                        marginLeft: "3%",
                      }}
                    >
                      <strong> Friday </strong>
                    </EuiFlexItem>
                    <TimeSlot
                      control={control}
                      setValue={setValue}
                      name="weak.friday"
                      watch={watch}
                    />
                  </EuiFlexGroup>
                </EuiFlexGroup>

                <EuiFlexGroup
                  style={{
                    marginBottom: 30,
                    paddingBottom: 40,
                    borderBottom: "1px solid black",
                  }}
                >
                  <EuiFlexGroup
                    style={{
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <EuiFlexItem
                      style={{
                        textAlign: "left",
                        marginLeft: "3%",
                      }}
                    >
                      <strong> Saturday </strong>
                    </EuiFlexItem>

                    <TimeSlot
                      control={control}
                      setValue={setValue}
                      name="weak.saturday"
                      watch={watch}
                    />
                  </EuiFlexGroup>
                </EuiFlexGroup>
              </>
            )}
          </EuiFlexItem>
        </EuiFlexGroup>
        {timeSlotError && (
          <EuiText style={{ color: "red" }}> Please select time slot </EuiText>
        )}

        <EuiFlexGroup style={{ marginTop: 10 }}>
          <EuiFlexItem grow={false}>
            {/* onClick={storeDatatoLocalStorage} */}
            <EuiButton fill>Save local draft</EuiButton>
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <EuiButton isLoading={loader} type="submit" fill>
              Save into database
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiForm>
    </>
  );
};

export default CreateOutlet;
