/* global localStorage */
import React, { useEffect, useState } from "react";
import "./loginCardStyle.scss";
import {
  EuiButton,
  EuiCallOut,
  EuiFieldPassword,
  EuiFieldText,
  EuiForm,
  EuiFormRow,
  EuiImage,
  EuiLink,
  EuiPageContent,
  EuiPageContentBody,
  EuiSpacer,
  EuiText,
} from "@elastic/eui";
import DarkLogo from "../../../assets/img/FabDeliveryNew.svg";
import WhiteLogo from "../../../assets/img/FabDeliveryNewWhite.svg";
import { loginAPI } from "../../../api/authAPI";
import { useDispatch, useSelector } from "react-redux";

export const LoginCard = () => {
  /* const [keepLogin, setKeepLogin] = useState(false) */
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [isUserError, setIsUserError] = useState(false);
  const [isPasswordError, setIsPasswordError] = useState(false);
  const [validCredentials, setValidCredentials] = useState(true);
  const [loginLoading, setLoginLoading] = useState(false);
  const currentTheme = useSelector((state) => state.theme.theme);
  const dispatch = useDispatch();

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        console.log("Enter key was pressed. Run your function.");
      }
    };
    document.addEventListener("keydown", listener);

    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      LoginSubmit();
    }
  };

  const LoginSubmit = () => {
    if (userName.length !== 0 && password.length !== 0) {
      setLoginLoading(true);
      setIsUserError(false);
      setIsPasswordError(false);
      const device_id = localStorage.getItem("deviceId");
      loginAPI(userName, password, device_id, "web_app").then((res) => {
        // console.log(res.apiError)
        if (!res.apiError) {
          setValidCredentials(true);
          setLoginLoading(false);
          localStorage.setItem("auth", JSON.stringify(res.body));
          dispatch({
            type: "set-auth",
            payload: {
              isLoggedIn: true,
              ...res.body,
            },
          });
        } else {
          // console.log(res)
          setValidCredentials(false);
          setLoginLoading(false);
        }
      });
    } else {
      if (userName.length === 0) {
        setIsUserError(true);
      } else {
        setIsUserError(false);
      }
      if (password.length === 0) {
        setIsPasswordError(true);
      } else {
        setIsPasswordError(false);
      }
    }
  };
  return (
    <EuiPageContent
      className="login-page-container"
      verticalPosition="center"
      horizontalPosition="center"
    >
      <EuiPageContentBody>
        <div className="login-logo-page">
          <EuiImage
            size="m"
            alt="img"
            url={currentTheme === "light" ? DarkLogo : WhiteLogo}
          />
        </div>
        <EuiSpacer />
        {!validCredentials && (
          <>
            <EuiCallOut
              title="Invalid username or password"
              color="danger"
              iconType="alert"
            />
            <EuiSpacer />
          </>
        )}
        <EuiForm isInvalid={false} error={["enter valid email"]}>
          <EuiFormRow
            label="Email"
            isInvalid={isUserError}
            error={["Username is required"]}
          >
            <EuiFieldText
              name="first"
              icon="user"
              placeholder="Username"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              isInvalid={isUserError}
            />
          </EuiFormRow>
          <EuiFormRow
            label="Password"
            isInvalid={isPasswordError}
            error={["Password is required"]}
          >
            <EuiFieldPassword
              name="first"
              icon="lock"
              placeholder="Password"
              value={password}
              isInvalid={isPasswordError}
              onKeyDown={onKeyDown}
              onChange={(e) => setPassword(e.target.value)}
            />
          </EuiFormRow>

          <EuiFormRow>
            <EuiButton
              isLoading={loginLoading}
              fullWidth
              fill
              onClick={() => LoginSubmit()}
            >
              Login
            </EuiButton>
          </EuiFormRow>
          <EuiFormRow>
            <div style={{ textAlign: "center" }}>
              <EuiLink>
                <EuiText>Forgot Password</EuiText>
              </EuiLink>
            </div>
          </EuiFormRow>
        </EuiForm>
      </EuiPageContentBody>
    </EuiPageContent>
  );
};
