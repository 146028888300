import React, { useState, useEffect } from "react";
import NumberField from "../../Form/NumberField";
import {
  EuiToolTip,
  EuiText,
  EuiForm,
  EuiButton,
  EuiFormRow,
  EuiFlexGroup,
  EuiFlexItem,
} from "@elastic/eui";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { baseApiUrl } from "../../../envConfig";
import { store } from "../../../reduxStore/store";

const LocationServicability = ({ getOutletData }) => {
  const { register, handleSubmit, errors, control, watch, reset, setValue } =
    useForm();
  const restaurantID = useSelector((state) => state.restaurant.restaurantId);
  var token = store.getState().auth.token;
  const [loader, setLoader] = useState(false);
  let defaultValue = {};
  useEffect(() => {
    if (getOutletData) {
      const outletData = getOutletData;
      defaultValue.latitude = outletData.outlet_address.latitude;
      defaultValue.longitude = outletData.outlet_address.longitude;
      defaultValue.service_radius = outletData.outlet_address.service_radius;
      reset({
        ...defaultValue,
      });
    }
  }, [getOutletData]);

  const onSubmit = async (data) => {
    data.id = null;
    if (getOutletData) {
      data.id = getOutletData.outlet_id;
    }
    data.address = {
      service_radius: data.service_radius,
      longitude: data.longitude,
      latitude: data.latitude,
    };
    delete data.service_radius;
    delete data.longitude;
    delete data.latitude;
    console.log("location and service", JSON.stringify(data));
    setLoader(true);
    // api call
    const locationServiceInfo = await fetch(
      `${baseApiUrl}restaurants/${restaurantID}/outlets/${data.id}?section=location_and_serviceability`,
      {
        method: "put",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    const locationServiceInfoJson = await locationServiceInfo.json();

    if (locationServiceInfoJson.success) {
      alert("successfully edited");
    } else {
      alert("Please try later");
    }

    setLoader(false);
  };
  return (
    <EuiForm
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      style={{ marginTop: 20 }}
    >
      <NumberField
        label="Latitude"
        errors={errors}
        name="latitude"
        placeholder="Please Enter latitude"
        rules={{
          required: "Please enter latitude",
        }}
        step="any"
        control={control}
      />
      <NumberField
        label="Longitude"
        errors={errors}
        name="longitude"
        placeholder="Please Enter longitude"
        rules={{
          required: "Please enter longitude",
        }}
        step="any"
        control={control}
      />
      <NumberField
        label=" Service availability radius"
        errors={errors}
        name="service_radius"
        placeholder="Please Enter radius"
        rules={{
          required: "Please enter radius",
        }}
        prepend={
          <EuiToolTip content="content">
            <EuiText size="s">In km</EuiText>
          </EuiToolTip>
        }
        step="any"
        control={control}
      />

      <EuiButton isLoading={loader} type="submit" fill>
        Update Location Servicability
      </EuiButton>
    </EuiForm>
  );
};

export default LocationServicability;
