import React from "react";
import { useQuery } from "react-query";
import {
  EuiLoadingContent,
  EuiFlexGroup,
  EuiFormRow,
  EuiText,
  EuiFlexItem,
  EuiSwitch,
} from "@elastic/eui";
import { queryCache } from "react-query";

import { getOutletPaymentProviderByIdAPI } from "../../../api/outletAPICall";
import { useSelector } from "react-redux";
import { baseApiUrl } from "../../../envConfig";
import { store } from "../../../reduxStore/store";

const OutletPaymentSettings = ({ outletId }) => {
  const {
    isLoading,
    error,
    data: getOutletPaymentProviderById,
  } = useQuery(
    [`getOutletPaymentProviderById`, outletId],
    getOutletPaymentProviderByIdAPI
  );

  const restaurantID = useSelector((state) => state.restaurant.restaurantId);
  var token = store.getState().auth.token;
  if (isLoading === true && getOutletPaymentProviderById === undefined)
    return <EuiLoadingContent lines={10} />;
  if (error) return <h1> Error...</h1>;

  const handleChange = async (e, id) => {
    try {
      const paymentSettings = await fetch(
        `${baseApiUrl}restaurants/${restaurantID}/outlets/${outletId}/payment-methods/${id}/is-active`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
          body: JSON.stringify({ active: e.target.checked }),
        }
      );
      const paymentSettingsJson = await paymentSettings.json();

      if (paymentSettingsJson.success) {
        alert("successfully edited");
      } else {
        alert("Please try later");
      }
    } catch (e) {
      alert("Please try later");
    }
    queryCache.invalidateQueries(`getOutletPaymentProviderById`);
  };
  const getPaymentProvider = (payment_provider) => {
    let payment_data = [];
    for (const [key1, value1] of Object.entries(payment_provider)) {
      if (key1 === "cash-on-delivery") {
        for (const [key2, value2] of Object.entries(payment_provider[key1])) {
          console.log("key", key2);
          if (key2 === "CASH_ON_DELIVERY") {
            payment_data.push(
              <EuiFormRow label="Case on delivery">
                <>
                  {payment_provider[key1]["CASH_ON_DELIVERY"].map((order) => (
                    <EuiFlexGroup
                      alignItems="center"
                      style={{ marginLeft: "30px" }}
                    >
                      <EuiFlexItem grow={false}>
                        <EuiSwitch
                          label={order.ordering_mode}
                          checked={order.active ? true : false}
                          onChange={(e) => handleChange(e, order.id)}
                        />
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  ))}
                </>
              </EuiFormRow>
            );
          }
        }
      }
    }
    return payment_data;
  };
  return (
    <div>
      <>
        {getPaymentProvider(
          getOutletPaymentProviderById.outlet_payment_methods[0]
        )}
      </>
    </div>
  );
};
export default OutletPaymentSettings;
