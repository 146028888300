import React, { useState, useEffect, Fragment } from "react";
import { useForm } from "react-hook-form";
import {
  EuiFlexGroup,
  EuiText,
  EuiFlexItem,
  EuiFormRow,
  EuiForm,
  EuiButton,
  EuiToolTip,
} from "@elastic/eui";
import NumberField from "../../Form/NumberField";
import CheckBoxField from "../../Form/CheckBoxField";
import { useSelector } from "react-redux";
import { baseApiUrl } from "../../../envConfig";
import { store } from "../../../reduxStore/store";

const Charges = ({ getOutletData }) => {
  const { register, handleSubmit, errors, control, watch, reset, setValue } =
    useForm();
  let defaultValue = {};
  const [loader, setLoader] = useState(false);
  const restaurantID = useSelector((state) => state.restaurant.restaurantId);
  var token = store.getState().auth.token;
  useEffect(() => {
    if (getOutletData) {
      const outletData = getOutletData;
      defaultValue.delivery_charge_threshold =
        outletData.settings.delivery_charge_threshold;
      defaultValue.delivery_charge_above_threshold =
        outletData.settings.delivery_charge_above_threshold;
      defaultValue.delivery_charge_below_threshold =
        outletData.settings.delivery_charge_below_threshold;
      defaultValue.packaging_charge = outletData.settings.packaging_charge;
      defaultValue.convinience_charge = outletData.settings.convenience_charge;
      defaultValue.extra_delivery_charge =
        outletData.settings.extra_delivery_charge;
      defaultValue.normal_delivery_radius =
        outletData.outlet_address.normal_delivery_radius;
      defaultValue.prize_per_kilometer = defaultValue.normal_delivery_radius
        ? true
        : false;
      reset({
        ...defaultValue,
      });
    }
  }, [getOutletData]);

  const onSubmit = async (data) => {
    data.id = null;
    if (getOutletData) {
      data.id = getOutletData.outlet_id;
    }
    data.address = {
      normal_delivery_radius: data.prize_per_kilometer
        ? data.normal_delivery_radius
        : null,
    };
    data.settings = {
      delivery_charge_threshold: data.delivery_charge_threshold,
      delivery_charge_above_threshold: data.delivery_charge_above_threshold,
      delivery_charge_below_threshold: data.delivery_charge_below_threshold,
      extra_delivery_charge: data.prize_per_kilometer
        ? data.extra_delivery_charge
        : null,
      packaging_charge: data.packaging_charge,
      convenience_charge: data.convinience_charge,
    };
    delete data.delivery_charge_threshold;
    delete data.delivery_charge_above_threshold;
    delete data.delivery_charge_below_threshold;
    delete data.extra_delivery_charge;
    delete data.packaging_charge;
    delete data.convenience_charge;

    // api call for charges
    setLoader(true);
    // api call
    const charges = await fetch(
      `${baseApiUrl}restaurants/${restaurantID}/outlets/${data.id}?section=delivery_charge_and_other_charges`,
      {
        method: "put",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    const chargesJson = await charges.json();

    if (chargesJson.success) {
      alert("successfully edited");
    } else {
      alert("Please try later");
    }
    setLoader(false);
  };
  return (
    <EuiForm
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      style={{ marginTop: 20 }}
    >
      <NumberField
        label="Delivery charge thresold"
        errors={errors}
        name="delivery_charge_threshold"
        placeholder="Please enter delivery charge thresold"
        rules={{
          required: "Please enter delivery charge thresold",
        }}
        step="any"
        control={control}
      />
      <NumberField
        label="Above thresold"
        errors={errors}
        name="delivery_charge_above_threshold"
        placeholder="Please enter delivery above thresold"
        rules={{
          required: "Please enter delivery above thresold",
        }}
        step="any"
        control={control}
      />
      <NumberField
        label="Below thresold"
        errors={errors}
        name="delivery_charge_below_threshold"
        placeholder="Please enter delivery below thresold"
        rules={{
          required: "Please enter delivery below thresold",
        }}
        step="any"
        control={control}
      />
      <EuiFormRow>
        <EuiFlexGroup alignItems="center">
          <EuiFlexItem grow={false}>
            <CheckBoxField
              name="prize_per_kilometer"
              defaultValue={watch("normal_delivery_radius") ? true : false}
              control={control}
              setValue={setValue}
            />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiText>Charge additional price per kilometer ?</EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFormRow>

      {watch("prize_per_kilometer") && (
        <Fragment style={{ marginLeft: 20 }}>
          <NumberField
            label="Normal delivery radius"
            errors={errors}
            name="normal_delivery_radius"
            placeholder="Please enter normal delivery radius"
            rules={{
              required: "Please enter normal delivery radius",
              validate: (value) => value > 0 || "Please enter positive value",
            }}
            prepend={
              <EuiToolTip content="content">
                <EuiText size="s">In km</EuiText>
              </EuiToolTip>
            }
            step="any"
            min={0}
            control={control}
          />
          <NumberField
            label="Extra Charge"
            errors={errors}
            name="extra_delivery_charge"
            placeholder="Please enter extra charge"
            rules={{
              required: "Please enter normal delivery radius",
              validate: (value) => value > 0 || "Please enter positive value",
            }}
            step="any"
            min={0}
            control={control}
          />
        </Fragment>
      )}
      <NumberField
        label="Packaging charge per order"
        errors={errors}
        name="packaging_charge"
        placeholder="Please enter packaging charge"
        rules={{
          required: "Please enter packaging charge",
        }}
        step="any"
        control={control}
      />
      <NumberField
        label="Convinience Charge"
        errors={errors}
        name="convinience_charge"
        placeholder="Please enter delivery charge thresold"
        rules={{
          required: "Please enter delivery charge thresold",
        }}
        step="any"
        control={control}
      />

      <EuiButton isLoading={loader} type="submit" fill>
        Update charges
      </EuiButton>
    </EuiForm>
  );
};

export default Charges;
