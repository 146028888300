import { baseApiUrl } from "../envConfig";
import { store } from "../reduxStore/store";
import API from "../api/axios/API";

export const getOutletList = async () => {
  const userJson = await API.get(`restaurants/:restaurantID/outlets`);

  console.log(userJson);
  if (userJson.success === true) {
    return userJson;
  } else {
    return userJson;
  }
};

export const getPermissionAboutUserByOutlet = async (key, id) => {
  const userOutletPermissionJson = await API.get(
    `restaurants/:restaurantID/users/${id}/outlet-permission`
  );

  return userOutletPermissionJson;
};

export const getOutletPaymentProviderByIdAPI = async (key, id) => {
  const userOutletPermissionJson = await API.get(
    `restaurants/:restaurantID/outlets/${id}/payment-methods`
  );

  return userOutletPermissionJson;
};
