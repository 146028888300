import API from "../api/axios/API";

export const getAllPaymentMethods = async () => {
  const patment_methodJson = await API.get(`payment-methods/`);

  if (patment_methodJson.success === true) {
    return patment_methodJson;
  } else {
    return patment_methodJson;
  }
};
