import React, { useState, Fragment, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  EuiForm,
  EuiFormRow,
  EuiButton,
  EuiText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiTextColor,
  EuiSwitch,
  EuiButtonIcon,
  EuiDatePicker,
  EuiRadioGroup,
  EuiCopy,
} from "@elastic/eui";
import { useSelector, useDispatch } from "react-redux";
import { useMutation } from "react-query";
import uuid from "uuid";
import { addRestaurant, editRestaurant } from "../../../api/restaurantsAPICall";
import { useHistory } from "react-router-dom";
import TextField from "../../Form/TextField";
import ColorField from "../../Form/ColorField";
import ComboBoxField from "../../Form/ComboBoxField";
import TextAreaField from "../../Form/TextAreaField";
import { ImageDropCrop } from "../../Form/ImageDropCrop";
import { availabledLanguage } from "./availableLanguages";
import { isEmpty } from "lodash";
import moment from "moment";

const CreateRestaurant = () => {
  const editRestaurantId = useSelector(
    (state) => state.restaurant.editRestaurantId
  );
  // const [render,setRender] = useState(false)
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [addMutate, addInfo] = useMutation(addRestaurant);
  const [editMutate, editInfo] = useMutation(editRestaurant);
  const [submitError, setSubmitError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showError, setShowError] = useState(false);
  const [joinDate, setJoinDate] = useState(moment());
  const allRestarurants = useSelector((state) => state.restaurant.restaurants);
  let defaultValue = {};
  let totalImage = [1, 2, 3];

  const contractRenewalTime = [
    {
      id: "monthly",
      label: "Monthly",
    },
    {
      id: "quarterly",
      label: "Quarterly",
    },
    {
      id: "half_yearly",
      label: "Half Yearly",
    },
    {
      id: "yearly",
      label: "Yearly",
    },
  ];

  const [selectedContractRenewalTime, setSelectedContractRenewalTime] =
    useState(contractRenewalTime[0].id);

  useEffect(() => {
    return () => {
      dispatch({
        type: "set-edit-restaurants",
        payload: {
          editRestaurantId: null,
        },
      });
    };
  }, [editRestaurantId]);

  useEffect(() => {
    if (!editRestaurantId) {
      setValue("apply_mask", true);
    }
  }, []);

  useEffect(() => {
    if (editRestaurantId) {
      const editFormData = allRestarurants.filter(
        (item) => item.id === editRestaurantId
      )[0];
      console.log(editFormData);
      defaultValue.name = editFormData.name;
      defaultValue.brand_color_primary = editFormData.brand_color_primary;
      defaultValue.brand_color_secondary = editFormData.brand_color_secondary;
      defaultValue.share_content = editFormData.settings?.share_content;

      defaultValue.logo_header_image =
        editFormData.settings && editFormData.settings.logo_intro_image_url
          ? {
              ...editFormData.settings.logo_intro_image_url,
              src:
                editFormData.settings.logo_intro_image_url.image_url &&
                editFormData.settings.logo_intro_image_url.image_url,
            }
          : null;

      defaultValue.logo_footer_image =
        editFormData.settings && editFormData.settings.logo_footer_image_url
          ? {
              ...editFormData.settings.logo_footer_image_url,
              src:
                editFormData.settings.logo_footer_image_url.image_url &&
                editFormData.settings.logo_footer_image_url.image_url,
            }
          : null;

      defaultValue.logo_brand_image =
        editFormData.settings && editFormData.settings.logo_brand_image_url
          ? {
              ...editFormData.settings.logo_brand_image_url,
              src:
                editFormData.settings.logo_brand_image_url.image_url &&
                editFormData.settings.logo_brand_image_url.image_url,
            }
          : null;

      defaultValue.location_selector_color =
        editFormData.location_selector_color;
      defaultValue.location_deliverable_color =
        editFormData.location_deliverable_color;
      defaultValue.location_undeliverable_color =
        editFormData.location_undeliverable_color;
      defaultValue.pickup_selector_color = editFormData.pickup_selector_color;
      defaultValue.pickup_tab_color = editFormData.pickup_tab_color;
      defaultValue.category_font_color = editFormData.category_font_color;
      defaultValue.option_availability_text_color =
        editFormData.option_availability_text_color;

      defaultValue.google_api_key =
        editFormData.settings && editFormData.settings.google_maps_api_key;
      defaultValue.two_factor_api_key =
        editFormData.settings && editFormData.settings.two_factor_api_key;
      defaultValue.google_api_key =
        editFormData.settings && editFormData.settings.google_maps_api_key;
      defaultValue.app_name =
        editFormData.settings && editFormData.settings.app_name;
      defaultValue.apply_mask =
        editFormData.settings && editFormData.settings.apply_mask;

      defaultValue.logo_height =
        editFormData.settings && editFormData.settings.logo_height;

      defaultValue.firebase_server_key =
        editFormData.notificationSettings && editFormData.notificationSettings
          ? editFormData.notificationSettings.fcm_server_key
          : null;
      defaultValue.firebase_api_key =
        editFormData.notificationSettings && editFormData.notificationSettings
          ? editFormData.notificationSettings.fcm_api_key
          : null;
      defaultValue.firebase_auth_domain =
        editFormData.notificationSettings && editFormData.notificationSettings
          ? editFormData.notificationSettings.fcm_auth_domain
          : null;
      defaultValue.firebase_database_url =
        editFormData.notificationSettings && editFormData.notificationSettings
          ? editFormData.notificationSettings.fcm_database_url
          : null;

      defaultValue.firebase_project_id =
        editFormData.notificationSettings && editFormData.notificationSettings
          ? editFormData.notificationSettings.fcm_project_id
          : null;
      defaultValue.firebase_storage_bucket =
        editFormData.notificationSettings && editFormData.notificationSettings
          ? editFormData.notificationSettings.fcm_storage_bucket
          : null;

      defaultValue.firebase_messaging_sender_id =
        editFormData.notificationSettings && editFormData.notificationSettings
          ? editFormData.notificationSettings.fcm_messaging_sender_id
          : null;
      defaultValue.firebase_measurement_id =
        editFormData.notificationSettings && editFormData.notificationSettings
          ? editFormData.notificationSettings.fcm_measurement_id
          : null;

      defaultValue.firebase_app_id =
        editFormData.notificationSettings && editFormData.notificationSettings
          ? editFormData.notificationSettings.fcm_app_id
          : null;
      defaultValue.firebase_project_vapi_id =
        editFormData.notificationSettings && editFormData.notificationSettings
          ? editFormData.notificationSettings.fcm_public_vapi_key
          : null;

      defaultValue.firebase_credentials =
        editFormData.notificationSettings && editFormData.notificationSettings
          ? editFormData.notificationSettings.firebase_credentials
          : null;

      defaultValue.languages = editFormData.languages.map((item, index) => ({
        label: item.language_label,
        language: item.language,
        language_label: item.language_label,
      }));

      //about_us, contact_us , privacy_policy , terms_condition , return_policy
      let translationObject = {
        about_us: {},
        contact_us: {},
        privacy_and_policy: {},
        terms_and_conditions: {},
        return_policy: {},
      };

      for (const language_label in editFormData.translations.about_us) {
        translationObject.about_us[language_label] =
          editFormData.translations.about_us[language_label];
      }
      for (const language_label in editFormData.translations.contact_us) {
        translationObject.contact_us[language_label] =
          editFormData.translations.contact_us[language_label];
      }
      for (const language_label in editFormData.translations
        .privacy_and_policy) {
        translationObject.privacy_and_policy[language_label] =
          editFormData.translations.privacy_and_policy[language_label];
      }
      for (const language_label in editFormData.translations
        .terms_and_conditions) {
        translationObject.terms_and_conditions[language_label] =
          editFormData.translations.terms_and_conditions[language_label];
      }
      for (const language_label in editFormData.translations.return_policy) {
        translationObject.return_policy[language_label] =
          editFormData.translations.return_policy[language_label];
      }
      // setImage and label
      let intro_screen = {};
      for (const key in editFormData.intro_screens) {
        intro_screen[parseInt(key) + 1] = editFormData.intro_screens[key];
        if (intro_screen[parseInt(key) + 1].image) {
          intro_screen[parseInt(key) + 1].image = {
            ...editFormData.intro_screens[key].image,
            src:
              editFormData.intro_screens[key].image &&
              editFormData.intro_screens[key].image.image_url
                ? editFormData.intro_screens[key].image.image_url
                : null,
          };
        }
      }
      defaultValue.intro_screens = intro_screen;
      defaultValue.translations = translationObject;

      if (
        editFormData.plan_information &&
        !isEmpty(editFormData.plan_information)
      ) {
        console.log("inside plan information ", editFormData.plan_information);
        defaultValue.join_date = moment(
          editFormData.plan_information.join_date
        );
        defaultValue.plan = editFormData.plan_information.plan;
      }

      console.log("defaultValue", defaultValue);
      reset({
        ...defaultValue,
      });
    }
  }, [editRestaurantId]);
  const { handleSubmit, errors, control, watch, reset, setValue, getValues } =
    useForm({
      defaultValues: {
        ...defaultValue,
      },
    });

  const saveIntoLocalStorage = () => {
    const data = getValues();
    data.settings = {
      two_factor_api_key: data.two_factor_api_key,
      google_maps_api_key: data.google_api_key,
      app_name: data.app_name,
      apply_mask: data.apply_mask
        ? data.apply_mask
        : defaultValue.apply_mask
        ? true
        : false,
    };
    const getFormList = JSON.parse(localStorage.getItem("restaurant_list"));
    if (editRestaurantId) {
      let index = getFormList.findIndex((item) => item.id === editRestaurantId);
      data.id = editRestaurantId;
      getFormList[index] = data;
      localStorage.setItem("form_list", JSON.stringify(getFormList));
    } else if (getFormList === null) {
      localStorage.setItem("restaurant_list", JSON.stringify([]));
      const getFormData = JSON.parse(localStorage.getItem("restaurant_list"));
      data.id = uuid.v4();
      getFormData.push(data);
      localStorage.setItem("restaurant_list", JSON.stringify(getFormData));
    } else {
      data.id = uuid.v4();
      getFormList.push(data);
      localStorage.setItem("restaurant_list", JSON.stringify(getFormList));
    }
  };

  const onSubmit = async (data) => {
    data.settings = {
      two_factor_api_key: data.two_factor_api_key,
      google_maps_api_key: data.google_api_key,
      map_service_provider: data.google_api_key ? "google" : "map_my_india",
      app_name: data.app_name,
      mapmyindia_client_id: data.mapmyindia_client_id
        ? data.mapmyindia_client_id
        : "",
      mapmyindia_client_secret: data.mapmyindia_client_secret
        ? data.mapmyindia_client_secret
        : "",
      mapmyindia_rest_api_key: data.mapmyindia_rest_api_key
        ? data.mapmyindia_rest_api_key
        : "",
      // set intro image
      logo_intro_image_id: data.logo_header_image.id,
      // logo_intro_image_id: 11,
      // set footer image
      logo_footer_image_id: data.logo_footer_image.id,
      //set brand logo
      brand_logo_image_id: data.logo_brand_image
        ? data.logo_brand_image.id
        : null,
      // logo_footer_image_id: 12,
      apply_mask: data.apply_mask
        ? data.apply_mask
        : editRestaurantId
        ? defaultValue.apply_mask
          ? true
          : false
        : true,
      logo_height: data.logo_height,
      share_content: data.share_content,
    };
    delete data.logo_header_image;
    delete data.logo_footer_image;
    delete data.two_factor_api_key;
    delete data.google_api_key;
    delete data.app_name;

    data.notification_setting = {
      fcm_server_key: data.firebase_server_key,
      fcm_api_key: data.firebase_api_key,
      fcm_auth_domain: data.firebase_auth_domain,
      fcm_database_url: data.firebase_database_url,
      fcm_project_id: data.firebase_project_id,
      fcm_storage_bucket: data.firebase_storage_bucket,
      fcm_messaging_sender_id: data.firebase_messaging_sender_id,
      fcm_measurement_id: data.firebase_measurement_id,
      fcm_app_id: data.firebase_app_id,
      fcm_public_vapi_key: data.firebase_project_vapi_id,
      firebase_credentials: data.firebase_credentials,
    };

    const about_us = {};
    const contact_us = {};
    const privacy_and_policy = {};
    const terms_and_conditions = {};
    const return_policy = {};
    const intro_screens = {};

    // check empty object
    function isEmptyObj(object) {
      for (var key in object) {
        if (object.hasOwnProperty(key)) {
          return false;
        }
      }
      return true;
    }
    //check all property
    function checkProperties(obj) {
      for (var key in obj) {
        if (obj[key] != "") return false;
      }
      return true;
    }

    //intro image
    for (const key in data.intro_screens) {
      console.log(key);
      intro_screens[parseInt(key)] = data.intro_screens[key];
      intro_screens[parseInt(key)].image =
        intro_screens[parseInt(key)].image &&
        intro_screens[parseInt(key)].image.id;
      if (isEmptyObj(intro_screens[parseInt(key)].label)) {
        delete intro_screens[parseInt(key)].label;
      }
      let flag = false;
      Object.values(intro_screens[parseInt(key)].label).forEach((item) => {
        if (item === null || item === "" || item === undefined) {
          flag = true;
        }
      });
      if (flag) {
        delete intro_screens[parseInt(key)].label;
      }
    }

    // set intro screen image
    data.intro_screens = intro_screens;
    // data.intro_screens ={
    //   "1":{
    //     image:12,
    //     label : {
    //       "en":"english"
    //     }
    //   }
    // }

    for (const property in data.translations.about_us) {
      about_us[parseInt(property)] = data.translations.about_us[property];
    }
    for (const property in data.translations.contact_us) {
      contact_us[parseInt(property)] = data.translations.contact_us[property];
    }
    for (const property in data.translations.privacy_and_policy) {
      privacy_and_policy[parseInt(property)] =
        data.translations.privacy_and_policy[property];
    }
    for (const property in data.translations.terms_and_conditions) {
      terms_and_conditions[parseInt(property)] =
        data.translations.terms_and_conditions[property];
    }
    for (const property in data.translations.return_policy) {
      return_policy[parseInt(property)] =
        data.translations.return_policy[property];
    }

    data.about_us = about_us;
    data.contact_us = contact_us;
    data.privacy_and_policy = privacy_and_policy;
    data.terms_and_conditions = terms_and_conditions;
    data.return_policy = return_policy;

    data.plan_information = {
      join_date: data.join_date
        ? data.join_date.format("YYYY-MM-DD")
        : joinDate.format("YYYY-MM-DD"),
      plan: data.plan ? data.plan : selectedContractRenewalTime,
    };
    // delete data.intro_screens
    let updateRestaurant;
    setLoader(true);
    if (editRestaurantId) {
      data.id = editRestaurantId;
      updateRestaurant = await editMutate(data);

      if (updateRestaurant.success === true) {
        alert("Data is updated added");
        dispatch({
          type: "set-edit-restaurants",
          payload: {
            editRestaurantId: null,
          },
        });
        history.push("/list-restaurant");
        setErrorMessage("");
        setSubmitError(false);
      } else {
        setErrorMessage(updateRestaurant.errors);
        setSubmitError(true);
      }
    } else {
      updateRestaurant = await addMutate(data);
      if (updateRestaurant.success === true) {
        alert("Data is successfully added");
      } else {
        setErrorMessage(updateRestaurant.errors);
        setSubmitError(true);
      }
    }
    setLoader(false);
    reset();
  };

  // display error
  const displayError = () => {
    let errorsList = [];
    if (submitError) {
      for (var key in errorMessage) {
        errorsList.push(
          <EuiTextColor color="danger">{errorMessage[key]}</EuiTextColor>
        );
      }
    }
    return errorsList;
  };

  const displayValidationError = () => {
    let innerArray = [];
    if (!isEmpty(errors)) {
      for (var key in errors) {
        errors[key].message &&
          innerArray.push(
            <EuiText>
              <ul>
                <li>{errors[key].message}</li>
              </ul>
            </EuiText>
          );
      }

      if (errors.intro_screens) {
        errors.intro_screens.map((screen) => {
          screen &&
            innerArray.push(
              <EuiText>
                <ul>
                  <li>{screen["image"].message}</li>
                </ul>
              </EuiText>
            );
        });
      }
    }
    return innerArray;
  };

  //check error
  const checkImageValidation = (value, itemImage) => {
    let imageLabel = value;
    let label = false;
    if (imageLabel) {
      for (const [key, value] of Object.entries(imageLabel)) {
        if (value) {
          label = true;
        }
      }
      if (!label) {
        console.log("inside label", errors);
        console.log("inside itemImage", itemImage);
        if (errors.intro_screens && errors.intro_screens[itemImage]) {
          delete errors.intro_screens[itemImage];
        }
      }
      return label;
    }
  };

  const onChangeValue = (e) => {
    console.log("on change value ", e.target);
    setValue("apply_mask", e.target.checked);
    // defaultValue.apply_mask = e.target.checked
  };

  const availableLanguage = availabledLanguage;
  const onChangeLanguage = (selectedLanguage) => {
    setValue("languages", selectedLanguage);
  };

  const onJoinDateChange = (date) => {
    setJoinDate(date);
    setValue("join_date", date);
  };

  console.log("watch ", watch());
  return (
    <Fragment>
      <EuiText style={{ marginBottom: "20px" }}>
        <strong>Create Reataurant</strong>{" "}
      </EuiText>
      <EuiForm component="form" onSubmit={handleSubmit(onSubmit)}>
        <TextField
          errors={errors}
          label="Name of restaurant"
          name="name"
          placeholder="Please enter the name of the restaurant"
          rules={{ required: "Please enter name of restaurant" }}
          control={control}
          watch={watch}
        />
        <EuiFormRow label="Select logo">
          <ImageDropCrop
            control={control}
            errors={errors}
            imageType="restaurant"
            setValue={setValue}
            watch={watch}
            name="logo_header_image"
            rules={{
              required: "Please  select this image (it's mandatory) ",
            }}
            isInvalid={errors.logo_header_image}
            error={
              errors.logo_header_image ? errors.logo_header_image.message : ""
            }
          />
        </EuiFormRow>
        <EuiFormRow label="Select footer logo">
          <ImageDropCrop
            aspectHeight={1}
            aspectWidth={1}
            imageType="restaurant"
            control={control}
            errors={errors}
            setValue={setValue}
            watch={watch}
            name="logo_footer_image"
            rules={{
              required: "Please  select this image (it's mandatory) ",
            }}
            isInvalid={errors.logo_footer_image}
            error={
              errors.logo_footer_image ? errors.logo_footer_image.message : ""
            }
          />
        </EuiFormRow>
        <EuiFormRow label="Select brand logo">
          <ImageDropCrop
            aspectHeight={1}
            aspectWidth={1}
            control={control}
            errors={errors}
            setValue={setValue}
            watch={watch}
            name="logo_brand_image"
            // rules={{
            //     required: "Please  select this image (it's mendatory) ",
            // }}
            isInvalid={errors.logo_brand_image}
            error={
              errors.logo_brand_image ? errors.logo_brand_image.message : ""
            }
          />
        </EuiFormRow>
        <ColorField
          errors={errors}
          label="Brand Color (Primary color) "
          name="brand_color_primary"
          placeholder="Please brand color"
          rules={{ required: "Please enter primary color" }}
          control={control}
          watch={watch}
          defaultValue="#FFFFFF"
        />
        <ColorField
          errors={errors}
          label="Brand Color (Secondary color) "
          name="brand_color_secondary"
          placeholder="Please brand color"
          rules={{ required: "Please enter secondary color" }}
          control={control}
          watch={watch}
          defaultValue="#FFFFFF"
        />
        <ColorField
          errors={errors}
          label="Location Selector Color"
          name="location_selector_color"
          placeholder="Please select color location selector color"
          rules={{ required: "Please select location selector color" }}
          control={control}
          watch={watch}
          defaultValue="#F5A623"
        />
        <ColorField
          errors={errors}
          label="Location Deliverable Color"
          name="location_deliverable_color"
          placeholder="Please select location deliverable color "
          rules={{ required: "Please enter location deliverable color" }}
          control={control}
          watch={watch}
          defaultValue="#003459"
        />
        <ColorField
          errors={errors}
          label="Location Undeliverable Color"
          name="location_undeliverable_color"
          placeholder="Please brand color"
          rules={{ required: "Please enter color" }}
          control={control}
          watch={watch}
          defaultValue="#DB2323"
        />
        <ColorField
          errors={errors}
          label="Pickup Selector Color"
          name="pickup_selector_color"
          placeholder="Please select pickup selector color"
          rules={{ required: "Please select pickup selector color" }}
          control={control}
          watch={watch}
          defaultValue="#F5A623"
        />
        <ColorField
          errors={errors}
          label="Pickup Tab Color"
          name="pickup_tab_color"
          placeholder="Please select pickup tab color"
          rules={{ required: "Please select pickup tab color" }}
          control={control}
          watch={watch}
          defaultValue="#0F4C75"
        />
        <ColorField
          errors={errors}
          label="PiPickup Category Font Color"
          name="category_font_color"
          placeholder="Please select category font colorZ"
          rules={{ required: "Please select category font color" }}
          control={control}
          watch={watch}
          defaultValue="#0F4C75"
        />
        <ColorField
          errors={errors}
          label="Pickup Category Option Color"
          name="option_availability_text_color"
          placeholder="Please select category option color"
          rules={{ required: "Please select category option color" }}
          control={control}
          watch={watch}
          defaultValue="#0F4C75"
        />
        <TextField
          errors={errors}
          label="2Factor SMS API key"
          name="two_factor_api_key"
          placeholder="Enter 2Factor SMS API key"
          rules={{ required: "Please Enter Two factor api key" }}
          control={control}
          watch={watch}
        />

        <TextField
          errors={errors}
          label="Google API key"
          name="google_api_key"
          placeholder="Enter Google maps API key"
          rules={{ required: "Please Enter Google api key" }}
          control={control}
          watch={watch}
        />
        <TextField
          errors={errors}
          label="Firebase server key"
          name="firebase_server_key"
          placeholder="Enter firebase maps server key"
          rules={{ required: "Please enter firebase server key" }}
          control={control}
          watch={watch}
        />
        <TextField
          errors={errors}
          label="Firebase API key"
          name="firebase_api_key"
          placeholder="Enter firebase maps API key"
          rules={{ required: "Please enter firebase api key" }}
          control={control}
          watch={watch}
        />

        <TextField
          errors={errors}
          label="Firebase auth domain"
          name="firebase_auth_domain"
          placeholder="Enter firebase maps auth domain"
          rules={{ required: "Please enter firebase auth domain" }}
          control={control}
          watch={watch}
        />

        <TextField
          errors={errors}
          label="Firebase database url"
          name="firebase_database_url"
          placeholder="Enter firebase maps database url"
          rules={{ required: "Please enter firebase database url" }}
          control={control}
          watch={watch}
        />

        <TextField
          errors={errors}
          label="Firebase project id"
          name="firebase_project_id"
          placeholder="Enter firebase maps project id"
          rules={{ required: "Please enter firebase project id" }}
          control={control}
          watch={watch}
        />

        <TextField
          errors={errors}
          label="Firebase storage bucket"
          name="firebase_storage_bucket"
          placeholder="Enter firebase maps storage bucket"
          rules={{ required: "Please enter firebase storage bucket" }}
          control={control}
          watch={watch}
        />

        <TextField
          errors={errors}
          label="Firebase messaging sender id"
          name="firebase_messaging_sender_id"
          placeholder="Enter firebase maps sender id"
          rules={{ required: "Please enter firebase sender id" }}
          control={control}
          watch={watch}
        />

        <TextField
          errors={errors}
          label="Firebase app id"
          name="firebase_app_id"
          placeholder="Enter firebase maps  app id"
          rules={{ required: "Please enter firebase  app id" }}
          control={control}
          watch={watch}
        />
        <TextField
          errors={errors}
          label="Firebase measurement id"
          name="firebase_measurement_id"
          placeholder="Enter firebase  measurement id"
          rules={{ required: "Please enter firebase measurement id" }}
          control={control}
          watch={watch}
        />

        <TextField
          errors={errors}
          label="Firebase public vapi id"
          name="firebase_project_vapi_id"
          placeholder="Enter firebase project vapi id"
          rules={{ required: "Please enter firebase project vapi id" }}
          control={control}
          watch={watch}
        />

        <TextAreaField
          errors={errors}
          label="Firebase credentials"
          name="firebase_credentials"
          rows={5}
          rules={{
            required: "Please enter firebase_credentials",
          }}
          control={control}
          watch={watch}
        />

        <TextField
          errors={errors}
          label="Application display name"
          name="app_name"
          placeholder="Enter the display name of the application"
          rules={{ required: "Please Enter your application name" }}
          control={control}
          watch={watch}
        />
        <ComboBoxField
          options={availableLanguage}
          selectedOptions={watch("languages") ? watch("languages") : []}
          onChangeData={onChangeLanguage}
          errors={errors}
          label="Select languages"
          name="languages"
          placeholder="Select one or more languages for the application"
          rules={{
            validate: (value) =>
              (watch("languages") && watch("languages").length > 0) ||
              "Please select atleast one language",
          }}
          control={control}
          watch={watch}
        />

        {watch("languages") && watch("languages").length > 0 && (
          <Fragment>
            <EuiFormRow style={{ maxWidth: "100%" }}>
              <>
                <EuiFlexGroup>
                  {totalImage.map((itemImage, imageIndex) => (
                    <EuiFlexItem grow={3} key={itemImage}>
                      <EuiFormRow
                        label={
                          imageIndex === 0
                            ? `Screen ${itemImage} mandatory`
                            : `Screen ${itemImage} optional`
                        }
                      >
                        <ImageDropCrop
                          rules={
                            itemImage === 1 ||
                            checkImageValidation(
                              watch(`intro_screens.${itemImage}.label`),
                              itemImage
                            )
                              ? {
                                  required:
                                    "Please  select this image (it's mandatory) ",
                                }
                              : null
                          }
                          isInvalid={
                            itemImage === 1 ||
                            checkImageValidation(
                              watch(`intro_screens.${itemImage}.label`),
                              itemImage
                            )
                              ? errors.intro_screens &&
                                errors.intro_screens.hasOwnProperty(
                                  itemImage
                                ) &&
                                errors.intro_screens[itemImage].image
                              : null
                          }
                          error={
                            itemImage === 1 ||
                            checkImageValidation(
                              watch(`intro_screens.${itemImage}.label`),
                              itemImage
                            )
                              ? errors.intro_screens &&
                                errors.intro_screens.hasOwnProperty(
                                  itemImage
                                ) &&
                                errors.intro_screens[itemImage].image &&
                                errors.intro_screens[itemImage].image
                                ? errors.intro_screens[itemImage].image.message
                                : ""
                              : null
                          }
                          removeError={itemImage}
                          control={control}
                          errors={errors}
                          setValue={setValue}
                          watch={watch}
                          name={`intro_screens.${itemImage}.image`}
                          imageType="restaurant"
                        />
                      </EuiFormRow>
                      {watch("languages").map((item, index) => (
                        <TextField
                          style={{ width: "60%" }}
                          isInvalid={null}
                          error={null}
                          errors={errors}
                          label={`${item.language_label} :- Into screen ${itemImage} label `}
                          name={`intro_screens.${itemImage}.label.${item.language}`}
                          placeholder="Enter the intro screen label"
                          rules={null}
                          control={control}
                          watch={watch}
                        />
                      ))}
                    </EuiFlexItem>
                  ))}
                </EuiFlexGroup>
              </>
            </EuiFormRow>
          </Fragment>
        )}
        {watch("languages") &&
          watch("languages").length > 0 &&
          watch("languages").map((item, index) => (
            <Fragment>
              <TextAreaField
                errors={errors}
                isInvalid={
                  errors.translations &&
                  errors.translations.about_us &&
                  errors.translations.about_us.hasOwnProperty(item.language)
                }
                error={
                  errors.translations &&
                  errors.translations.about_us &&
                  errors.translations.about_us.hasOwnProperty(item.language) &&
                  errors.translations.about_us[item.language]
                    ? errors.translations.about_us[item.language].message
                    : ""
                }
                label={`${item.language_label} : about us`}
                name={`translations.about_us.${item.language}`}
                placeholder="Write about us"
                rules={{ required: "Please write about us" }}
                control={control}
                watch={watch}
                rows={4}
              />
              <TextAreaField
                errors={errors}
                isInvalid={
                  errors.translations &&
                  errors.translations.contact_us &&
                  errors.translations.contact_us.hasOwnProperty(item.language)
                }
                error={
                  errors.translations &&
                  errors.translations.contact_us &&
                  errors.translations.contact_us.hasOwnProperty(
                    item.language
                  ) &&
                  errors.translations.contact_us[item.language]
                    ? errors.translations.contact_us[item.language].message
                    : ""
                }
                label={`${item.language_label} :Contact us`}
                name={`translations.contact_us.${item.language}`}
                placeholder="Write contact us"
                rules={{ required: "Please write contact us" }}
                control={control}
                watch={watch}
                rows={4}
              />
              <TextAreaField
                errors={errors}
                isInvalid={
                  errors.translations &&
                  errors.translations.privacy_and_policy &&
                  errors.translations.privacy_and_policy.hasOwnProperty(
                    item.language
                  )
                }
                error={
                  errors.translations &&
                  errors.translations.privacy_and_policy &&
                  errors.translations.privacy_and_policy.hasOwnProperty(
                    item.language
                  ) &&
                  errors.translations.privacy_and_policy[item.language]
                    ? errors.translations.privacy_and_policy[item.language]
                        .message
                    : ""
                }
                label={`${item.language_label} : privacy and policy`}
                name={`translations.privacy_and_policy.${item.language}`}
                placeholder="Write privacy and policy"
                rules={{ required: "Please write privacy and policy" }}
                control={control}
                watch={watch}
                rows={4}
              />
              <TextAreaField
                errors={errors}
                isInvalid={
                  errors.translations &&
                  errors.translations.terms_and_conditions &&
                  errors.translations.terms_and_conditions.hasOwnProperty(
                    item.language
                  )
                }
                error={
                  errors.translations &&
                  errors.translations.terms_and_conditions &&
                  errors.translations.terms_and_conditions.hasOwnProperty(
                    item.language
                  ) &&
                  errors.translations.terms_and_conditions[item.language]
                    ? errors.translations.terms_and_conditions[item.language]
                        .message
                    : ""
                }
                label={`${item.language_label} : Term and condition`}
                name={`translations.terms_and_conditions.${item.language}`}
                placeholder="Write term and condition"
                rules={{ required: "Please write term and condition" }}
                control={control}
                watch={watch}
                rows={4}
              />
              <TextAreaField
                errors={errors}
                isInvalid={
                  errors.translations &&
                  errors.translations.return_policy &&
                  errors.translations.return_policy.hasOwnProperty(
                    item.language
                  )
                }
                error={
                  errors.translations &&
                  errors.translations.return_policy &&
                  errors.translations.return_policy.hasOwnProperty(
                    item.language
                  ) &&
                  errors.translations.return_policy[item.language]
                    ? errors.translations.return_policy[item.language].message
                    : ""
                }
                label={`${item.language_label} : Return policy`}
                name={`translations.return_policy.${item.language}`}
                placeholder="Write return policy"
                rules={{ required: "Please return policy" }}
                control={control}
                watch={watch}
                rows={4}
              />
            </Fragment>
          ))}
        <TextField
          errors={errors}
          label="Logo height"
          name="logo_height"
          placeholder="Logo height"
          control={control}
          watch={watch}
        />
        <TextAreaField
          errors={errors}
          label="Share content"
          name="share_content"
          placeholder="Share content"
          control={control}
          defaultValue={watch("share_content") ? watch("share_content") : ""}
        />
        <EuiFormRow label="Join date">
          <Controller
            render={({ onChange }) => (
              <EuiDatePicker
                selected={editRestaurantId ? watch("join_date") : joinDate}
                dateFormat={"DD-MM-YYYY"}
                onChange={onJoinDateChange}
              />
            )}
            name={"join_date"}
            control={control}
            fullWidth
            // defaultValue={joinDate}
          />
          {/*  */}
        </EuiFormRow>
        <EuiFormRow label={"Plan"} style={{ marginLeft: "2px" }}>
          <Controller
            render={({ onChange }) => (
              <EuiRadioGroup
                options={contractRenewalTime}
                idSelected={
                  editRestaurantId ? watch("plan") : selectedContractRenewalTime
                }
                onChange={(id) => {
                  onChange(id);
                  setSelectedContractRenewalTime(id);
                }}
              />
            )}
            name={"plan"}
            control={control}
            fullWidth
          />
        </EuiFormRow>
        <EuiFormRow label={"Apply Mask on Image"} style={{ marginLeft: "2px" }}>
          <Controller
            render={({ onChange }) => (
              <EuiSwitch
                style={{ marginLeft: "2px" }}
                label=""
                checked={watch("apply_mask") ? watch("apply_mask") : false}
                onChange={(e) => onChangeValue(e)}
              />
            )}
            name={"apply_mask"}
            control={control}
            fullWidth
            // defaultValue={defaultValue.apply_mask}
          />
        </EuiFormRow>
        {/* {console.log("errors", errors)} */}
        {!isEmpty(errors) && showError && (
          <EuiFlexGroup
            direction="column"
            style={{
              backgroundColor: "#f8e9e9",
              padding: "5px",
              marginTop: "15px",
            }}
          >
            <EuiFlexItem
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <EuiTextColor color="danger" style={{ paddingBottom: "10px" }}>
                <b>Please resolve below validations</b>
              </EuiTextColor>
              <EuiButtonIcon
                color={"warning"}
                onClick={() => setShowError(false)}
                iconType="cross"
                aria-label="cross"
              />
            </EuiFlexItem>
            {displayValidationError()}
          </EuiFlexGroup>
        )}
        {submitError && (
          <EuiFlexGroup
            direction="column"
            style={{
              backgroundColor: "#f8e9e9",
              margin: "10px",
              padding: "15px",
              borderRadius: "5px",
            }}
          >
            <EuiTextColor color="danger" style={{ paddingBottom: "10px" }}>
              <b>Please resolve below validations</b>
            </EuiTextColor>
            {displayError()}
          </EuiFlexGroup>
        )}
        <EuiFlexGroup style={{ marginTop: 10 }}>
          <EuiFlexItem grow={false}>
            <EuiButton onClick={saveIntoLocalStorage} fill>
              Save local draft
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton
              onClick={() => setShowError(true)}
              isLoading={loader}
              type="submit"
              fill
            >
              Submit
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiForm>
    </Fragment>
  );
};

export default CreateRestaurant;
