import React, { useRef } from "react";
import {
  EuiFocusTrap,
  EuiPage,
  EuiPageBody,
  EuiPageContent,
  EuiPageContentBody,
  EuiProvider,
} from "@elastic/eui";
import { Header } from "../header";
import { useSelector } from "react-redux";

export const MainLayout = (props) => {
  const navDrawerRef = useRef(null);
  const theme = useSelector((state) => state.theme.theme);

  return (
    <>
      <EuiFocusTrap>
        <EuiProvider colorMode={theme}>
          <Header navDrawerRef={navDrawerRef} />

          <EuiPage
            style={{
              backgroundColor:
                theme === "light"
                  ? "rgba(244, 244, 244, 1)"
                  : theme === "light"
                  ? "#ffffff"
                  : "",
            }}
            className="euiNavDrawerPage main-page-height"
          >
            <EuiPageBody style={{ overflowX: "hidden" }}>
              <EuiPageContent>
                <EuiPageContentBody>{props.children}</EuiPageContentBody>
              </EuiPageContent>
            </EuiPageBody>
          </EuiPage>
        </EuiProvider>
      </EuiFocusTrap>
    </>
  );
};
