import React, { useEffect, useState } from "react";
import {
  EuiPanel,
  EuiText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiLoadingContent,
} from "@elastic/eui";
import { RevenueCard } from "../revenueCard";
import {
  getCurrentMonthString,
  getDateByDate,
  getPreviousMonthString,
} from "../../../helper/dateTime";
import { getOutletOutletOrderDetails } from "../../../api/outlet";

export const OutletOrders = (props) => {
  const [state, setState] = useState({
    todaySwitch: true,
    currentMonthSwitch: true,
    currentWeekSwitch: true,
    apiDataLoaded: false,
    today: {
      fromDate: "",
      toDate: "",
      delivered: {
        revenue: "",
        total: "",
      },
      cancelled: {
        revenue: "",
        total: "",
      },
    },
    yesterday: {
      fromDate: "",
      toDate: "",
      delivered: {
        revenue: "",
        total: "",
      },
      cancelled: {
        revenue: "",
        total: "",
      },
    },
    week: {
      fromDate: "",
      toDate: "",
      delivered: {
        revenue: "",
        total: "",
      },
      cancelled: {
        revenue: "",
        total: "",
      },
    },
    preWeek: {
      fromDate: "",
      toDate: "",
      delivered: {
        revenue: "",
        total: "",
      },
      cancelled: {
        revenue: "",
        total: "",
      },
    },
    month: {
      fromDate: "",
      toDate: "",
      delivered: {
        revenue: "",
        total: "",
      },
      cancelled: {
        revenue: "",
        total: "",
      },
    },
    preMonth: {
      fromDate: "",
      toDate: "",
      delivered: {
        revenue: "",
        total: "",
      },
      cancelled: {
        revenue: "",
        total: "",
      },
    },
  });

  useEffect(() => {
    getOutletOutletOrderDetails(props.outletId).then((res) => {
      if (!res.apiError) {
        const details = res.body.details;
        setState({
          ...state,
          apiDataLoaded: true,
          today: {
            fromDate: details.today.from_date,
            toDate: details.today.to_date,
            delivered: {
              revenue: details.today.delivered.revenue,
              total: details.today.delivered.total,
            },
            cancelled: {
              revenue: details.today.cancelled.revenue,
              total: details.today.cancelled.total,
            },
          },
          yesterday: {
            fromDate: details.yesterday.from_date,
            toDate: details.yesterday.to_date,
            delivered: {
              revenue: details.yesterday.delivered.revenue,
              total: details.yesterday.delivered.total,
            },
            cancelled: {
              revenue: details.yesterday.cancelled.revenue,
              total: details.yesterday.cancelled.total,
            },
          },
          week: {
            fromDate: details.week.from_date,
            toDate: details.week.to_date,
            delivered: {
              revenue: details.week.delivered.revenue,
              total: details.week.delivered.total,
            },
            cancelled: {
              revenue: details.week.cancelled.revenue,
              total: details.week.cancelled.total,
            },
          },
          preWeek: {
            fromDate: details.past_week.from_date,
            toDate: details.past_week.to_date,
            delivered: {
              revenue: details.past_week.delivered.revenue,
              total: details.past_week.delivered.total,
            },
            cancelled: {
              revenue: details.past_week.cancelled.revenue,
              total: details.past_week.cancelled.total,
            },
          },
          month: {
            fromDate: details.month.from_date,
            toDate: details.month.to_date,
            delivered: {
              revenue: details.month.delivered.revenue,
              total: details.month.delivered.total,
            },
            cancelled: {
              revenue: details.month.cancelled.revenue,
              total: details.month.cancelled.total,
            },
          },
          preMonth: {
            fromDate: details.past_month.from_date,
            toDate: details.past_month.to_date,
            delivered: {
              revenue: details.past_month.delivered.revenue,
              total: details.past_month.delivered.total,
            },
            cancelled: {
              revenue: details.past_month.cancelled.revenue,
              total: details.past_month.cancelled.total,
            },
          },
        });
      }
    });
  }, []);

  const getToDateString = (date1, date2) => {
    return getDateByDate(date1) + " to " + getDateByDate(date2);
  };
  return (
    <EuiPanel paddingSize="s">
      <EuiText>
        <h5>Order Details</h5>
      </EuiText>
      <EuiSpacer size="s" />
      {!state.apiDataLoaded && <EuiLoadingContent lines={4} />}
      {state.apiDataLoaded && (
        <>
          <EuiFlexGroup>
            <EuiFlexItem>
              <RevenueCard
                totalAmount={
                  state.todaySwitch
                    ? state.today.delivered.revenue +
                      state.today.cancelled.revenue
                    : state.yesterday.delivered.revenue +
                      state.yesterday.cancelled.revenue
                }
                totalOrders={
                  state.todaySwitch
                    ? state.today.delivered.total + state.today.cancelled.total
                    : state.yesterday.delivered.total +
                      state.yesterday.cancelled.total
                }
                deliveredAmount={
                  state.todaySwitch
                    ? state.today.delivered.revenue
                    : state.yesterday.delivered.revenue
                }
                deliveredOrders={
                  state.todaySwitch
                    ? state.today.delivered.total
                    : state.yesterday.delivered.total
                }
                cancelledAmount={
                  state.todaySwitch
                    ? state.today.cancelled.revenue
                    : state.yesterday.cancelled.revenue
                }
                cancelledOrders={
                  state.todaySwitch
                    ? state.today.cancelled.total
                    : state.yesterday.cancelled.total
                }
                preSwitchText="Yesterday"
                nextSwitchText="Today"
                toolTipText={
                  state.todaySwitch
                    ? getDateByDate(state.today.fromDate)
                    : getDateByDate(state.yesterday.fromDate)
                }
                isSwitchActive={state.todaySwitch}
                onChangeSwitch={(e) =>
                  setState({ ...state, todaySwitch: e.target.checked })
                }
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <RevenueCard
                totalAmount={
                  state.currentWeekSwitch
                    ? state.week.delivered.revenue +
                      state.week.cancelled.revenue
                    : state.preWeek.delivered.revenue +
                      state.preWeek.cancelled.revenue
                }
                totalOrders={
                  state.currentWeekSwitch
                    ? state.week.delivered.total + state.week.cancelled.total
                    : state.preWeek.delivered.total +
                      state.preWeek.cancelled.total
                }
                deliveredAmount={
                  state.currentWeekSwitch
                    ? state.week.delivered.revenue
                    : state.preWeek.delivered.revenue
                }
                deliveredOrders={
                  state.currentWeekSwitch
                    ? state.week.delivered.total
                    : state.preWeek.delivered.total
                }
                cancelledAmount={
                  state.currentWeekSwitch
                    ? state.week.cancelled.revenue
                    : state.preWeek.cancelled.revenue
                }
                cancelledOrders={
                  state.currentWeekSwitch
                    ? state.week.cancelled.total
                    : state.preWeek.cancelled.total
                }
                preSwitchText="Previous Week"
                nextSwitchText="This Week"
                toolTipText={
                  state.currentWeekSwitch
                    ? getToDateString(state.week.fromDate, state.week.toDate)
                    : getToDateString(
                        state.preWeek.fromDate,
                        state.preWeek.toDate
                      )
                }
                isSwitchActive={state.currentWeekSwitch}
                onChangeSwitch={(e) =>
                  setState({ ...state, currentWeekSwitch: e.target.checked })
                }
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <RevenueCard
                totalAmount={
                  state.currentMonthSwitch
                    ? state.month.delivered.revenue +
                      state.month.cancelled.revenue
                    : state.preMonth.delivered.revenue +
                      state.preMonth.cancelled.revenue
                }
                totalOrders={
                  state.currentMonthSwitch
                    ? state.month.delivered.total + state.month.cancelled.total
                    : state.preMonth.delivered.total +
                      state.preMonth.cancelled.total
                }
                deliveredAmount={
                  state.currentMonthSwitch
                    ? state.month.delivered.revenue
                    : state.preMonth.delivered.revenue
                }
                deliveredOrders={
                  state.currentMonthSwitch
                    ? state.month.delivered.total
                    : state.preMonth.delivered.total
                }
                cancelledAmount={
                  state.currentMonthSwitch
                    ? state.month.cancelled.revenue
                    : state.preMonth.cancelled.revenue
                }
                cancelledOrders={
                  state.currentMonthSwitch
                    ? state.month.cancelled.total
                    : state.preMonth.cancelled.total
                }
                preSwitchText={getPreviousMonthString()}
                nextSwitchText={getCurrentMonthString()}
                toolTipText={
                  state.currentMonthSwitch
                    ? getToDateString(state.month.fromDate, state.month.toDate)
                    : getToDateString(
                        state.preMonth.fromDate,
                        state.preMonth.toDate
                      )
                }
                isSwitchActive={state.currentMonthSwitch}
                onChangeSwitch={(e) =>
                  setState({ ...state, currentMonthSwitch: e.target.checked })
                }
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </>
      )}
    </EuiPanel>
  );
};
