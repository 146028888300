import React from "react";
import { EuiFormRow, EuiTextArea } from "@elastic/eui";
import { Controller } from "react-hook-form";

const TextAreaField = (props) => {
  const {
    label,
    name,
    placeholder,
    rules,
    errors,
    control,
    isInvalid,
    error,
    fullWidth,
  } = props;
  return (
    <>
      <EuiFormRow
        label={label}
        isInvalid={isInvalid ? isInvalid : errors[name]}
        error={error ? error : errors[name] ? errors[name].message : ""}
        fullWidth={fullWidth ? true : false}
      >
        <Controller
          {...props}
          as={EuiTextArea}
          name={name}
          control={control}
          rules={rules}
          fullWidth={true}
          placeholder={placeholder}
        />
      </EuiFormRow>
    </>
  );
};

export default TextAreaField;
