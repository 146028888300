import React, { useState, useEffect } from "react";
import {
  EuiFlexGroup,
  EuiText,
  EuiFlexItem,
  EuiFormRow,
  EuiButton,
  EuiRadioGroup,
  EuiForm,
} from "@elastic/eui";
import SwitchField from "../../Form/SwitchField";
import { Controller, useForm } from "react-hook-form";
import TimeSlot from "../../Form/TimeSlot";

import { formatedData } from "../createOutlet/formatedData";
import { v4 as uuidv4, v1 as uuid } from "uuid";
import { useSelector } from "react-redux";
import { baseApiUrl } from "../../../envConfig";
import { store } from "../../../reduxStore/store";

const Timing = ({ getOutletData }) => {
  const restaurantID = useSelector((state) => state.restaurant.restaurantId);
  var token = store.getState().auth.token;
  const { register, handleSubmit, errors, control, watch, reset, setValue } =
    useForm();
  let defaultValue = {};
  const [loader, setLoader] = useState(false);
  const [timeSlotError, setTimeSlotError] = useState(false);
  const timeSlot = [
    {
      id: "outlet_same_day",
      label: "Available at same time for all days of the week",
    },
    {
      id: "outlet_differant_day",
      label: "Available at specific time for all days of the week",
    },
  ];
  const deliveryTimeSlot = [
    {
      id: 9,
      label: "Delivery is available at  for all days of the week ",
    },
    {
      id: 10,
      label: "Delivery is available at same time for all days of the week",
    },
    {
      id: 11,
      label: "Delivery is available at specific time for all days of the week",
    },
  ];
  const dineInTimeSlot = [
    {
      id: 12,
      label: "Dine-in is available at  for all days of the week ",
    },
    {
      id: 13,
      label: "Dine-in is available at same time for all days of the week",
    },
    {
      id: 14,
      label: "Dine-in is available at specific time for all days of the week",
    },
  ];
  const pickUpTimeSlot = [
    {
      id: 15,
      label: "Takeaway is available at  for all days of the week ",
    },
    {
      id: 16,
      label: "Takeaway is available at same time for all days of the week",
    },
    {
      id: 17,
      label: "Takeaway is available at specific time for all days of the week",
    },
  ];
  useEffect(() => {
    if (getOutletData) {
      const outletData = getOutletData;
      defaultValue.delivery = outletData.settings.delivery ? true : false;
      defaultValue.dine_in = outletData.settings.dine_in ? true : false;
      defaultValue.takeaway = outletData.settings.takeaway ? true : false;
      // set delivery timer_for_delivery
      defaultValue.timer_for_delivery =
        outletData.settings.delivery_time_slot_setting === "no_time_slots"
          ? 9
          : outletData.settings.delivery_time_slot_setting ===
            "same_time_slot_for_all_days"
          ? 10
          : outletData.settings.delivery_time_slot_setting ===
            "different_time_slots_for_different_days"
          ? 11
          : 9;
      if (
        outletData.settings.delivery_time_slot_setting ===
        "same_time_slot_for_all_days"
      ) {
        let formatedTimeSlotInfoDelivery = formatedData(
          outletData.settings.delivery_schedule
        );
        let fieldData = [];
        formatedTimeSlotInfoDelivery &&
          formatedTimeSlotInfoDelivery[1] &&
          formatedTimeSlotInfoDelivery[1].map((item, index) => {
            fieldData.push({
              id: uuid(),
              start_time: item.start_time,
              end_time: item.end_time,
            });
          });
        defaultValue.timeForDelivery = fieldData;
      }
      defaultValue.weakDelivery = {
        sunday: [],
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: [],
      };
      if (
        outletData.settings.delivery_time_slot_setting ===
        "different_time_slots_for_different_days"
      ) {
        let formatedTimeSlotInfoDelivery = formatedData(
          outletData.settings.delivery_schedule
        );
        for (const key in formatedTimeSlotInfoDelivery) {
          console.log(key);
          if (key == 7) {
            console.log("sunday");
            formatedTimeSlotInfoDelivery[7].map((item) =>
              defaultValue.weakDelivery.sunday.push({
                id: uuidv4(),
                start_time: item.start_time,
                end_time: item.end_time,
              })
            );
            console.log(defaultValue.weakDelivery.sunday);
          }
          if (key == 1) {
            console.log("monday");
            formatedTimeSlotInfoDelivery[1].forEach((item) =>
              defaultValue.weakDelivery.monday.push({
                id: uuidv4(),
                start_time: item.start_time,
                end_time: item.end_time,
              })
            );
          }
          if (key == 2) {
            console.log("tuesday");
            formatedTimeSlotInfoDelivery[2].map((item) =>
              defaultValue.weakDelivery.tuesday.push({
                id: uuidv4(),
                start_time: item.start_time,
                end_time: item.end_time,
              })
            );
          }
          if (key == 3) {
            console.log("wednesday");
            formatedTimeSlotInfoDelivery[3].map((item) =>
              defaultValue.weakDelivery.wednesday.push({
                id: uuidv4(),
                start_time: item.start_time,
                end_time: item.end_time,
              })
            );
          }
          if (key == 4) {
            console.log("thursday");
            formatedTimeSlotInfoDelivery[4].map((item) =>
              defaultValue.weakDelivery.thursday.push({
                id: uuidv4(),
                start_time: item.start_time,
                end_time: item.end_time,
              })
            );
          }
          if (key == 5) {
            console.log("friday");
            formatedTimeSlotInfoDelivery[5].map((item) =>
              defaultValue.weakDelivery.friday.push({
                id: uuidv4(),
                start_time: item.start_time,
                end_time: item.end_time,
              })
            );
          }

          if (key == 6) {
            console.log("saturday");
            formatedTimeSlotInfoDelivery[6].map((item) =>
              defaultValue.weakDelivery.saturday.push({
                id: uuidv4(),
                start_time: item.start_time,
                end_time: item.end_time,
              })
            );
          }
        }
      }

      // set dinin
      defaultValue.timer_for_dine_in =
        outletData.settings.dine_in_time_slot_setting === "no_time_slots"
          ? 12
          : outletData.settings.dine_in_time_slot_setting ===
            "same_time_slot_for_all_days"
          ? 13
          : outletData.settings.dine_in_time_slot_setting ===
            "different_time_slots_for_different_days"
          ? 14
          : 12;
      if (
        outletData.settings.dine_in_time_slot_setting ===
        "same_time_slot_for_all_days"
      ) {
        let formatedTimeSlotInfoDinein = formatedData(
          outletData.settings.dine_in_schedule
        );
        let fieldData = [];
        formatedTimeSlotInfoDinein &&
          formatedTimeSlotInfoDinein[1] &&
          formatedTimeSlotInfoDinein[1].map((item, index) => {
            fieldData.push({
              id: uuid(),
              start_time: item.start_time,
              end_time: item.end_time,
            });
          });
        defaultValue.timeForDineIn = fieldData;
      }
      defaultValue.weakDineIn = {
        sunday: [],
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: [],
      };
      if (
        outletData.settings.dine_in_time_slot_setting ===
        "different_time_slots_for_different_days"
      ) {
        let formatedTimeSlotInfoDinein = formatedData(
          outletData.settings.dine_in_schedule
        );
        for (const key in formatedTimeSlotInfoDinein) {
          console.log(key);
          if (key == 7) {
            formatedTimeSlotInfoDinein[7].map((item) =>
              defaultValue.weakDineIn.sunday.push({
                id: uuidv4(),
                start_time: item.start_time,
                end_time: item.end_time,
              })
            );
          }
          if (key == 1) {
            formatedTimeSlotInfoDinein[1].forEach((item) =>
              defaultValue.weakDineIn.monday.push({
                id: uuidv4(),
                start_time: item.start_time,
                end_time: item.end_time,
              })
            );
          }

          if (key == 2) {
            formatedTimeSlotInfoDinein[2].map((item) =>
              defaultValue.weakDineIn.tuesday.push({
                id: uuidv4(),
                start_time: item.start_time,
                end_time: item.end_time,
              })
            );
          }

          if (key == 3) {
            formatedTimeSlotInfoDinein[3].map((item) =>
              defaultValue.weakDineIn.wednesday.push({
                id: uuidv4(),
                start_time: item.start_time,
                end_time: item.end_time,
              })
            );
          }

          if (key == 4) {
            formatedTimeSlotInfoDinein[4].map((item) =>
              defaultValue.weakDineIn.thursday.push({
                id: uuidv4(),
                start_time: item.start_time,
                end_time: item.end_time,
              })
            );
          }

          if (key == 5) {
            formatedTimeSlotInfoDinein[5].map((item) =>
              defaultValue.weakDineIn.friday.push({
                id: uuidv4(),
                start_time: item.start_time,
                end_time: item.end_time,
              })
            );
          }

          if (key == 6) {
            formatedTimeSlotInfoDinein[6].map((item) =>
              defaultValue.weakDineIn.saturday.push({
                id: uuidv4(),
                start_time: item.start_time,
                end_time: item.end_time,
              })
            );
          }
        }
      }

      // set takeaway
      defaultValue.timer_for_takeaway =
        outletData.settings.takeaway_time_slot_setting === "no_time_slots"
          ? 15
          : outletData.settings.takeaway_time_slot_setting ===
            "same_time_slot_for_all_days"
          ? 16
          : outletData.settings.takeaway_time_slot_setting ===
            "different_time_slots_for_different_days"
          ? 17
          : 15;
      if (
        outletData.settings.takeaway_time_slot_setting ===
        "same_time_slot_for_all_days"
      ) {
        let formatedTimeSlotInfoTakeaway = formatedData(
          outletData.settings.takeaway_schedule
        );
        let fieldData = [];
        formatedTimeSlotInfoTakeaway &&
          formatedTimeSlotInfoTakeaway[1] &&
          formatedTimeSlotInfoTakeaway[1].map((item, index) => {
            fieldData.push({
              id: uuid(),
              start_time: item.start_time,
              end_time: item.end_time,
            });
          });
        defaultValue.timeForTakeAway = fieldData;
      }
      defaultValue.weakTakeAway = {
        sunday: [],
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: [],
      };

      if (
        outletData.settings.takeaway_time_slot_setting ===
        "different_time_slots_for_different_days"
      ) {
        let formatedTimeSlotInfoTakeaway = formatedData(
          outletData.settings.takeaway_schedule
        );
        for (const key in formatedTimeSlotInfoTakeaway) {
          console.log(key);
          if (key == 7) {
            formatedTimeSlotInfoTakeaway[7].map((item) =>
              defaultValue.weakTakeAway.sunday.push({
                id: uuidv4(),
                start_time: item.start_time,
                end_time: item.end_time,
              })
            );
          }
          if (key == 1) {
            formatedTimeSlotInfoTakeaway[1].forEach((item) =>
              defaultValue.weakTakeAway.monday.push({
                id: uuidv4(),
                start_time: item.start_time,
                end_time: item.end_time,
              })
            );
          }

          if (key == 2) {
            formatedTimeSlotInfoTakeaway[2].map((item) =>
              defaultValue.weakTakeAway.tuesday.push({
                id: uuidv4(),
                start_time: item.start_time,
                end_time: item.end_time,
              })
            );
          }

          if (key == 3) {
            formatedTimeSlotInfoTakeaway[3].map((item) =>
              defaultValue.weakTakeAway.wednesday.push({
                id: uuidv4(),
                start_time: item.start_time,
                end_time: item.end_time,
              })
            );
          }

          if (key == 4) {
            formatedTimeSlotInfoTakeaway[4].map((item) =>
              defaultValue.weakTakeAway.thursday.push({
                id: uuidv4(),
                start_time: item.start_time,
                end_time: item.end_time,
              })
            );
          }

          if (key == 5) {
            formatedTimeSlotInfoTakeaway[5].map((item) =>
              defaultValue.weakTakeAway.friday.push({
                id: uuidv4(),
                start_time: item.start_time,
                end_time: item.end_time,
              })
            );
          }

          if (key == 6) {
            formatedTimeSlotInfoTakeaway[6].map((item) =>
              defaultValue.weakTakeAway.saturday.push({
                id: uuidv4(),
                start_time: item.start_time,
                end_time: item.end_time,
              })
            );
          }
        }
      }

      //set outlet time
      defaultValue.time_slot =
        outletData.settings.time_slot_setting === "same_time_slot_for_all_days"
          ? "outlet_same_day"
          : outletData.settings.time_slot_setting ===
            "different_time_slots_for_different_days"
          ? "outlet_differant_day"
          : "outlet_same_day";
      if (
        outletData.settings.time_slot_setting === "same_time_slot_for_all_days"
      ) {
        let formatedTimeSlotInfo = formatedData(outletData.settings.schedules);
        let fieldData = [];
        formatedTimeSlotInfo &&
          formatedTimeSlotInfo[1] &&
          formatedTimeSlotInfo[1].map((item, index) => {
            fieldData.push({
              id: uuid(),
              start_time: item.start_time,
              end_time: item.end_time,
            });
          });
        defaultValue.time = fieldData;
      }
      defaultValue.weak = {
        sunday: [],
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: [],
      };
      if (
        outletData.settings.time_slot_setting ===
        "different_time_slots_for_different_days"
      ) {
        let formatedTimeSlotInfo = formatedData(outletData.settings.schedules);
        for (const key in formatedTimeSlotInfo) {
          if (key == 7) {
            formatedTimeSlotInfo[7].map((item) =>
              defaultValue.weak.sunday.push({
                id: uuidv4(),
                start_time: item.start_time,
                end_time: item.end_time,
              })
            );
            console.log(defaultValue.weak.sunday);
          }
          if (key == 1) {
            formatedTimeSlotInfo[1].forEach((item) =>
              defaultValue.weak.monday.push({
                id: uuidv4(),
                start_time: item.start_time,
                end_time: item.end_time,
              })
            );
          }

          if (key == 2) {
            formatedTimeSlotInfo[2].map((item) =>
              defaultValue.weak.tuesday.push({
                id: uuidv4(),
                start_time: item.start_time,
                end_time: item.end_time,
              })
            );
          }

          if (key == 3) {
            formatedTimeSlotInfo[3].map((item) =>
              defaultValue.weak.wednesday.push({
                id: uuidv4(),
                start_time: item.start_time,
                end_time: item.end_time,
              })
            );
          }

          if (key == 4) {
            formatedTimeSlotInfo[4].map((item) =>
              defaultValue.weak.thursday.push({
                id: uuidv4(),
                start_time: item.start_time,
                end_time: item.end_time,
              })
            );
          }

          if (key == 5) {
            formatedTimeSlotInfo[5].map((item) =>
              defaultValue.weak.friday.push({
                id: uuidv4(),
                start_time: item.start_time,
                end_time: item.end_time,
              })
            );
          }

          if (key == 6) {
            formatedTimeSlotInfo[6].map((item) =>
              defaultValue.weak.saturday.push({
                id: uuidv4(),
                start_time: item.start_time,
                end_time: item.end_time,
              })
            );
          }
        }
      }

      reset({
        ...defaultValue,
        timeForDelivery: defaultValue.timeForDelivery
          ? defaultValue.timeForDelivery
          : [],
        weakDelivery: defaultValue.weakDelivery
          ? defaultValue.weakDelivery
          : {
              sunday: [],
              monday: [],
              tuesday: [],
              wednesday: [],
              thursday: [],
              friday: [],
              saturday: [],
            },
        timeForDineIn: defaultValue.timeForDineIn
          ? defaultValue.timeForDineIn
          : [],
        weakDineIn: defaultValue.weakDineIn
          ? defaultValue.weakDineIn
          : {
              sunday: [],
              monday: [],
              tuesday: [],
              wednesday: [],
              thursday: [],
              friday: [],
              saturday: [],
            },
        timeForTakeAway: defaultValue.timeForTakeAway
          ? defaultValue.timeForTakeAway
          : [],
        weakTakeAway: defaultValue.weakTakeAway
          ? defaultValue.weakTakeAway
          : {
              sunday: [],
              monday: [],
              tuesday: [],
              wednesday: [],
              thursday: [],
              friday: [],
              saturday: [],
            },
        time: defaultValue.time ? defaultValue.time : [],
        weak: defaultValue.weak
          ? defaultValue.weak
          : {
              sunday: [],
              monday: [],
              tuesday: [],
              wednesday: [],
              thursday: [],
              friday: [],
              saturday: [],
            },
      });
    }
  }, [getOutletData]);
  const onSubmit = async (data) => {
    data.id = null;
    if (getOutletData) {
      data.id = getOutletData.outlet_id;
    }
    data.settings = {
      delivery: data.delivery ? 1 : 0,
      dine_in: data.dine_in ? 1 : 0,
      takeaway: data.takeaway ? 1 : 0,
    };
    //set delivery time slot
    let delivery_schedules;
    data.settings.delivery_time_slot_setting = null;
    if (data.delivery) {
      if (data.timer_for_delivery === 9 || data.timer_for_delivery === true) {
        data.settings.delivery_schedules = null;
        data.settings.delivery_time_slot_setting = "no_time_slots";
      } else if (data.timer_for_delivery === 10) {
        data.settings.delivery_time_slot_setting =
          "same_time_slot_for_all_days";

        delivery_schedules = [];

        let singleTimeSlot =
          data.timeForDelivery &&
          data.timeForDelivery.length > 0 &&
          data.timeForDelivery.filter((item, index) => {
            if (item.start_time !== undefined && item.end_time !== undefined) {
              return item;
            }
          });
        singleTimeSlot &&
          singleTimeSlot.length > 0 &&
          delivery_schedules.push({
            days_of_week: [1, 2, 3, 4, 5, 6, 7],
            time_slots: singleTimeSlot,
          });
        data.settings.delivery_schedules =
          delivery_schedules.length > 0 ? delivery_schedules : null;
        if (!data.settings.delivery_schedules) {
          data.settings.delivery_time_slot_setting = "no_time_slots";
        }
      } else {
        delivery_schedules = [];
        data.settings.delivery_time_slot_setting =
          "different_time_slots_for_different_days";

        let sunday =
          data.weakDelivery &&
          data.weakDelivery.sunday &&
          data.weakDelivery.sunday.filter((item, index) => {
            if (item.start_time !== undefined && item.end_time !== undefined) {
              return item;
            }
          });
        sunday &&
          sunday.length > 0 &&
          delivery_schedules.push({
            days_of_week: [7],
            time_slots: sunday,
          });

        let monday =
          data.weakDelivery &&
          data.weakDelivery.monday &&
          data.weakDelivery.monday.filter((item, index) => {
            if (item.start_time !== undefined && item.end_time !== undefined) {
              return item;
            }
          });
        monday &&
          monday.length > 0 &&
          delivery_schedules.push({
            days_of_week: [1],
            time_slots: monday,
          });

        let tuesday =
          data.weakDelivery &&
          data.weakDelivery.tuesday &&
          data.weakDelivery.tuesday.filter((item, index) => {
            if (item.start_time !== undefined && item.end_time !== undefined) {
              return item;
            }
          });
        tuesday &&
          tuesday.length > 0 &&
          delivery_schedules.push({
            days_of_week: [2],
            time_slots: tuesday,
          });

        let wednesday =
          data.weakDelivery &&
          data.weakDelivery.wednesday &&
          data.weakDelivery.wednesday.filter((item, index) => {
            if (item.start_time !== undefined && item.end_time !== undefined) {
              return item;
            }
          });
        wednesday &&
          wednesday.length > 0 &&
          delivery_schedules.push({
            days_of_week: [3],
            time_slots: wednesday,
          });

        let thursday =
          data.weakDelivery &&
          data.weakDelivery.thursday &&
          data.weakDelivery.thursday.filter((item, index) => {
            if (item.start_time !== undefined && item.end_time !== undefined) {
              return item;
            }
          });
        thursday &&
          thursday.length > 0 &&
          delivery_schedules.push({
            days_of_week: [4],
            time_slots: thursday,
          });

        let friday =
          data.weakDelivery &&
          data.weakDelivery.friday &&
          data.weakDelivery.friday.filter((item, index) => {
            if (item.start_time !== undefined && item.end_time !== undefined) {
              return item;
            }
          });
        friday &&
          friday.length > 0 &&
          delivery_schedules.push({
            days_of_week: [5],
            time_slots: friday,
          });

        let saturday =
          data.weakDelivery &&
          data.weakDelivery.saturday &&
          data.weakDelivery.saturday.filter((item, index) => {
            if (item.start_time !== undefined && item.end_time !== undefined) {
              return item;
            }
          });
        saturday &&
          saturday.length > 0 &&
          delivery_schedules.push({
            days_of_week: [6],
            time_slots: saturday,
          });
        data.settings.delivery_schedules =
          delivery_schedules.length > 0 ? delivery_schedules : null;
        if (!data.settings.delivery_schedules) {
          data.settings.delivery_time_slot_setting = "no_time_slots";
        }
      }
    }

    // set dining time slot
    data.settings.dine_in_time_slot_setting = null;
    let dine_in_schedules;
    if (data.dine_in) {
      if (data.timer_for_dine_in === 12 || data.timer_for_dine_in === true) {
        data.settings.dine_in_schedules = null;
        data.settings.dine_in_time_slot_setting = "no_time_slots";
      } else if (data.timer_for_dine_in === 13) {
        data.settings.dine_in_time_slot_setting = "same_time_slot_for_all_days";

        dine_in_schedules = [];

        let singleTimeSlot =
          data.timeForDineIn &&
          data.timeForDineIn.length > 0 &&
          data.timeForDineIn.filter((item, index) => {
            if (item.start_time !== undefined && item.end_time !== undefined) {
              return item;
            }
          });
        singleTimeSlot &&
          singleTimeSlot.length > 0 &&
          dine_in_schedules.push({
            days_of_week: [1, 2, 3, 4, 5, 6, 7],
            time_slots: singleTimeSlot,
          });

        data.settings.dine_in_schedules =
          dine_in_schedules.length > 0 ? dine_in_schedules : null;
        if (!data.settings.dine_in_schedules) {
          data.settings.dine_in_time_slot_setting = "no_time_slots";
        }
      } else {
        dine_in_schedules = [];
        data.settings.dine_in_time_slot_setting =
          "different_time_slots_for_different_days";

        let sunday =
          data.weakDineIn &&
          data.weakDineIn.sunday &&
          data.weakDineIn.sunday.filter((item, index) => {
            if (item.start_time !== undefined && item.end_time !== undefined) {
              return item;
            }
          });
        sunday &&
          sunday.length > 0 &&
          dine_in_schedules.push({
            days_of_week: [7],
            time_slots: sunday,
          });
        let monday =
          data.weakDineIn &&
          data.weakDineIn.monday &&
          data.weakDineIn.monday.filter((item, index) => {
            if (item.start_time !== undefined && item.end_time !== undefined) {
              return item;
            }
          });
        monday &&
          monday.length > 0 &&
          dine_in_schedules.push({
            days_of_week: [1],
            time_slots: monday,
          });

        let tuesday =
          data.weakDineIn &&
          data.weakDineIn.tuesday &&
          data.weakDineIn.tuesday.filter((item, index) => {
            if (item.start_time !== undefined && item.end_time !== undefined) {
              return item;
            }
          });
        tuesday &&
          tuesday.length > 0 &&
          dine_in_schedules.push({
            days_of_week: [2],
            time_slots: tuesday,
          });

        let wednesday =
          data.weakDineIn &&
          data.weakDineIn.wednesday &&
          data.weakDineIn.wednesday.filter((item, index) => {
            if (item.start_time !== undefined && item.end_time !== undefined) {
              return item;
            }
          });
        wednesday &&
          wednesday.length > 0 &&
          dine_in_schedules.push({
            days_of_week: [3],
            time_slots: wednesday,
          });

        let thursday =
          data.weakDineIn &&
          data.weakDineIn.thursday &&
          data.weakDineIn.thursday.filter((item, index) => {
            if (item.start_time !== undefined && item.end_time !== undefined) {
              return item;
            }
          });
        thursday &&
          thursday.length > 0 &&
          dine_in_schedules.push({
            days_of_week: [4],
            time_slots: thursday,
          });

        let friday =
          data.weakDineIn &&
          data.weakDineIn.friday &&
          data.weakDineIn.friday.filter((item, index) => {
            if (item.start_time !== undefined && item.end_time !== undefined) {
              return item;
            }
          });
        friday &&
          friday.length > 0 &&
          dine_in_schedules.push({
            days_of_week: [5],
            time_slots: friday,
          });

        let saturday =
          data.weakDineIn &&
          data.weakDineIn.saturday &&
          data.weakDineIn.saturday.filter((item, index) => {
            if (item.start_time !== undefined && item.end_time !== undefined) {
              return item;
            }
          });
        saturday &&
          saturday.length > 0 &&
          dine_in_schedules.push({
            days_of_week: [6],
            time_slots: saturday,
          });
        data.settings.dine_in_schedules =
          dine_in_schedules.length > 0 ? dine_in_schedules : null;
        if (!data.settings.dine_in_schedules) {
          data.settings.dine_in_time_slot_setting = "no_time_slots";
        }
      }
    }

    //set takeaway time slot

    let takeaway_schedules;
    data.settings.takeaway_time_slot_setting = null;
    if (data.takeaway) {
      if (data.timer_for_takeaway === 15 || data.timer_for_takeaway === true) {
        data.settings.takeaway_schedules = null;
        data.settings.takeaway_time_slot_setting = "no_time_slots";
      } else if (data.timer_for_takeaway === 16) {
        data.settings.takeaway_time_slot_setting =
          "same_time_slot_for_all_days";

        takeaway_schedules = [];

        let singleTimeSlot =
          data.timeForTakeAway &&
          data.timeForTakeAway.length > 0 &&
          data.timeForTakeAway.filter((item, index) => {
            if (item.start_time !== undefined && item.end_time !== undefined) {
              return item;
            }
          });
        singleTimeSlot &&
          singleTimeSlot.length > 0 &&
          takeaway_schedules.push({
            days_of_week: [1, 2, 3, 4, 5, 6, 7],
            time_slots: singleTimeSlot,
          });
        data.settings.takeaway_schedules =
          takeaway_schedules.length > 0 ? takeaway_schedules : null;
        if (!data.settings.takeaway_schedules) {
          data.settings.takeaway_time_slot_setting = "no_time_slots";
        }
      } else {
        takeaway_schedules = [];
        data.settings.takeaway_time_slot_setting =
          "different_time_slots_for_different_days";

        let sunday =
          data.weakTakeAway &&
          data.weakTakeAway.sunday &&
          data.weakTakeAway.sunday.filter((item, index) => {
            if (item.start_time !== undefined && item.end_time !== undefined) {
              return item;
            }
          });
        sunday &&
          sunday.length > 0 &&
          takeaway_schedules.push({
            days_of_week: [7],
            time_slots: sunday,
          });
        let monday =
          data.weakTakeAway &&
          data.weakTakeAway.monday &&
          data.weakTakeAway.monday.filter((item, index) => {
            if (item.start_time !== undefined && item.end_time !== undefined) {
              return item;
            }
          });
        monday &&
          monday.length > 0 &&
          takeaway_schedules.push({
            days_of_week: [1],
            time_slots: monday,
          });

        let tuesday =
          data.weakTakeAway &&
          data.weakTakeAway.tuesday &&
          data.weakTakeAway.tuesday.filter((item, index) => {
            if (item.start_time !== undefined && item.end_time !== undefined) {
              return item;
            }
          });
        tuesday &&
          tuesday.length > 0 &&
          takeaway_schedules.push({
            days_of_week: [2],
            time_slots: tuesday,
          });

        let wednesday =
          data.weakTakeAway &&
          data.weakTakeAway.wednesday &&
          data.weakTakeAway.wednesday.filter((item, index) => {
            if (item.start_time !== undefined && item.end_time !== undefined) {
              return item;
            }
          });
        wednesday &&
          wednesday.length > 0 &&
          takeaway_schedules.push({
            days_of_week: [3],
            time_slots: wednesday,
          });

        let thursday =
          data.weakTakeAway &&
          data.weakTakeAway.thursday &&
          data.weakTakeAway.thursday.filter((item, index) => {
            if (item.start_time !== undefined && item.end_time !== undefined) {
              return item;
            }
          });
        thursday &&
          thursday.length > 0 &&
          takeaway_schedules.push({
            days_of_week: [4],
            time_slots: thursday,
          });

        let friday =
          data.weakTakeAway &&
          data.weakTakeAway.friday &&
          data.weakTakeAway.friday.filter((item, index) => {
            if (item.start_time !== undefined && item.end_time !== undefined) {
              return item;
            }
          });
        friday &&
          friday.length > 0 &&
          takeaway_schedules.push({
            days_of_week: [5],
            time_slots: friday,
          });

        let saturday =
          data.weakTakeAway &&
          data.weakTakeAway.saturday &&
          data.weakTakeAway.saturday.filter((item, index) => {
            if (item.start_time !== undefined && item.end_time !== undefined) {
              return item;
            }
          });
        saturday &&
          saturday.length > 0 &&
          takeaway_schedules.push({
            days_of_week: [6],
            time_slots: saturday,
          });
        data.settings.takeaway_schedules =
          takeaway_schedules.length > 0 ? takeaway_schedules : null;
        if (!data.settings.takeaway_schedules) {
          data.settings.takeaway_time_slot_setting = "no_time_slots";
        }
      }
    }
    // set outlet time
    let schedules;
    console.log("data.time", data.time);
    if (data.time_slot === "outlet_same_day") {
      data.settings.time_slot_setting = "same_time_slot_for_all_days";
      schedules = [];

      let singleTimeSlot =
        data.time &&
        data.time.length > 0 &&
        data.time.filter((item, index) => {
          if (item.start_time !== undefined && item.end_time !== undefined) {
            return item;
          }
        });
      singleTimeSlot &&
        singleTimeSlot.length > 0 &&
        schedules.push({
          days_of_week: [1, 2, 3, 4, 5, 6, 7],
          time_slots: singleTimeSlot,
        });

      data.settings.schedules = schedules.length > 0 ? schedules : null;
      if (!data.settings.schedules) {
        setTimeSlotError(true);
        return false;
      }
    } else if (data.time_slot === "outlet_differant_day") {
      schedules = [];
      data.settings.time_slot_setting =
        "different_time_slots_for_different_days";

      let sunday =
        data.weak &&
        data.weak.sunday &&
        data.weak.sunday.filter((item, index) => {
          if (item.start_time !== undefined && item.end_time !== undefined) {
            return item;
          }
        });

      sunday &&
        sunday.length > 0 &&
        schedules.push({
          days_of_week: [7],
          time_slots: sunday,
        });
      let monday =
        data.weak &&
        data.weak.monday &&
        data.weak.monday.filter((item, index) => {
          if (item.start_time !== undefined && item.end_time !== undefined) {
            return item;
          }
        });
      monday &&
        monday.length > 0 &&
        schedules.push({
          days_of_week: [1],
          time_slots: monday,
        });
      let tuesday =
        data.weak &&
        data.weak.tuesday &&
        data.weak.tuesday.filter((item, index) => {
          if (item.start_time !== undefined && item.end_time !== undefined) {
            return item;
          }
        });
      tuesday &&
        tuesday.length > 0 &&
        schedules.push({
          days_of_week: [2],
          time_slots: tuesday,
        });

      let wednesday =
        data.weak &&
        data.weak.wednesday &&
        data.weak.wednesday.filter((item, index) => {
          if (item.start_time !== undefined && item.end_time !== undefined) {
            return item;
          }
        });
      wednesday &&
        wednesday.length > 0 &&
        schedules.push({
          days_of_week: [3],
          time_slots: wednesday,
        });

      let thursday =
        data.weak &&
        data.weak.thursday &&
        data.weak.thursday.filter((item, index) => {
          if (item.start_time !== undefined && item.end_time !== undefined) {
            return item;
          }
        });
      thursday &&
        thursday.length > 0 &&
        schedules.push({
          days_of_week: [4],
          time_slots: thursday,
        });

      let friday =
        data.weak &&
        data.weak.friday &&
        data.weak.friday.filter((item, index) => {
          if (item.start_time !== undefined && item.end_time !== undefined) {
            return item;
          }
        });
      friday &&
        friday.length > 0 &&
        schedules.push({
          days_of_week: [5],
          time_slots: friday,
        });

      let saturday =
        data.weak &&
        data.weak.saturday &&
        data.weak.saturday.filter((item, index) => {
          if (item.start_time !== undefined && item.end_time !== undefined) {
            return item;
          }
        });
      saturday &&
        saturday.length > 0 &&
        schedules.push({
          days_of_week: [6],
          time_slots: saturday,
        });

      data.settings.schedules = schedules.length > 0 ? schedules : null;
      if (!data.settings.schedules) {
        data.settings.time_slot_setting = "no_time_slots";
      }
    } else {
      data.settings.schedules = null;
      data.settings.time_slot_setting = "no_time_slots";
    }
    console.log("timing", JSON.stringify(data));
    setLoader(true);
    // api call
    const timing = await fetch(
      `${baseApiUrl}restaurants/${restaurantID}/outlets/${data.id}?section=timings`,
      {
        method: "put",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    const timingJson = await timing.json();

    if (timingJson.success) {
      alert("successfully edited");
    } else {
      alert("Please try later");
    }
    setLoader(false);
  };
  return (
    <EuiForm
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      style={{ marginTop: 20 }}
    >
      <EuiFormRow fullWidth={true} label="Module options for the restaurant :-">
        <>
          <EuiFlexGroup alignItems="center">
            <EuiFlexItem grow={false}>
              <SwitchField
                name="delivery"
                defaultValue={false}
                control={control}
                setValue={setValue}
              />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiText>Delivery</EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>
          {watch("delivery") === true && (
            <>
              <EuiFlexGroup alignItems="center" style={{ marginTop: "10px" }}>
                <EuiFlexItem grow={false}>
                  <EuiFormRow
                    isInvalid={errors.timer_for_banner}
                    fullWidth={true}
                  >
                    <Controller
                      render={({ onChange }) => (
                        <EuiRadioGroup
                          options={deliveryTimeSlot}
                          idSelected={
                            watch("timer_for_delivery") === 9
                              ? 9
                              : watch("timer_for_delivery") === 10
                              ? 10
                              : watch("timer_for_delivery") === 11
                              ? 11
                              : 9
                          }
                          onChange={(id) =>
                            setValue(
                              "timer_for_delivery",
                              id === 9 ? 9 : id === 10 ? 10 : id === 11 ? 11 : 9
                            )
                          }
                          legend={{
                            children: (
                              <span> Decide this delivery lifetime </span>
                            ),
                          }}
                        />
                      )}
                      name="timer_for_delivery"
                      defaultValue={9}
                      control={control}
                      fullWidth={true}
                    />
                  </EuiFormRow>
                </EuiFlexItem>
              </EuiFlexGroup>
              <EuiFlexGroup style={{ width: "90%" }}>
                <EuiFlexItem>
                  {watch("timer_for_delivery") === 10 && (
                    <>
                      <EuiFlexGroup
                        style={{
                          background: "#9895CA",
                          marginTop: "20px",
                          marginBottom: "30px",
                        }}
                      >
                        <EuiFlexItem grow={2}>Days</EuiFlexItem>
                        <EuiFlexItem grow={4}>Start Time</EuiFlexItem>
                        <EuiFlexItem grow={4}>End Time</EuiFlexItem>
                      </EuiFlexGroup>
                      <TimeSlot
                        control={control}
                        setValue={setValue}
                        name="timeForDelivery"
                        errors={errors}
                        watch={watch}
                      />
                    </>
                  )}
                  {watch("timer_for_delivery") === 11 && (
                    <>
                      <EuiFlexGroup
                        style={{
                          background: "#9895CA",
                          marginTop: "20px",
                          marginBottom: "30px",
                        }}
                        columns={3}
                      >
                        <EuiFlexItem grow={2}>Days</EuiFlexItem>
                        <EuiFlexItem grow={4}>Start Time</EuiFlexItem>
                        <EuiFlexItem grow={4}>End Time</EuiFlexItem>
                      </EuiFlexGroup>

                      <EuiFlexGroup
                        style={{
                          marginBottom: 30,
                          paddingBottom: 40,
                          borderBottom: "1px solid black",
                        }}
                      >
                        <EuiFlexGroup
                          style={{
                            flexDirection: "column",
                            textAlign: "center",
                          }}
                        >
                          <EuiFlexItem
                            style={{
                              textAlign: "left",
                              marginLeft: "3%",
                            }}
                          >
                            <strong> Sunday </strong>
                          </EuiFlexItem>

                          <TimeSlot
                            control={control}
                            setValue={setValue}
                            name={`weakDelivery.sunday`}
                            errors={errors}
                            watch={watch}
                          />
                        </EuiFlexGroup>
                      </EuiFlexGroup>

                      <EuiFlexGroup
                        style={{
                          marginBottom: 30,
                          paddingBottom: 40,
                          borderBottom: "1px solid black",
                        }}
                      >
                        <EuiFlexGroup
                          style={{
                            flexDirection: "column",
                            textAlign: "center",
                          }}
                        >
                          <EuiFlexItem
                            style={{
                              textAlign: "left",
                              marginLeft: "3%",
                            }}
                          >
                            {" "}
                            <strong> Monday </strong>{" "}
                          </EuiFlexItem>
                          <TimeSlot
                            control={control}
                            setValue={setValue}
                            name={`weakDelivery.monday`}
                            errors={errors}
                            watch={watch}
                          />
                        </EuiFlexGroup>
                      </EuiFlexGroup>

                      <EuiFlexGroup
                        style={{
                          marginBottom: 30,
                          paddingBottom: 40,
                          borderBottom: "1px solid black",
                        }}
                      >
                        <EuiFlexGroup
                          style={{
                            flexDirection: "column",
                            textAlign: "center",
                          }}
                        >
                          <EuiFlexItem
                            style={{
                              textAlign: "left",
                              marginLeft: "3%",
                            }}
                          >
                            {" "}
                            <strong> Tuesday </strong>{" "}
                          </EuiFlexItem>
                          <TimeSlot
                            control={control}
                            setValue={setValue}
                            name={`weakDelivery.tuesday`}
                            errors={errors}
                            watch={watch}
                          />
                        </EuiFlexGroup>
                      </EuiFlexGroup>

                      <EuiFlexGroup
                        style={{
                          marginBottom: 30,
                          paddingBottom: 40,
                          borderBottom: "1px solid black",
                        }}
                      >
                        <EuiFlexGroup
                          style={{
                            flexDirection: "column",
                            textAlign: "center",
                          }}
                        >
                          <EuiFlexItem
                            style={{
                              textAlign: "left",
                              marginLeft: "3%",
                            }}
                          >
                            {" "}
                            <strong> Wednesday </strong>{" "}
                          </EuiFlexItem>
                          <TimeSlot
                            control={control}
                            setValue={setValue}
                            name={`weakDelivery.wednesday`}
                            errors={errors}
                            watch={watch}
                          />
                        </EuiFlexGroup>
                      </EuiFlexGroup>

                      <EuiFlexGroup
                        style={{
                          marginBottom: 30,
                          paddingBottom: 40,
                          borderBottom: "1px solid black",
                          justifyContent: "center",
                        }}
                      >
                        <EuiFlexGroup
                          style={{
                            flexDirection: "column",
                            textAlign: "center",
                          }}
                        >
                          <EuiFlexItem
                            style={{
                              textAlign: "left",
                              marginLeft: "3%",
                            }}
                          >
                            {" "}
                            <strong> Thursday </strong>{" "}
                          </EuiFlexItem>
                          <TimeSlot
                            control={control}
                            setValue={setValue}
                            name={`weakDelivery.thursday`}
                            errors={errors}
                            watch={watch}
                          />
                        </EuiFlexGroup>
                      </EuiFlexGroup>

                      <EuiFlexGroup
                        style={{
                          marginBottom: 30,
                          paddingBottom: 40,
                          borderBottom: "1px solid black",
                        }}
                      >
                        <EuiFlexGroup
                          style={{
                            flexDirection: "column",
                            textAlign: "center",
                          }}
                        >
                          <EuiFlexItem
                            style={{
                              textAlign: "left",
                              marginLeft: "3%",
                            }}
                          >
                            {" "}
                            <strong> Friday </strong>{" "}
                          </EuiFlexItem>
                          <TimeSlot
                            control={control}
                            setValue={setValue}
                            name={`weakDelivery.friday`}
                            errors={errors}
                            watch={watch}
                          />
                        </EuiFlexGroup>
                      </EuiFlexGroup>

                      <EuiFlexGroup
                        style={{
                          marginBottom: 30,
                          paddingBottom: 40,
                          borderBottom: "1px solid black",
                        }}
                      >
                        <EuiFlexGroup
                          style={{
                            flexDirection: "column",
                            textAlign: "center",
                          }}
                        >
                          <EuiFlexItem
                            style={{
                              textAlign: "left",
                              marginLeft: "3%",
                            }}
                          >
                            {" "}
                            <strong> Saturday </strong>{" "}
                          </EuiFlexItem>
                          <TimeSlot
                            control={control}
                            setValue={setValue}
                            name={`weakDelivery.saturday`}
                            errors={errors}
                            watch={watch}
                          />
                        </EuiFlexGroup>
                      </EuiFlexGroup>
                    </>
                  )}
                </EuiFlexItem>
              </EuiFlexGroup>
            </>
          )}

          <EuiFlexGroup alignItems="center">
            <EuiFlexItem grow={false}>
              <SwitchField
                name="dine_in"
                defaultValue={false}
                control={control}
                setValue={setValue}
              />
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
              <EuiText>Dine-in</EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>
          {watch("dine_in") === true && (
            <>
              <EuiFlexGroup alignItems="center" style={{ marginTop: "10px" }}>
                <EuiFlexItem grow={false}>
                  <EuiFormRow
                    isInvalid={errors.timer_for_banner}
                    fullWidth={true}
                  >
                    <Controller
                      render={({ onChange }) => (
                        <EuiRadioGroup
                          options={dineInTimeSlot}
                          idSelected={
                            watch("timer_for_dine_in") === 12
                              ? 12
                              : watch("timer_for_dine_in") === 13
                              ? 13
                              : watch("timer_for_dine_in") === 14
                              ? 14
                              : 12
                          }
                          onChange={(id) =>
                            setValue(
                              "timer_for_dine_in",
                              id === 12
                                ? 12
                                : id === 13
                                ? 13
                                : id === 14
                                ? 14
                                : 12
                            )
                          }
                          legend={{
                            children: (
                              <span> Decide this dine-in lifetime </span>
                            ),
                          }}
                        />
                      )}
                      name="timer_for_dine_in"
                      defaultValue={12}
                      control={control}
                      fullWidth={true}
                    />
                  </EuiFormRow>
                </EuiFlexItem>
              </EuiFlexGroup>
              <EuiFlexGroup style={{ width: "90%" }}>
                <EuiFlexItem>
                  {watch("timer_for_dine_in") === 13 && (
                    <>
                      <EuiFlexGroup
                        style={{
                          background: "#9895CA",
                          marginTop: "20px",
                          marginBottom: "30px",
                        }}
                      >
                        <EuiFlexItem grow={2}>Days</EuiFlexItem>
                        <EuiFlexItem grow={4}>Start Time</EuiFlexItem>
                        <EuiFlexItem grow={4}>End Time</EuiFlexItem>
                      </EuiFlexGroup>
                      <TimeSlot
                        control={control}
                        setValue={setValue}
                        name="timeForDineIn"
                        errors={errors}
                        watch={watch}
                      />
                    </>
                  )}
                  {watch("timer_for_dine_in") === 14 && (
                    <>
                      <EuiFlexGroup
                        style={{
                          background: "#9895CA",
                          marginTop: "20px",
                          marginBottom: "30px",
                        }}
                        columns={3}
                      >
                        <EuiFlexItem grow={2}>Days</EuiFlexItem>
                        <EuiFlexItem grow={4}>Start Time</EuiFlexItem>
                        <EuiFlexItem grow={4}>End Time</EuiFlexItem>
                      </EuiFlexGroup>

                      <EuiFlexGroup
                        style={{
                          marginBottom: 30,
                          paddingBottom: 40,
                          borderBottom: "1px solid black",
                        }}
                      >
                        <EuiFlexGroup
                          style={{
                            flexDirection: "column",
                            textAlign: "center",
                          }}
                        >
                          <EuiFlexItem
                            style={{
                              textAlign: "left",
                              marginLeft: "3%",
                            }}
                          >
                            <strong> Sunday </strong>
                          </EuiFlexItem>

                          <TimeSlot
                            control={control}
                            setValue={setValue}
                            name={`weakDineIn.sunday`}
                            errors={errors}
                            watch={watch}
                          />
                        </EuiFlexGroup>
                      </EuiFlexGroup>

                      <EuiFlexGroup
                        style={{
                          marginBottom: 30,
                          paddingBottom: 40,
                          borderBottom: "1px solid black",
                        }}
                      >
                        <EuiFlexGroup
                          style={{
                            flexDirection: "column",
                            textAlign: "center",
                          }}
                        >
                          <EuiFlexItem
                            style={{
                              textAlign: "left",
                              marginLeft: "3%",
                            }}
                          >
                            {" "}
                            <strong> Monday </strong>{" "}
                          </EuiFlexItem>
                          <TimeSlot
                            control={control}
                            setValue={setValue}
                            name={`weakDineIn.monday`}
                            errors={errors}
                            watch={watch}
                          />
                        </EuiFlexGroup>
                      </EuiFlexGroup>

                      <EuiFlexGroup
                        style={{
                          marginBottom: 30,
                          paddingBottom: 40,
                          borderBottom: "1px solid black",
                        }}
                      >
                        <EuiFlexGroup
                          style={{
                            flexDirection: "column",
                            textAlign: "center",
                          }}
                        >
                          <EuiFlexItem
                            style={{
                              textAlign: "left",
                              marginLeft: "3%",
                            }}
                          >
                            {" "}
                            <strong> Tuesday </strong>{" "}
                          </EuiFlexItem>
                          <TimeSlot
                            control={control}
                            setValue={setValue}
                            name={`weakDineIn.tuesday`}
                            errors={errors}
                            watch={watch}
                          />
                        </EuiFlexGroup>
                      </EuiFlexGroup>

                      <EuiFlexGroup
                        style={{
                          marginBottom: 30,
                          paddingBottom: 40,
                          borderBottom: "1px solid black",
                        }}
                      >
                        <EuiFlexGroup
                          style={{
                            flexDirection: "column",
                            textAlign: "center",
                          }}
                        >
                          <EuiFlexItem
                            style={{
                              textAlign: "left",
                              marginLeft: "3%",
                            }}
                          >
                            {" "}
                            <strong> Wednesday </strong>{" "}
                          </EuiFlexItem>
                          <TimeSlot
                            control={control}
                            setValue={setValue}
                            name={`weakDineIn.wednesday`}
                            errors={errors}
                            watch={watch}
                          />
                        </EuiFlexGroup>
                      </EuiFlexGroup>
                      <EuiFlexGroup
                        style={{
                          marginBottom: 30,
                          paddingBottom: 40,
                          borderBottom: "1px solid black",
                          justifyContent: "center",
                        }}
                      >
                        <EuiFlexGroup
                          style={{
                            flexDirection: "column",
                            textAlign: "center",
                          }}
                        >
                          <EuiFlexItem
                            style={{
                              textAlign: "left",
                              marginLeft: "3%",
                            }}
                          >
                            {" "}
                            <strong> Thursday </strong>{" "}
                          </EuiFlexItem>
                          <TimeSlot
                            control={control}
                            setValue={setValue}
                            name={`weakDineIn.thursday`}
                            errors={errors}
                            watch={watch}
                          />
                        </EuiFlexGroup>
                      </EuiFlexGroup>

                      <EuiFlexGroup
                        style={{
                          marginBottom: 30,
                          paddingBottom: 40,
                          borderBottom: "1px solid black",
                        }}
                      >
                        <EuiFlexGroup
                          style={{
                            flexDirection: "column",
                            textAlign: "center",
                          }}
                        >
                          <EuiFlexItem
                            style={{
                              textAlign: "left",
                              marginLeft: "3%",
                            }}
                          >
                            {" "}
                            <strong> Friday </strong>{" "}
                          </EuiFlexItem>
                          <TimeSlot
                            control={control}
                            setValue={setValue}
                            name={`weakDineIn.friday`}
                            errors={errors}
                            watch={watch}
                          />
                        </EuiFlexGroup>
                      </EuiFlexGroup>

                      <EuiFlexGroup
                        style={{
                          marginBottom: 30,
                          paddingBottom: 40,
                          borderBottom: "1px solid black",
                        }}
                      >
                        <EuiFlexGroup
                          style={{
                            flexDirection: "column",
                            textAlign: "center",
                          }}
                        >
                          <EuiFlexItem
                            style={{
                              textAlign: "left",
                              marginLeft: "3%",
                            }}
                          >
                            {" "}
                            <strong> Saturday </strong>{" "}
                          </EuiFlexItem>
                          <TimeSlot
                            control={control}
                            setValue={setValue}
                            name={`weakDineIn.saturday`}
                            errors={errors}
                            watch={watch}
                          />
                        </EuiFlexGroup>
                      </EuiFlexGroup>
                    </>
                  )}
                </EuiFlexItem>
              </EuiFlexGroup>
            </>
          )}

          <EuiFlexGroup alignItems="center">
            <EuiFlexItem grow={false}>
              <SwitchField
                name="takeaway"
                defaultValue={false}
                control={control}
                setValue={setValue}
              />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiText>Pick-up</EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>
          {watch("takeaway") === true && (
            <>
              <EuiFlexGroup alignItems="center" style={{ marginTop: "10px" }}>
                <EuiFlexItem grow={false}>
                  <EuiFormRow
                    isInvalid={errors.timer_for_banner}
                    fullWidth={true}
                  >
                    <Controller
                      render={({ onChange }) => (
                        <EuiRadioGroup
                          options={pickUpTimeSlot}
                          idSelected={
                            watch("timer_for_takeaway") === 15
                              ? 15
                              : watch("timer_for_takeaway") === 16
                              ? 16
                              : watch("timer_for_takeaway") === 17
                              ? 17
                              : 15
                          }
                          onChange={(id) =>
                            setValue(
                              "timer_for_takeaway",
                              id === 15
                                ? 15
                                : id === 16
                                ? 16
                                : id === 17
                                ? 17
                                : 15
                            )
                          }
                          legend={{
                            children: (
                              <span> Decide this takeaway lifetime </span>
                            ),
                          }}
                        />
                      )}
                      name="timer_for_takeaway"
                      defaultValue={15}
                      control={control}
                      fullWidth={true}
                    />
                  </EuiFormRow>
                </EuiFlexItem>
              </EuiFlexGroup>
              <EuiFlexGroup style={{ width: "90%" }}>
                <EuiFlexItem>
                  {watch("timer_for_takeaway") === 16 && (
                    <>
                      <EuiFlexGroup
                        style={{
                          background: "#9895CA",
                          marginTop: "20px",
                          marginBottom: "30px",
                        }}
                      >
                        <EuiFlexItem grow={2}>Days</EuiFlexItem>
                        <EuiFlexItem grow={4}>Start Time</EuiFlexItem>
                        <EuiFlexItem grow={4}>End Time</EuiFlexItem>
                      </EuiFlexGroup>
                      <TimeSlot
                        control={control}
                        setValue={setValue}
                        name="timeForTakeAway"
                        errors={errors}
                        watch={watch}
                      />
                    </>
                  )}
                  {watch("timer_for_takeaway") === 17 && (
                    <>
                      <EuiFlexGroup
                        style={{
                          background: "#9895CA",
                          marginTop: "20px",
                          marginBottom: "30px",
                        }}
                        columns={3}
                      >
                        <EuiFlexItem grow={2}>Days</EuiFlexItem>
                        <EuiFlexItem grow={4}>Start Time</EuiFlexItem>
                        <EuiFlexItem grow={4}>End Time</EuiFlexItem>
                      </EuiFlexGroup>

                      <EuiFlexGroup
                        style={{
                          marginBottom: 30,
                          paddingBottom: 40,
                          borderBottom: "1px solid black",
                        }}
                      >
                        <EuiFlexGroup
                          style={{
                            flexDirection: "column",
                            textAlign: "center",
                          }}
                        >
                          <EuiFlexItem
                            style={{
                              textAlign: "left",
                              marginLeft: "3%",
                            }}
                          >
                            <strong> Sunday </strong>
                          </EuiFlexItem>

                          <TimeSlot
                            control={control}
                            setValue={setValue}
                            name={`weakTakeAway.sunday`}
                            errors={errors}
                            watch={watch}
                          />
                        </EuiFlexGroup>
                      </EuiFlexGroup>

                      <EuiFlexGroup
                        style={{
                          marginBottom: 30,
                          paddingBottom: 40,
                          borderBottom: "1px solid black",
                        }}
                      >
                        <EuiFlexGroup
                          style={{
                            flexDirection: "column",
                            textAlign: "center",
                          }}
                        >
                          <EuiFlexItem
                            style={{
                              textAlign: "left",
                              marginLeft: "3%",
                            }}
                          >
                            {" "}
                            <strong> Monday </strong>{" "}
                          </EuiFlexItem>
                          <TimeSlot
                            control={control}
                            setValue={setValue}
                            name={`weakTakeAway.monday`}
                            errors={errors}
                            watch={watch}
                          />
                        </EuiFlexGroup>
                      </EuiFlexGroup>

                      <EuiFlexGroup
                        style={{
                          marginBottom: 30,
                          paddingBottom: 40,
                          borderBottom: "1px solid black",
                        }}
                      >
                        <EuiFlexGroup
                          style={{
                            flexDirection: "column",
                            textAlign: "center",
                          }}
                        >
                          <EuiFlexItem
                            style={{
                              textAlign: "left",
                              marginLeft: "3%",
                            }}
                          >
                            {" "}
                            <strong> Tuesday </strong>{" "}
                          </EuiFlexItem>
                          <TimeSlot
                            control={control}
                            setValue={setValue}
                            name={`weakTakeAway.tuesday`}
                            errors={errors}
                            watch={watch}
                          />
                        </EuiFlexGroup>
                      </EuiFlexGroup>

                      <EuiFlexGroup
                        style={{
                          marginBottom: 30,
                          paddingBottom: 40,
                          borderBottom: "1px solid black",
                        }}
                      >
                        <EuiFlexGroup
                          style={{
                            flexDirection: "column",
                            textAlign: "center",
                          }}
                        >
                          <EuiFlexItem
                            style={{
                              textAlign: "left",
                              marginLeft: "3%",
                            }}
                          >
                            {" "}
                            <strong> Wednesday </strong>{" "}
                          </EuiFlexItem>
                          <TimeSlot
                            control={control}
                            setValue={setValue}
                            name={`weakTakeAway.wednesday`}
                            errors={errors}
                            watch={watch}
                          />
                        </EuiFlexGroup>
                      </EuiFlexGroup>

                      <EuiFlexGroup
                        style={{
                          marginBottom: 30,
                          paddingBottom: 40,
                          borderBottom: "1px solid black",
                          justifyContent: "center",
                        }}
                      >
                        <EuiFlexGroup
                          style={{
                            flexDirection: "column",
                            textAlign: "center",
                          }}
                        >
                          <EuiFlexItem
                            style={{
                              textAlign: "left",
                              marginLeft: "3%",
                            }}
                          >
                            {" "}
                            <strong> Thursday </strong>{" "}
                          </EuiFlexItem>
                          <TimeSlot
                            control={control}
                            setValue={setValue}
                            name={`weakTakeAway.thursday`}
                            errors={errors}
                            watch={watch}
                          />
                        </EuiFlexGroup>
                      </EuiFlexGroup>

                      <EuiFlexGroup
                        style={{
                          marginBottom: 30,
                          paddingBottom: 40,
                          borderBottom: "1px solid black",
                        }}
                      >
                        <EuiFlexGroup
                          style={{
                            flexDirection: "column",
                            textAlign: "center",
                          }}
                        >
                          <EuiFlexItem
                            style={{
                              textAlign: "left",
                              marginLeft: "3%",
                            }}
                          >
                            {" "}
                            <strong> Friday </strong>{" "}
                          </EuiFlexItem>
                          <TimeSlot
                            control={control}
                            setValue={setValue}
                            name={`weakTakeAway.friday`}
                            errors={errors}
                            watch={watch}
                          />
                        </EuiFlexGroup>
                      </EuiFlexGroup>

                      <EuiFlexGroup
                        style={{
                          marginBottom: 30,
                          paddingBottom: 40,
                          borderBottom: "1px solid black",
                        }}
                      >
                        <EuiFlexGroup
                          style={{
                            flexDirection: "column",
                            textAlign: "center",
                          }}
                        >
                          <EuiFlexItem
                            style={{
                              textAlign: "left",
                              marginLeft: "3%",
                            }}
                          >
                            {" "}
                            <strong> Saturday </strong>{" "}
                          </EuiFlexItem>
                          <TimeSlot
                            control={control}
                            setValue={setValue}
                            name={`weakTakeAway.saturday`}
                            errors={errors}
                            watch={watch}
                          />
                        </EuiFlexGroup>
                      </EuiFlexGroup>
                    </>
                  )}
                </EuiFlexItem>
              </EuiFlexGroup>
            </>
          )}
        </>
      </EuiFormRow>

      <EuiFlexGroup alignItems="center" style={{ marginTop: "10px" }}>
        <EuiFlexItem grow={false}>
          <EuiFormRow isInvalid={errors.time_slot} fullWidth={true}>
            <Controller
              render={({ onChange }) => (
                <EuiRadioGroup
                  options={timeSlot}
                  idSelected={
                    watch("time_slot") === "outlet_differant_day"
                      ? "outlet_differant_day"
                      : "outlet_same_day"
                  }
                  onChange={(id) =>
                    setValue(
                      "time_slot",
                      id === "outlet_differant_day"
                        ? "outlet_differant_day"
                        : "outlet_same_day"
                    )
                  }
                  legend={{
                    children: <span> Decide Outlet Timing </span>,
                  }}
                />
              )}
              name="time_slot"
              defaultValue={
                watch("time_slot") === "outlet_differant_day"
                  ? "outlet_differant_day"
                  : "outlet_same_day"
              }
              control={control}
              fullWidth={true}
            />
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiFlexGroup style={{ width: "90%" }}>
        <EuiFlexItem>
          {watch("time_slot") === "outlet_same_day" && (
            <>
              <EuiFlexGroup
                style={{
                  background: "#9895CA",
                  marginTop: "20px",
                  marginBottom: "30px",
                }}
              >
                <EuiFlexItem grow={2}>Days</EuiFlexItem>
                <EuiFlexItem grow={4}>Start Time</EuiFlexItem>
                <EuiFlexItem grow={4}>End Time</EuiFlexItem>
              </EuiFlexGroup>

              <TimeSlot
                control={control}
                setValue={setValue}
                name="time"
                watch={watch}
              />
            </>
          )}
          {watch("time_slot") === "outlet_differant_day" && (
            <>
              <EuiFlexGroup
                style={{
                  background: "#9895CA",
                  marginTop: "20px",
                  marginBottom: "30px",
                }}
              >
                <EuiFlexItem grow={2}>Days</EuiFlexItem>
                <EuiFlexItem grow={4}>Start Time</EuiFlexItem>
                <EuiFlexItem grow={4}>End Time</EuiFlexItem>
              </EuiFlexGroup>

              <EuiFlexGroup
                style={{
                  marginBottom: 30,
                  paddingBottom: 40,
                  borderBottom: "1px solid black",
                }}
              >
                <EuiFlexGroup
                  style={{
                    flexDirection: "column",
                    textAlign: "center",
                  }}
                >
                  <EuiFlexItem
                    style={{
                      textAlign: "left",
                      marginLeft: "3%",
                    }}
                  >
                    <strong> Sunday </strong>{" "}
                  </EuiFlexItem>
                  <TimeSlot
                    control={control}
                    setValue={setValue}
                    name="weak.sunday"
                    watch={watch}
                  />
                </EuiFlexGroup>
              </EuiFlexGroup>

              <EuiFlexGroup
                style={{
                  marginBottom: 30,
                  paddingBottom: 40,
                  borderBottom: "1px solid black",
                }}
              >
                <EuiFlexGroup
                  style={{
                    flexDirection: "column",
                    textAlign: "center",
                  }}
                >
                  <EuiFlexItem
                    style={{
                      textAlign: "left",
                      marginLeft: "3%",
                    }}
                  >
                    <strong> Monday </strong>
                  </EuiFlexItem>
                  <TimeSlot
                    control={control}
                    setValue={setValue}
                    name="weak.monday"
                    watch={watch}
                  />
                </EuiFlexGroup>
              </EuiFlexGroup>

              <EuiFlexGroup
                style={{
                  marginBottom: 30,
                  paddingBottom: 40,
                  borderBottom: "1px solid black",
                }}
              >
                <EuiFlexGroup
                  style={{
                    flexDirection: "column",
                    textAlign: "center",
                  }}
                >
                  <EuiFlexItem
                    style={{
                      textAlign: "left",
                      marginLeft: "3%",
                    }}
                  >
                    {" "}
                    <strong> Tuesday </strong>{" "}
                  </EuiFlexItem>

                  <TimeSlot
                    control={control}
                    setValue={setValue}
                    name="weak.tuesday"
                    watch={watch}
                  />
                </EuiFlexGroup>
              </EuiFlexGroup>

              <EuiFlexGroup
                style={{
                  marginBottom: 30,
                  paddingBottom: 40,
                  borderBottom: "1px solid black",
                }}
              >
                <EuiFlexGroup
                  style={{
                    flexDirection: "column",
                    textAlign: "center",
                  }}
                >
                  <EuiFlexItem
                    style={{
                      textAlign: "left",
                      marginLeft: "3%",
                    }}
                  >
                    {" "}
                    <strong> Wednesday </strong>{" "}
                  </EuiFlexItem>

                  <TimeSlot
                    control={control}
                    setValue={setValue}
                    name="weak.wednesday"
                    watch={watch}
                  />
                </EuiFlexGroup>
              </EuiFlexGroup>

              <EuiFlexGroup
                style={{
                  marginBottom: 30,
                  paddingBottom: 40,
                  borderBottom: "1px solid black",
                  justifyContent: "center",
                }}
              >
                <EuiFlexGroup
                  style={{
                    flexDirection: "column",
                    textAlign: "center",
                  }}
                >
                  <EuiFlexItem
                    style={{
                      textAlign: "left",
                      marginLeft: "3%",
                    }}
                  >
                    <strong> Thursday </strong>
                  </EuiFlexItem>
                  <TimeSlot
                    control={control}
                    setValue={setValue}
                    name="weak.thursday"
                    watch={watch}
                  />
                </EuiFlexGroup>
              </EuiFlexGroup>

              <EuiFlexGroup
                style={{
                  marginBottom: 30,
                  paddingBottom: 40,
                  borderBottom: "1px solid black",
                }}
              >
                <EuiFlexGroup
                  style={{
                    flexDirection: "column",
                    textAlign: "center",
                  }}
                >
                  <EuiFlexItem
                    style={{
                      textAlign: "left",
                      marginLeft: "3%",
                    }}
                  >
                    <strong> Friday </strong>
                  </EuiFlexItem>
                  <TimeSlot
                    control={control}
                    setValue={setValue}
                    name="weak.friday"
                    watch={watch}
                  />
                </EuiFlexGroup>
              </EuiFlexGroup>

              <EuiFlexGroup
                style={{
                  marginBottom: 30,
                  paddingBottom: 40,
                  borderBottom: "1px solid black",
                }}
              >
                <EuiFlexGroup
                  style={{
                    flexDirection: "column",
                    textAlign: "center",
                  }}
                >
                  <EuiFlexItem
                    style={{
                      textAlign: "left",
                      marginLeft: "3%",
                    }}
                  >
                    <strong> Saturday </strong>
                  </EuiFlexItem>

                  <TimeSlot
                    control={control}
                    setValue={setValue}
                    name="weak.saturday"
                    watch={watch}
                  />
                </EuiFlexGroup>
              </EuiFlexGroup>
            </>
          )}
        </EuiFlexItem>
      </EuiFlexGroup>
      {timeSlotError && (
        <EuiText style={{ color: "red" }}> Please select time slot </EuiText>
      )}

      <EuiButton isLoading={loader} type="submit" fill>
        Update Timing
      </EuiButton>
    </EuiForm>
  );
};

export default Timing;
