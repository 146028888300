import React from "react";
import { MainLayout } from "../components/layout/mainLayout";
import SelectRestaurantToGetOutlet from "../components/restaurants/listOutlet/selectRestaurant";

const ListOutlets = () => {
  return (
    <MainLayout>
      <SelectRestaurantToGetOutlet />
    </MainLayout>
  );
};

export default ListOutlets;
