import React from "react";
import { EuiFormRow, EuiFieldText } from "@elastic/eui";
import { Controller } from "react-hook-form";

const ColorField = (props) => {
  const { label, name, placeholder, rules, errors, control, error, fullWidth } =
    props;
  return (
    <EuiFormRow
      label={label}
      isInvalid={errors.name}
      error={error ? error : errors[name] ? errors[name].message : ""}
      fullWidth={fullWidth ? true : false}
    >
      <Controller
        {...props}
        as={EuiFieldText}
        name={name}
        control={control}
        rules={rules}
        fullWidth={true}
        placeholder={placeholder}
        type="color"
      />
    </EuiFormRow>
  );
};

export default ColorField;
