import axios from "axios";
import { store } from "../../reduxStore/store";
// import * as Sentry from "@sentry/browser"

let API = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  responseType: "json",
});
API.defaults.headers.post["content-type"] = "application/json";
API.defaults.headers.get["Accept"] = "application/json";

API.interceptors.request.use(
  (request) => {
    let authenticationData = JSON.parse(localStorage.getItem("auth"));

    if (authenticationData.token) {
      request.headers.Authorization = `Bearer ${authenticationData.token}`;
    }

    //TODO: Redirect to Restaurant Page if no restaurant is selected.

    // Check If we need to replace the restaurant ID.
    let restaurantID = store.getState().restaurant.restaurantId;

    request.url = request.url.replace(/:restaurantId/gi, restaurantID);

    // Dispatch that we are loading a request.
    store.dispatch({
      type: "START_LOADING",
    });

    console.log(`${request.method} ${request.url}`);
    // Important: request interceptors **must** return the request.
    return request;
  },
  (error) => {
    store.dispatch({ type: "FINISH_LOADING" });
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  (response) => {
    console.log("Response");

    // Dispatch that we finished loading.
    store.dispatch({
      type: "FINISH_LOADING",
    });

    return response.data;
  },
  (error) => {
    store.dispatch({ type: "FINISH_LOADING" });

    const {
      config,
      response: { status },
    } = error;
    const originalRequest = config;

    // const email = store.getState().auth.user ? store.getState().auth.user.email : ''

    // Sentry.configureScope(scope => scope.setLevel("Error").setUser({"email": email}))
    // Sentry.captureException(error);

    console.log("Error Response", error.response.data);
    console.log(error.response.data);

    if (
      (status === 401 || status === 400 || status === 403) &&
      window.location.pathname !== "/login"
    ) {
      // TODO: take necessary steps when there is an error
      //TODO: Remove auth details from redux store.
      localStorage.removeItem("auth");
      window.location.href = "/login";
    }
    return Promise.reject(error.response.data);
  }
);

export default API;
