import React from "react";
import { MainLayout } from "../components/layout/mainLayout";
import PlansComponent from "../components/plans/";

const Plans = (props) => {
  return (
    <MainLayout>
      <PlansComponent />
    </MainLayout>
  );
};

export default Plans;
