import API from "../axios/API";
import * as types from "../../reduxStore/types/plans";

export const getOutletList = (restaurantId) => {
  return async (dispatch, getState) => {
    let outletResponse;

    try {
      outletResponse = await API.get(`restaurants/${restaurantId}/outlets`);
    } catch (error) {
      outletResponse = error;
    }

    return outletResponse;
  };
};
