import React from "react";
import { MainLayout } from "../components/layout/mainLayout";
import CreateOutlet from "../components/restaurants/createOutlet";

const CreateOutlets = () => {
  return (
    <MainLayout>
      <CreateOutlet />
    </MainLayout>
  );
};

export default CreateOutlets;
