import superagent from "../config/superagent";
import * as types from "../reduxStore/types/outlet";
import format from "date-fns/format";

export const getAllOutlet = async () => {
  let apiRes;
  try {
    apiRes = await superagent.get("en/outlets/list");
    apiRes.apiError = false;
  } catch (err) {
    apiRes = err;
    apiRes.apiError = true;
  }
  if (!apiRes.apiError) {
    superagent.dispatch({
      type: types.SET_OUTLET_LIST,
      payload: apiRes.body.outlets,
    });
  }
  return apiRes;
};

export const getOrderAndVisitorCountByDate = async (date, outletId) => {
  let apiRes;
  try {
    apiRes = await superagent.get(
      `en/dashboard/date/details?compareDate=${format(
        new Date(date),
        "yyyy-MM-dd"
      )}&outletId=${outletId}`
    );
    apiRes.apiError = false;
  } catch (err) {
    apiRes = err;
    apiRes.apiError = true;
  }
  return apiRes;
};

export const getUniversalOrdersDetails = async () => {
  let apiRes;
  try {
    apiRes = await superagent.get("en/dashboard/universal/details");
    apiRes.apiError = false;
  } catch (err) {
    apiRes = err;
    apiRes.apiError = true;
  }
  return apiRes;
};

export const getOutletOutletOrderDetails = async (outletId) => {
  let apiRes;
  try {
    apiRes = await superagent.get(
      `en/dashboard/outlet/details?outletId=${outletId}`
    );
    apiRes.apiError = false;
  } catch (err) {
    apiRes = err;
    apiRes.apiError = true;
  }
  return apiRes;
};

export const getVisitorInformationByOutlet = async (outletId) => {
  let apiRes;
  try {
    apiRes = await superagent.get(
      `en/dashboard/visitors/details?outletId=${outletId}`
    );
    apiRes.apiError = false;
  } catch (err) {
    apiRes = err;
    apiRes.apiError = true;
  }
  return apiRes;
};

export const getTotalDeliveryCostByOutlet = async (outletId) => {
  let apiRes;
  try {
    apiRes = await superagent.get(
      `en/dashboard/delivery/cost?outletId=${outletId}`
    );
    apiRes.apiError = false;
  } catch (err) {
    apiRes = err;
    apiRes.apiError = true;
  }
  return apiRes;
};
