import * as types from "../types/restaurant";

const initialState = {
  restaurantId: null,
  restaurants: [],
  editRestaurantId: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_RESTAURANT_ID:
      return {
        ...state,
        restaurantId: action.payload.restaurantId,
      };
    case types.SET_RESTAURANTS:
      return {
        ...state,
        restaurants: action.payload.restaurants,
      };
    case types.SET_EDIT_RESTAURANTS:
      return {
        ...state,
        editRestaurantId: action.payload.editRestaurantId,
      };
    default:
      return state;
  }
};
