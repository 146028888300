import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { v4 as uuidv4, v1 as uuid } from "uuid";
import { EuiFlexGroup, EuiFlexItem, EuiForm, EuiButton } from "@elastic/eui";
import { formatedDataForKitchen } from "../createOutlet/formatedKitchenData";
import NumberField from "../../Form/NumberField";
import RadioField from "../../Form/RadioField";
import KitchenTimeSlot from "../../Form/kitchenTimeSlot";
import { baseApiUrl } from "../../../envConfig";
import { useSelector } from "react-redux";
import { store } from "../../../reduxStore/store";

const PreparationTime = ({ getOutletData }) => {
  const { register, handleSubmit, errors, control, watch, reset, setValue } =
    useForm();
  const restaurantID = useSelector((state) => state.restaurant.restaurantId);
  var token = store.getState().auth.token;
  let defaultValue = {};
  const [loader, setLoader] = useState(false);
  const kitchen_timeSlot = [
    {
      id: "no_rush_hour",
      label: "There is no rush hour ",
    },
    {
      id: "all_days_rush_hour",
      label: "Add some rush hour on all days",
    },
    {
      id: "differant_days_rush_hour",
      label: "Differant rush hour on differant days",
    },
  ];

  useEffect(() => {
    if (getOutletData) {
      const outletData = getOutletData;
      defaultValue.kitchen_preparation_time =
        outletData.settings.kitchen_preparation_time;
      //set rush our
      defaultValue.kitchen_time_slot =
        outletData.settings.rush_hour_time_slot_setting === "no_time_slots"
          ? "no_rush_hour"
          : outletData.settings.rush_hour_time_slot_setting ===
            "same_time_slot_for_all_days"
          ? "all_days_rush_hour"
          : outletData.settings.rush_hour_time_slot_setting ===
            "different_time_slots_for_different_days"
          ? "differant_days_rush_hour"
          : "no_rush_hour";
      if (
        outletData.settings.rush_hour_time_slot_setting ===
        "same_time_slot_for_all_days"
      ) {
        let formatedTimeSlotInfoRushour = formatedDataForKitchen(
          outletData.settings.rush_hour_schedules
        );
        console.log("formatedTimeSlotInfoRushour", formatedTimeSlotInfoRushour);
        let fieldData = [];
        formatedTimeSlotInfoRushour &&
          formatedTimeSlotInfoRushour[1] &&
          formatedTimeSlotInfoRushour[1].map((item, index) => {
            fieldData.push({
              id: uuid(),
              start_time: item.start_time,
              end_time: item.end_time,
              preparation_time_in_rush: item.preparation_time_in_rush,
            });
          });
        defaultValue.kitchen_time = fieldData;
      }
      defaultValue.kitchen_time_weak = {
        sunday: [],
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: [],
      };
      if (
        outletData.settings.rush_hour_time_slot_setting ===
        "different_time_slots_for_different_days"
      ) {
        let formatedTimeSlotInfo = formatedDataForKitchen(
          outletData.settings.rush_hour_schedules
        );
        console.log("formatedTimeSlotInfo", formatedTimeSlotInfo);
        for (const key in formatedTimeSlotInfo) {
          if (key == 7) {
            formatedTimeSlotInfo[7].map((item) =>
              defaultValue.kitchen_time_weak.sunday.push({
                id: uuidv4(),
                start_time: item.start_time,
                end_time: item.end_time,
                preparation_time_in_rush: item.preparation_time_in_rush,
              })
            );
          }
          if (key == 1) {
            formatedTimeSlotInfo[1].forEach((item) =>
              defaultValue.kitchen_time_weak.monday.push({
                id: uuidv4(),
                start_time: item.start_time,
                end_time: item.end_time,
                preparation_time_in_rush: item.preparation_time_in_rush,
              })
            );
          }

          if (key == 2) {
            formatedTimeSlotInfo[2].map((item) =>
              defaultValue.kitchen_time_weak.tuesday.push({
                id: uuidv4(),
                start_time: item.start_time,
                end_time: item.end_time,
                preparation_time_in_rush: item.preparation_time_in_rush,
              })
            );
          }

          if (key == 3) {
            formatedTimeSlotInfo[3].map((item) =>
              defaultValue.kitchen_time_weak.wednesday.push({
                id: uuidv4(),
                start_time: item.start_time,
                end_time: item.end_time,
                preparation_time_in_rush: item.preparation_time_in_rush,
              })
            );
          }

          if (key == 4) {
            formatedTimeSlotInfo[4].map((item) =>
              defaultValue.kitchen_time_weak.thursday.push({
                id: uuidv4(),
                start_time: item.start_time,
                end_time: item.end_time,
                preparation_time_in_rush: item.preparation_time_in_rush,
              })
            );
          }

          if (key == 5) {
            formatedTimeSlotInfo[5].map((item) =>
              defaultValue.kitchen_time_weak.friday.push({
                id: uuidv4(),
                start_time: item.start_time,
                end_time: item.end_time,
                preparation_time_in_rush: item.preparation_time_in_rush,
              })
            );
          }

          if (key == "no_rush_hour") {
            formatedTimeSlotInfo[6].map((item) =>
              defaultValue.kitchen_time_weak.saturday.push({
                id: uuidv4(),
                start_time: item.start_time,
                end_time: item.end_time,
                preparation_time_in_rush: item.preparation_time_in_rush,
              })
            );
          }
        }
      }
      reset({
        ...defaultValue,
        time: defaultValue.time ? defaultValue.time : [],
        weak: defaultValue.weak
          ? defaultValue.weak
          : {
              sunday: [],
              monday: [],
              tuesday: [],
              wednesday: [],
              thursday: [],
              friday: [],
              saturday: [],
            },
        kitchen_time: defaultValue.kitchen_time
          ? defaultValue.kitchen_time
          : [],
        kitchen_time_weak: defaultValue.kitchen_time_weak
          ? defaultValue.kitchen_time_weak
          : {
              sunday: [],
              monday: [],
              tuesday: [],
              wednesday: [],
              thursday: [],
              friday: [],
              saturday: [],
            },
      });
    }
  }, [getOutletData]);
  const onSubmit = async (data) => {
    console.log(data);
    data.id = null;
    if (getOutletData) {
      data.id = getOutletData.outlet_id;
    }
    data.settings = {
      kitchen_preparation_time: data.kitchen_preparation_time,
    };
    let rush_hour_schedules;
    if (
      data.settings.kitchen_time_slot === "no_rush_hour" ||
      data.kitchen_time_slot === true
    ) {
      data.rush_hour_schedules = null;
      data.settings.rush_hour_time_slot_setting = "no_time_slots";
    } else if (data.kitchen_time_slot === "all_days_rush_hour") {
      data.settings.rush_hour_time_slot_setting = "same_time_slot_for_all_days";
      rush_hour_schedules = [];

      let singleTimeSlot =
        data.kitchen_time &&
        data.kitchen_time.length > 0 &&
        data.kitchen_time.filter((item, index) => {
          if (
            item.start_time !== undefined &&
            item.end_time !== undefined &&
            item.preparation_time_in_rush !== undefined
          ) {
            return item;
          }
        });
      singleTimeSlot &&
        singleTimeSlot.length > 0 &&
        rush_hour_schedules.push({
          days_of_week: [1, 2, 3, 4, 5, 6, 7],
          time_slots: singleTimeSlot,
        });
      data.settings.rush_hour_schedules =
        rush_hour_schedules.length > 0 ? rush_hour_schedules : null;
      if (!data.settings.rush_hour_schedules) {
        data.settings.rush_hour_time_slot_setting = "no_time_slots";
      }
    } else if (data.kitchen_time_slot === "differant_days_rush_hour") {
      data.settings.rush_hour_time_slot_setting =
        "different_time_slots_for_different_days";

      rush_hour_schedules = [];

      let sunday =
        data.kitchen_time_weak &&
        data.kitchen_time_weak.sunday &&
        data.kitchen_time_weak.sunday.filter((item, index) => {
          if (
            item.start_time !== undefined &&
            item.end_time !== undefined &&
            item.preparation_time_in_rush !== undefined
          ) {
            return item;
          }
        });

      sunday &&
        sunday.length > 0 &&
        rush_hour_schedules.push({
          days_of_week: [7],
          time_slots: sunday,
        });

      let monday =
        data.kitchen_time_weak &&
        data.kitchen_time_weak.monday &&
        data.kitchen_time_weak.monday.filter((item, index) => {
          if (
            item.start_time !== undefined &&
            item.end_time !== undefined &&
            item.preparation_time_in_rush !== undefined
          ) {
            return item;
          }
        });
      monday &&
        monday.length > 0 &&
        rush_hour_schedules.push({
          days_of_week: [1],
          time_slots: monday,
        });

      let tuesday =
        data.kitchen_time_weak &&
        data.kitchen_time_weak.tuesday &&
        data.kitchen_time_weak.tuesday.filter((item, index) => {
          if (
            item.start_time !== undefined &&
            item.end_time !== undefined &&
            item.preparation_time_in_rush !== undefined
          ) {
            return item;
          }
        });
      tuesday &&
        tuesday.length > 0 &&
        rush_hour_schedules.push({
          days_of_week: [2],
          time_slots: tuesday,
        });

      let wednesday =
        data.kitchen_time_weak &&
        data.kitchen_time_weak.wednesday &&
        data.kitchen_time_weak.wednesday.filter((item, index) => {
          if (
            item.start_time !== undefined &&
            item.end_time !== undefined &&
            item.preparation_time_in_rush !== undefined
          ) {
            return item;
          }
        });
      wednesday &&
        wednesday.length > 0 &&
        rush_hour_schedules.push({
          days_of_week: [3],
          time_slots: wednesday,
        });

      let thursday =
        data.kitchen_time_weak &&
        data.kitchen_time_weak.thursday &&
        data.kitchen_time_weak.thursday.filter((item, index) => {
          if (
            item.start_time !== undefined &&
            item.end_time !== undefined &&
            item.preparation_time_in_rush !== undefined
          ) {
            return item;
          }
        });
      thursday &&
        thursday.length > 0 &&
        rush_hour_schedules.push({
          days_of_week: [4],
          time_slots: thursday,
        });

      let friday =
        data.kitchen_time_weak &&
        data.kitchen_time_weak.friday &&
        data.kitchen_time_weak.friday.filter((item, index) => {
          if (
            item.start_time !== undefined &&
            item.end_time !== undefined &&
            item.preparation_time_in_rush !== undefined
          ) {
            return item;
          }
        });
      friday &&
        friday.length > 0 &&
        rush_hour_schedules.push({
          days_of_week: [5],
          time_slots: friday,
        });

      let saturday =
        data.kitchen_time_weak &&
        data.kitchen_time_weak.saturday &&
        data.kitchen_time_weak.saturday.filter((item, index) => {
          if (
            item.start_time !== undefined &&
            item.end_time !== undefined &&
            item.preparation_time_in_rush !== undefined
          ) {
            return item;
          }
        });
      saturday &&
        saturday.length > 0 &&
        rush_hour_schedules.push({
          days_of_week: [6],
          time_slots: saturday,
        });
      console.log("rush week time", data.rush_hour_schedules);
      data.settings.rush_hour_schedules =
        rush_hour_schedules.length > 0 ? rush_hour_schedules : null;
      if (!data.settings.rush_hour_schedules) {
        data.settings.rush_hour_time_slot_setting = "no_time_slots";
      }
    } else {
      rush_hour_schedules = null;
      data.settings.rush_hour_time_slot_setting = "no_time_slots";
      data.settings.rush_hour_schedules = null;
    }

    console.log("Prepoaration and rush hour", JSON.stringify(data));
    setLoader(true);
    const preparationTime = await fetch(
      `${baseApiUrl}restaurants/${restaurantID}/outlets/${data.id}?section=preparation_time_and_rush_hour`,
      {
        method: "put",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    const preparationTimeJson = await preparationTime.json();

    if (preparationTimeJson.success) {
      alert("successfully edited");
    } else {
      alert("Please try later");
    }
    setLoader(false);
  };
  return (
    <EuiForm
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      style={{ marginTop: 20 }}
    >
      <NumberField
        label="Kitchen Prep Time Slots"
        errors={errors}
        name="kitchen_preparation_time"
        placeholder="Please enter kitchen preparation time"
        rules={{
          required: "Please enter kitchen preparation time",
          validate: (value) => value > 0 || "Please enter positive value",
        }}
        step="any"
        min={0}
        control={control}
      />
      <RadioField
        name="kitchen_time_slot"
        label=" Decide kitchen rush hour slot"
        options={kitchen_timeSlot}
        idSelected={
          watch("kitchen_time_slot") === "no_rush_hour"
            ? "no_rush_hour"
            : watch("kitchen_time_slot") === "all_days_rush_hour"
            ? "all_days_rush_hour"
            : watch("kitchen_time_slot") === "differant_days_rush_hour"
            ? "differant_days_rush_hour"
            : "no_rush_hour"
        }
        onChangeData={(id) =>
          setValue(
            "kitchen_time_slot",
            id === "no_rush_hour"
              ? "no_rush_hour"
              : id === "all_days_rush_hour"
              ? "all_days_rush_hour"
              : id === "differant_days_rush_hour"
              ? "differant_days_rush_hour"
              : "no_rush_hour"
          )
        }
        control={control}
        defaultValue={"no_rush_hour"}
      />
      <EuiFlexGroup style={{ width: "90%" }}>
        <EuiFlexItem>
          {watch("kitchen_time_slot") === "all_days_rush_hour" && (
            <>
              <EuiFlexGroup
                style={{
                  background: "#9895CA",
                  marginTop: "20px",
                  marginBottom: "30px",
                }}
              >
                <EuiFlexItem grow={2}>Days</EuiFlexItem>
                <EuiFlexItem grow={4}>Start Time</EuiFlexItem>
                <EuiFlexItem grow={4}>End Time</EuiFlexItem>
                <EuiFlexItem grow={4}>Kitchen Prepation Time</EuiFlexItem>
              </EuiFlexGroup>
              <KitchenTimeSlot
                control={control}
                setValue={setValue}
                name="kitchen_time"
                watch={watch}
                errors={errors}
              />
            </>
          )}
          {watch("kitchen_time_slot") === "differant_days_rush_hour" && (
            <>
              <EuiFlexGroup
                style={{
                  background: "#9895CA",
                  marginTop: "20px",
                  marginBottom: "30px",
                  width: "90%",
                }}
              >
                <EuiFlexItem grow={2}>Days</EuiFlexItem>
                <EuiFlexItem grow={4}>Start Time</EuiFlexItem>
                <EuiFlexItem grow={4}>End Time</EuiFlexItem>
                <EuiFlexItem grow={4}>Kitchen Prepation Time</EuiFlexItem>
                <EuiFlexItem grow={1}></EuiFlexItem>
              </EuiFlexGroup>

              <EuiFlexGroup
                style={{
                  marginBottom: 30,
                  paddingBottom: 40,
                  borderBottom: "1px solid black",
                  width: "90%",
                }}
              >
                <EuiFlexGroup
                  style={{
                    flexDirection: "column",
                    textAlign: "center",
                  }}
                >
                  <EuiFlexItem
                    style={{
                      textAlign: "left",
                    }}
                  >
                    <strong> Sunday </strong>{" "}
                  </EuiFlexItem>

                  <KitchenTimeSlot
                    control={control}
                    setValue={setValue}
                    name="kitchen_time_weak.sunday"
                    watch={watch}
                  />
                </EuiFlexGroup>
              </EuiFlexGroup>

              <EuiFlexGroup
                style={{
                  marginBottom: 30,
                  paddingBottom: 40,
                  borderBottom: "1px solid black",
                  width: "90%",
                }}
              >
                <EuiFlexGroup
                  style={{
                    flexDirection: "column",
                    textAlign: "center",
                  }}
                >
                  <EuiFlexItem
                    style={{
                      textAlign: "left",
                      marginLeft: "3%",
                    }}
                  >
                    <strong> Monday </strong>
                  </EuiFlexItem>
                  <KitchenTimeSlot
                    control={control}
                    setValue={setValue}
                    name="kitchen_time_weak.monday"
                    watch={watch}
                  />
                </EuiFlexGroup>
              </EuiFlexGroup>

              <EuiFlexGroup
                style={{
                  marginBottom: 30,
                  paddingBottom: 40,
                  borderBottom: "1px solid black",
                  width: "90%",
                }}
              >
                <EuiFlexGroup
                  style={{
                    flexDirection: "column",
                    textAlign: "center",
                  }}
                >
                  <EuiFlexItem
                    style={{
                      textAlign: "left",
                      marginLeft: "3%",
                    }}
                  >
                    {" "}
                    <strong> Tuesday </strong>{" "}
                  </EuiFlexItem>

                  <KitchenTimeSlot
                    control={control}
                    setValue={setValue}
                    name="kitchen_time_weak.tuesday"
                    watch={watch}
                  />
                </EuiFlexGroup>
              </EuiFlexGroup>

              <EuiFlexGroup
                style={{
                  marginBottom: 30,
                  paddingBottom: 40,
                  borderBottom: "1px solid black",
                  width: "90%",
                }}
              >
                <EuiFlexGroup
                  style={{
                    flexDirection: "column",
                    textAlign: "center",
                  }}
                >
                  <EuiFlexItem
                    style={{
                      textAlign: "left",
                      marginLeft: "3%",
                    }}
                  >
                    {" "}
                    <strong> Wednesday </strong>{" "}
                  </EuiFlexItem>

                  <KitchenTimeSlot
                    control={control}
                    setValue={setValue}
                    name="kitchen_time_weak.wednesday"
                    watch={watch}
                  />
                </EuiFlexGroup>
              </EuiFlexGroup>

              <EuiFlexGroup
                style={{
                  marginBottom: 30,
                  paddingBottom: 40,
                  borderBottom: "1px solid black",
                  justifyContent: "center",
                  width: "90%",
                }}
              >
                <EuiFlexGroup
                  style={{
                    flexDirection: "column",
                    textAlign: "center",
                  }}
                >
                  <EuiFlexItem
                    style={{
                      textAlign: "left",
                      marginLeft: "3%",
                    }}
                  >
                    <strong> Thursday </strong>
                  </EuiFlexItem>
                  <KitchenTimeSlot
                    control={control}
                    setValue={setValue}
                    name="kitchen_time_weak.thursday"
                    watch={watch}
                  />
                </EuiFlexGroup>
              </EuiFlexGroup>

              <EuiFlexGroup
                style={{
                  marginBottom: 30,
                  paddingBottom: 40,
                  borderBottom: "1px solid black",
                  width: "90%",
                }}
              >
                <EuiFlexGroup
                  style={{
                    flexDirection: "column",
                    textAlign: "center",
                  }}
                >
                  <EuiFlexItem
                    style={{
                      textAlign: "left",
                      marginLeft: "3%",
                    }}
                  >
                    <strong> Friday </strong>
                  </EuiFlexItem>
                  <KitchenTimeSlot
                    control={control}
                    setValue={setValue}
                    name="kitchen_time_weak.friday"
                    watch={watch}
                  />
                </EuiFlexGroup>
              </EuiFlexGroup>

              <EuiFlexGroup
                style={{
                  marginBottom: 30,
                  paddingBottom: 40,
                  borderBottom: "1px solid black",
                  justifyContent: "center",
                  width: "90%",
                }}
              >
                <EuiFlexGroup
                  style={{
                    flexDirection: "column",
                    textAlign: "center",
                  }}
                >
                  <EuiFlexItem
                    style={{
                      textAlign: "left",
                      marginLeft: "3%",
                    }}
                  >
                    <strong> Saturday </strong>
                  </EuiFlexItem>
                  <KitchenTimeSlot
                    control={control}
                    setValue={setValue}
                    name="kitchen_time_weak.saturday"
                    watch={watch}
                  />
                </EuiFlexGroup>
              </EuiFlexGroup>
            </>
          )}
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiButton isLoading={loader} type="submit" fill>
        Update Preparation Time
      </EuiButton>
    </EuiForm>
  );
};

export default PreparationTime;
