export const baseApiUrl = process.env.REACT_APP_BASE_URL;
export const socketUrl = "https://socket.demo.fab.delivery/";

export const firebaseConfig = {
  apiKey: "AIzaSyAAa2tRyleeuiauJ1cumd5ZWTq1fPY6_8Q",
  authDomain: "fab-delivery---demo-app.firebaseapp.com",
  databaseURL: "https://fab-delivery---demo-app.firebaseio.com",
  projectId: "fab-delivery---demo-app",
  storageBucket: "fab-delivery---demo-app.appspot.com",
  messagingSenderId: "1070135244311",
  appId: "1:1070135244311:web:3e90fc2a911f6ab0474e52",
  measurementId: "G-SKW98Y4Z32",
};
