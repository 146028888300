import React from "react";
import { EuiFormRow, EuiComboBox } from "@elastic/eui";
import { Controller } from "react-hook-form";

const ComboBoxField = (props) => {
  const {
    label,
    name,
    placeholder,
    rules,
    errors,
    control,
    options,
    selectedOptions,
    onChangeData,
    fullWidth,
  } = props;
  return (
    <EuiFormRow
      label={label}
      isInvalid={errors[name]}
      error={errors[name] ? errors[name].message : ""}
      fullWidth={fullWidth ? true : false}
    >
      <Controller
        render={({ onChange }) => (
          <EuiComboBox
            {...props}
            options={options}
            selectedOptions={selectedOptions}
            onChange={onChangeData}
            placeholder={placeholder}
            fullWidth
          />
        )}
        name={name}
        control={control}
        isInvalid={errors[name]}
        rules={rules}
      />
    </EuiFormRow>
  );
};

export default ComboBoxField;
