import React, { useState } from "react";
import { EuiSuperSelect, EuiText, EuiSpacer } from "@elastic/eui";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "react-query";
import { getAllRestaurants } from "../../../api/restaurantsAPICall";

import ListOutlet from "./listOutlet";

const SelectRestaurantToGetOutlet = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState("0");
  const onChange = async (value) => {
    setValue(value);
  };
  const {
    isLoading: loading,
    error,
    data: getAllRestaurant,
  } = useQuery("getAllRestaurant", getAllRestaurants);

  if (loading) return <h1>Loading....</h1>;
  if (error) return <h1> Error...</h1>;

  let options = [
    {
      value: "0",
      inputDisplay: "Please select restaurant",
    },
  ];
  if (getAllRestaurant !== undefined) {
    if (getAllRestaurant.success) {
      getAllRestaurant.restaurants &&
        getAllRestaurant.restaurants.map((item, index) =>
          options.push({
            value: item.id,
            inputDisplay: item.name,
          })
        );
    }
  }
  if (value !== "0") {
    const getRestaurantInfo = getAllRestaurant.restaurants.find((item) => {
      if (item.id === value) {
        return item;
      }
    });

    dispatch({
      type: "set-all-available-languages",
      payload: {
        availableLanguages: getRestaurantInfo.languages,
      },
    });
    dispatch({
      type: "set-restaurantId",
      payload: {
        restaurantId: getRestaurantInfo.id,
      },
    });
  }

  return (
    <div>
      <EuiText>
        <strong> Select Restaurant </strong>{" "}
      </EuiText>
      <EuiSuperSelect
        options={options}
        valueOfSelected={value}
        onChange={(value) => onChange(value)}
      />
      <EuiSpacer />

      {value !== "0" && <ListOutlet restaurantID={value} />}
      {/* {value && <CreateOutlet value={value} paymentMethod={getAllPaymentMethod} /> } */}
    </div>
  );
};

export default SelectRestaurantToGetOutlet;
