import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import LoginPage from "./pages/login";
import HomePage from "./pages";
import PrivateRoute from "./components/auth/privateRoute";
import Page404 from "./pages/not-found-404";
import { EuiTheme } from "./components/elasticTheme";
import { DashboardPage } from "./pages/dashboard";
import CreateRestaurants from "./pages/create-restaurant";
import CreateOutlets from "./pages/create-outlet";
import ListRestaurants from "./pages/list-restaurant";
import Users from "./pages/users";
import ListOutlets from "./pages/list-outlet";
import EditOutlets from "./pages/edit-outlet";
import Plans from "./pages/plans";
import { EuiProgress } from "@elastic/eui";
import { useSelector } from "react-redux";

const App = () => {
  let totalApiRequestInProgress = useSelector(
    (state) => state.loading.totalRequest
  );

  return (
    <Router>
      {/*Global Loading Indicator*/}
      {totalApiRequestInProgress > 0 ? (
        <EuiProgress size="xs" color="primary" position="fixed" />
      ) : null}
      <EuiTheme />
      <Switch>
        <Route path="/login" component={LoginPage} exact />
        <PrivateRoute path="/" component={HomePage} exact />
        <PrivateRoute path="/dashboard" component={DashboardPage} exact />
        <PrivateRoute
          path="/create-restaurant"
          component={CreateRestaurants}
          exact
        />
        <PrivateRoute path="/create-outlet" component={CreateOutlets} exact />
        <PrivateRoute
          path="/list-restaurant"
          component={ListRestaurants}
          exact
        />
        <PrivateRoute path="/list-outlet" component={ListOutlets} exact />
        <PrivateRoute path="/user" component={Users} exact />
        <PrivateRoute path="/edit-outlet" component={EditOutlets} exact />
        <PrivateRoute path="/plans" component={Plans} exact />
        <PrivateRoute path="*" component={Page404} exact />
      </Switch>
    </Router>
  );
};

export default App;
