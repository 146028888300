import React, { useEffect } from "react";
import { MainLayout } from "../components/layout/mainLayout";
import { useHistory } from "react-router-dom";

export default () => {
  const history = useHistory();
  const tempDep = "temp";
  useEffect(() => {
    history.push("/dashboard");
  }, [tempDep, history]);
  return (
    <>
      <MainLayout>home page</MainLayout>
    </>
  );
};
