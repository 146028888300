import React from "react";
import { MainLayout } from "../components/layout/mainLayout";
import ListRestaurant from "../components/restaurants/listRestaurant/listRestaurant";

const ListRestaurants = () => {
  return (
    <MainLayout>
      <ListRestaurant />
    </MainLayout>
  );
};

export default ListRestaurants;
