import React from "react";
import { EuiFormRow, EuiSuperSelect } from "@elastic/eui";

import { Controller } from "react-hook-form";
const SelectField = (props) => {
  const {
    label,
    name,
    placeholder,
    rules,
    errors,
    control,
    options,
    valueOfSelected,
    onChangeData,
    defaultValue,
    fullWidth,
  } = props;
  return (
    <EuiFormRow
      label={label}
      isInvalid={errors[name]}
      error={errors[name] ? errors[name].message : ""}
      fullWidth={fullWidth ? true : false}
    >
      <Controller
        render={({ onChange }) => (
          <EuiSuperSelect
            options={options}
            valueOfSelected={valueOfSelected}
            onChange={onChangeData}
            placeholder={placeholder}
          />
        )}
        rules={rules}
        name={name}
        control={control}
        fullWidth
        defaultValue={defaultValue}
        required={errors[name]}
      />
    </EuiFormRow>
  );
};

export default SelectField;
