import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EuiText, EuiButton, EuiDataGrid, EuiSpacer } from "@elastic/eui";
import { useHistory } from "react-router-dom";
import { useQuery, queryCache } from "react-query";
import { getAllRestaurants } from "../../../api/restaurantsAPICall";
import { baseApiUrl } from "../../../envConfig";

const ListRestaurant = () => {
  //  const listOutlet = JSON.parse(localStorage.getItem("form_list"));
  //  console.log("LocalStorage data", listOutlet);

  const {
    isLoading: loading,
    error,
    data: getAllRestaurantsData,
  } = useQuery("getAllRestaurantsData", getAllRestaurants);

  const dispatch = useDispatch();
  let history = useHistory();

  const token = useSelector((state) => state.auth.token);
  const columns = [
    {
      id: "id",
    },
    {
      id: "name",
    },

    {
      id: "app_name",
    },
    {
      id: "two_factor_api_key",
    },
    {
      id: "google_maps_api_key",
    },
    {
      id: "edit",
    },
    {
      id: "delete",
    },
  ];

  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });

  const handleDeleteOutlet = async (id) => {
    if (window.confirm("Are you sure you want to delete this record ?")) {
      const restaurant = await fetch(`${baseApiUrl}restaurants/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      });
      const restaurantJson = await restaurant.json();
      if (restaurantJson.success) {
        window.alert("Deleted successfully");
        queryCache.invalidateQueries("getAllRestaurantsData");
      } else {
        window.alert("Please try later");
      }
    }
  };

  const onChangeItemsPerPage = useCallback(
    (pageSize) =>
      setPagination((pagination) => ({
        ...pagination,
        pageSize,
        pageIndex: 0,
      })),
    [setPagination]
  );
  const onChangePage = useCallback(
    (pageIndex) =>
      setPagination((pagination) => ({ ...pagination, pageIndex })),
    [setPagination]
  );

  const [sortingColumns, setSortingColumns] = useState([]);

  const onSort = useCallback(
    (sortingColumns) => {
      setSortingColumns(sortingColumns);
    },
    [setSortingColumns]
  );

  const [visibleColumns, setVisibleColumns] = useState(() =>
    columns.map(({ id }) => id)
  );
  let mappedItems =
    getAllRestaurantsData &&
    getAllRestaurantsData.success &&
    getAllRestaurantsData.restaurants.length > 0 &&
    getAllRestaurantsData.restaurants.map((item) => {
      return {
        id: <>{<EuiText key={item.id + "id"}>{item.id}</EuiText>}</>,
        name: <>{<EuiText key={item.id + "name"}>{item.name}</EuiText>}</>,
        app_name: (
          <>
            {
              <EuiText key={item.id + "app_name"}>
                {" "}
                {item.settings ? item.settings.app_name : null}
              </EuiText>
            }
          </>
        ),

        two_factor_api_key: (
          <>
            {
              <EuiText key={item.id + "two_factor_api_key"}>
                {item.settings ? item.settings.two_factor_api_key : null}
              </EuiText>
            }
          </>
        ),
        google_maps_api_key: (
          <>
            {
              <EuiText key={item.id + "google_maps_api_key"}>
                {item.settings ? item.settings.google_maps_api_key : null}
              </EuiText>
            }
          </>
        ),
        google_address: (
          <>
            {
              <EuiText key={item.id + "google_address"}>
                {item.google_address}
              </EuiText>
            }
          </>
        ),
        edit: (
          <>
            <EuiButton
              iconType="documentEdit"
              onClick={() => handleEditOutlet(item.id)}
            >
              Edit
            </EuiButton>
          </>
        ),
        delete: (
          <>
            <EuiButton
              iconType="cross"
              onClick={() => handleDeleteOutlet(item.id)}
            >
              Delete
            </EuiButton>
          </>
        ),
      };
    });

  const renderCellValue = useMemo(() => {
    return ({ rowIndex, columnId, setCellProps }) => {
      return mappedItems.hasOwnProperty(rowIndex)
        ? mappedItems[rowIndex][columnId]
        : null;
    };
  }, [mappedItems]);

  const handleEditOutlet = (id) => {
    console.log("id", id);
    dispatch({
      type: "set-edit-restaurants",
      payload: {
        editRestaurantId: id,
      },
    });
    history.push("/create-restaurant");
  };

  if (loading) {
    return <h1>Loading</h1>;
  }
  if (error) {
    return <h1> Error</h1>;
  }
  dispatch({
    type: "set-restaurants",
    payload: {
      restaurants: getAllRestaurantsData.restaurants,
    },
  });
  return (
    <>
      <EuiText style={{ fontWeight: "bold" }}>Restaurant List :-</EuiText>
      <EuiSpacer />
      <EuiDataGrid
        aria-label="Restaurant List Datagrid"
        columns={columns}
        columnVisibility={{ visibleColumns, setVisibleColumns }}
        rowCount={mappedItems.length}
        renderCellValue={renderCellValue}
        sorting={{ columns: sortingColumns, onSort }}
        pagination={{
          ...pagination,
          pageSizeOptions: [10, 50, 100],
          onChangeItemsPerPage: onChangeItemsPerPage,
          onChangePage: onChangePage,
        }}
      />
    </>
  );
};

export default ListRestaurant;
