import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import {
  getAllUserByRestaurantId,
  getAllUserPermission,
} from "../../api/permissionAPICall";
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiTabbedContent,
  EuiButton,
} from "@elastic/eui";
import UserListBasedOnRestaurant from "./userListBasedOnRestaurant";
import UserOutletPermission from "./userOutletPermission";
import UserAccessPermissions from "./userAccessPermission";
import AddUserFlatout from "./addUserFlatout";
import "./style.css";

const UserTableList = ({ value }) => {
  const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);
  const [accessPermissionState, setAccessPermissionState] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedUserId, setSelectedUser] = useState(1);
  const {
    isLoading,
    error,
    data: userData,
  } = useQuery([`getAllUser`, value], getAllUserByRestaurantId);

  useEffect(() => {
    console.log("userData", userData);
    setSelectedUser(
      userData && userData.users && userData.users.length > 0
        ? userData.users[0].user.id
        : 1
    );
  }, [userData]);

  const {
    isLoading: loadingPermission,
    error: errorPermission,
    data: userPermission,
  } = useQuery([userData && `getAllUserPermissinon`], getAllUserPermission);

  if (isLoading || loadingPermission) return <h1>Loading...</h1>;
  if (error || errorPermission) return <h1>Error...</h1>;
  let filteredUser = userData;
  let flyout;
  if (isFlyoutVisible) {
    flyout = (
      <AddUserFlatout
        setIsFlyoutVisible={setIsFlyoutVisible}
        restaurantId={value}
      />
    );
  }

  if (selectedUserId) {
    filteredUser =
      userData && userData.users && userData.users ? userData.users[0] : null; // seleted user = 1
  }
  const onTabClick = (selectedTab) => {
    console.log("selectedTab", selectedTab);
    setSelectedTab(selectedTab.index);
  };
  const tabs = [
    {
      index: 0,
      id: "Outlet Permission",
      name: "Outlet Permission",
      content: filteredUser && (
        <UserOutletPermission
          selectedUserId={selectedUserId}
          user={filteredUser}
          value={value}
        />
      ),
    },
    {
      index: 1,
      id: "Access Permission",
      name: "Access Permission",
      content: filteredUser && userPermission && (
        <UserAccessPermissions
          user={filteredUser}
          modules={userPermission}
          selectedUserId={selectedUserId}
          state={accessPermissionState}
          stateChange={setAccessPermissionState}
          value={value}
        />
      ),
    },
  ];
  const openFlyOut = () => {
    setIsFlyoutVisible(true);
  };
  return (
    <>
      <EuiFlexGroup className="user-main">
        <EuiFlexItem>
          <EuiText>
            <EuiFlexGroup>
              <EuiFlexItem grow={3}>
                <h3>Users</h3>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiButton onClick={() => setIsFlyoutVisible(true)}>
                  Add user
                </EuiButton>
                {flyout}
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiText>
          {filteredUser && (
            <>
              <UserListBasedOnRestaurant
                users={userData}
                selectedUser={selectedUserId}
                setSelectedUser={setSelectedUser}
                openFlyOut={openFlyOut}
              />
            </>
          )}
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText>
            <h3>Access and Permissions</h3>
          </EuiText>

          <EuiTabbedContent
            tabs={tabs}
            selectedTab={tabs[selectedTab]}
            onTabClick={onTabClick}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );
};

export default UserTableList;
