import { getHeaderToken } from "../helper/getHeaderToken";
import { baseApiUrl } from "../envConfig";
const request = require("superagent-use")(require("superagent"));

export const setSuperAgent = (store) => {
  request.use((req) => {
    req.header = getHeaderToken(store.getState().auth.token);
    req.url = `${baseApiUrl}${req.url}`;
    return req;
  });
  request.language = store.getState().language.language;
  request.dispatch = store.dispatch;
  request.store = store;
};

// Setup some defaults

export default request;
