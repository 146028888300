import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import '@elastic/eui/dist/eui_theme_light.css';

export const EuiTheme = () => {
  const themeMode = useSelector((state) => state.theme.theme);

  useEffect(() => {
    if (themeMode === 'light') {
      document.documentElement.style.setProperty('--var-for-theme-date-picker', 'white');
      document.documentElement.style.setProperty('--var-for-theme-date-picker-fonts', 'black');
      document.documentElement.style.setProperty('--var-for-theme-date-picker-border', '#e3e6ef');
      return import('@elastic/eui/dist/eui_theme_light.min.css');
    } else {
      document.documentElement.style.setProperty('--var-for-theme-date-picker', '#18191d');
      document.documentElement.style.setProperty('--var-for-theme-date-picker-fonts', 'white');
      document.documentElement.style.setProperty('--var-for-theme-date-picker-border', '#2f3034');
      return import('@elastic/eui/dist/eui_theme_dark.min.css');
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [themeMode]);

  return <></>;
};
