import React from "react";
import { MainLayout } from "../components/layout/mainLayout";
import CreateRestaurant from "../components/restaurants/createRestaurant";

const CreateRestaurants = () => {
  return (
    <MainLayout>
      <CreateRestaurant />
    </MainLayout>
  );
};
export default CreateRestaurants;
