import React, { useEffect, useState } from "react";
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiText,
  EuiDatePicker,
  EuiSpacer,
} from "@elastic/eui";
import moment from "moment";
import { getOrderAndVisitorCountByDate } from "../../../api/outlet";

export const CompareWidget = (props) => {
  const [state, setState] = useState({
    firstDate: moment(moment().subtract(1, "days").format("L")),
    secondDate: moment(),
    firstVisitor: 0,
    firstSuccessOrders: 0,
    firstTotalOrder: 0,
    secondVisitor: 0,
    secondSuccessOrders: 0,
    secondTotalOrder: 0,
  });
  useEffect(() => {
    getOrderAndVisitorCountByDate(state.firstDate, props.outletId).then(
      (res) => {
        if (!res.apiError) {
          setState({
            ...state,
            firstVisitor: res.body.details.users,
            firstSuccessOrders: res.body.details.successOrders,
            firstTotalOrder: res.body.details.totalOrders,
          });
        }
      }
    );
  }, [state.firstDate, props.outletId]);

  useEffect(() => {
    getOrderAndVisitorCountByDate(state.secondDate, props.outletId).then(
      (res) => {
        if (!res.apiError) {
          setState({
            ...state,
            secondVisitor: res.body.details.users,
            secondSuccessOrders: res.body.details.successOrders,
            secondTotalOrder: res.body.details.totalOrders,
          });
        }
      }
    );
  }, [state.secondDate, props.outletId]);
  return (
    <EuiFlexGroup>
      <EuiFlexItem grow={false}>
        <EuiPanel>
          <EuiText>
            <h4>Compare Widget</h4>
          </EuiText>
          <EuiFlexGroup responsive>
            <EuiFlexItem grow={5}>
              <EuiDatePicker
                selected={state.firstDate}
                onChange={(date) => setState({ ...state, firstDate: date })}
              />
            </EuiFlexItem>
            <EuiFlexItem grow={5}>
              <EuiDatePicker
                selected={state.secondDate}
                onChange={(date) => setState({ ...state, secondDate: date })}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer size="s" />
          <EuiFlexGroup responsive>
            <EuiFlexItem grow={5}>
              <EuiPanel>
                <EuiText>
                  <p>
                    Total orders: <b>{state.firstTotalOrder}</b>
                  </p>
                </EuiText>
                <EuiText>
                  <p>
                    Success orders: <b>{state.firstSuccessOrders}</b>
                  </p>
                </EuiText>
                <EuiText>
                  <p>
                    Visitors: <b>{state.firstVisitor}</b>
                  </p>
                </EuiText>
              </EuiPanel>
            </EuiFlexItem>
            <EuiFlexItem grow={5}>
              <EuiPanel>
                <EuiText>
                  <p>
                    Total orders: <b>{state.secondTotalOrder}</b>
                  </p>
                </EuiText>
                <EuiText>
                  <p>
                    Success orders: <b>{state.secondSuccessOrders}</b>
                  </p>
                </EuiText>
                <EuiText>
                  <p>
                    Visitors: <b>{state.secondVisitor}</b>
                  </p>
                </EuiText>
              </EuiPanel>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiPanel>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};
