import React, { useEffect, useState } from "react";
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiComboBox,
  EuiFormRow,
} from "@elastic/eui";
import { useDispatch } from "react-redux";
import { getRestaurantList } from "../../api/plans/fetchRestaurantList";
import RestaurantPlans from "./restaurantPlans";

const PlansComponent = () => {
  const dispatch = useDispatch();

  const [restaurantOptions, setRestaurantOptions] = useState([]);
  const [selectedRestaurant, setSelectedRestaurant] = useState([]);

  useEffect(() => {
    let restaurantArray = [];
    dispatch(getRestaurantList()).then((res) => {
      if (res.success) {
        res.restaurants.map((restaurant) => {
          restaurantArray.push({ ...restaurant, label: restaurant.name });
        });

        if (restaurantArray.length) {
          setRestaurantOptions(restaurantArray);
        }
      } else {
        alert("Error in fetching restaurant list");
      }
    });
  }, []);

  const onChange = (selectedOptions) => {
    setSelectedRestaurant(selectedOptions);
  };

  return (
    <EuiFlexGroup direction="column">
      <EuiFlexItem>
        {/* Restaurnat List */}
        <EuiFormRow label="Restaurants">
          <EuiComboBox
            placeholder="Select restaurant"
            options={restaurantOptions}
            selectedOptions={selectedRestaurant}
            onChange={onChange}
            isClearable={true}
            singleSelection={{ asPlainText: true }}
          />
        </EuiFormRow>
      </EuiFlexItem>

      <EuiFlexItem>
        {selectedRestaurant.length ? (
          <RestaurantPlans selectedRestaurant={selectedRestaurant} />
        ) : (
          ""
        )}
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export default PlansComponent;
