import React, { useState } from "react";
import {
  EuiAvatar,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHeaderSectionItemButton,
  EuiLink,
  EuiText,
  EuiSpacer,
  EuiPopover,
  EuiSwitch,
  htmlIdGenerator,
} from "@elastic/eui";
import { logoutCall } from "../../../helper/logout";

import { useDispatch, useSelector } from "react-redux";
import { capitalize } from "../../../helper/capitalize";

export default () => {
  const id = htmlIdGenerator()();
  const themeMode = useSelector((state) => state.theme.theme);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [checked, setChecked] = useState(themeMode === "dark");
  const auth = useSelector((state) => state.auth);

  const onChange = (e) => {
    setChecked(e.target.checked);
    if (themeMode === "light") {
      dispatch({ type: "set-theme", payload: "dark" });
    } else if (themeMode === "dark") {
      dispatch({ type: "set-theme", payload: "light" });
    }
    window.location.reload();
  };

  const logout = () => {
    dispatch({
      type: "set-auth",
      payload: {
        isLoggedIn: false,
      },
    });
    logoutCall(auth.token);
  };

  const onMenuButtonClick = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const button = (
    <EuiHeaderSectionItemButton
      aria-controls={id}
      aria-expanded={isOpen}
      aria-haspopup="true"
      aria-label="Account menu"
      onClick={onMenuButtonClick}
    >
      <EuiAvatar name={auth.user.name} size="s" />
    </EuiHeaderSectionItemButton>
  );

  return (
    <EuiPopover
      id={id}
      ownFocus
      button={button}
      isOpen={isOpen}
      closePopover={closeMenu}
    >
      <EuiFlexGroup gutterSize="m" responsive={false} alignItems="center" wrap>
        <EuiFlexItem grow={false}>
          <EuiAvatar name={auth.user.name} size="xl" />
        </EuiFlexItem>

        <EuiFlexItem>
          <EuiText>
            <p>{auth.user.name}</p>
          </EuiText>

          <EuiSpacer size="m" />

          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiFlexGroup justifyContent="spaceBetween">
                <EuiFlexItem grow={false}>
                  <EuiSwitch
                    label={capitalize(themeMode.toString())}
                    checked={checked}
                    onChange={(e) => onChange(e)}
                  />
                </EuiFlexItem>

                <EuiFlexItem grow={false}>
                  <EuiLink onClick={() => logout()}>Log out</EuiLink>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPopover>
  );
};
