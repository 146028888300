import { EuiText } from "@elastic/eui";
import React from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import EditOutletForm from "./editOutletForm";

const EditOutlet = () => {
  const editOutlet = useSelector((state) => state.outlet.editOutlet);

  console.log("editOutlet inside component  ", editOutlet);
  // edit outlet info did not get then return
  if (!editOutlet) return <EuiText> No Record found </EuiText>;
  return <EditOutletForm getOutletData={editOutlet} />;
};

export default EditOutlet;
