import React, { useState, useRef } from "react";
import DropNCrop from "@synapsestudios/react-drop-n-crop";
import "@synapsestudios/react-drop-n-crop/lib/react-drop-n-crop.min.css";
import { Controller } from "react-hook-form";
import { EuiFlexGroup, EuiFlexItem, EuiButton, EuiFormRow } from "@elastic/eui";
import { baseApiUrl } from "../../envConfig";
import { useSelector } from "react-redux";

export const ImageDropCrop = (props) => {
  const [boolValue, setBoolValue] = useState(false);
  const [loader, setloader] = useState(false);
  const [loaderDelete, setLoaderDelete] = useState(false);
  const {
    name,
    setValue,
    watch,
    control,
    errors,
    rules,
    isInvalid,
    error,
    aspectWidth,
    aspectHeight,
  } = props;
  let cropperRef = useRef(null);
  const token = useSelector((state) => state.auth.token);

  const onChangeImage = (value) => {
    setValue(name, value);
    delete errors[`${name}`];
  };
  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };
  const onUpload = async () => {
    const data = cropperRef.current.cropper.getCroppedCanvas().toDataURL();
    let formData = new FormData();
    let fileObj = dataURLtoFile(data, watch(name).filename);

    formData.append("image", fileObj);
    formData.append("image_type", props.imageType);
    setloader(true);
    let imageUpload = await fetch(`${baseApiUrl}uploads`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      // body: JSON.stringify({
      //     image:data
      // }),
      body: formData,
    });
    imageUpload = await imageUpload.json();

    if (imageUpload.success) {
      props.removeError &&
        errors.intro_screens &&
        errors.intro_screens[props.removeError] &&
        delete errors.intro_screens[props.removeError];
      console.log("upload name ", name);
      let obj = watch(name);
      obj["id"] = imageUpload.image.id;
      obj["image_url"] = imageUpload.image.image_url;
      setValue(name, obj);
      console.log("successfully uploaded", imageUpload);
      setloader(false);
    } else {
      console.log("image uploading error");
      setloader(false);
    }
  };
  const onDelete = async () => {
    let id = watch(name).id;
    setloader(false);
    console.log("delete id -", id);
    setLoaderDelete(true);
    if (id) {
      let imageDelete = await fetch(`${baseApiUrl}uploads/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      imageDelete = await imageDelete.json();
      if (imageDelete.success) {
        setValue(name, "");
        setBoolValue(false);
      } else {
        alert("error while deleting");
        setBoolValue(false);
      }
    } else {
      setValue(name, "");
      setBoolValue(false);
    }
    setLoaderDelete(false);
  };
  return (
    <>
      <EuiFormRow isInvalid={isInvalid} error={error}>
        <Controller
          render={({ onChange }) => (
            <DropNCrop
              cropperOptions={{
                initialAspectRatio:
                  parseFloat(aspectWidth) / parseFloat(aspectHeight),
                guides: false,
                aspectRatio: parseFloat(aspectWidth) / parseFloat(aspectHeight),
                zoomable: false,
                autoCropArea: 1,
                dragCrop: false,
                cropBoxResizable: false,
                center: false,
                viewMode: 1,
                autoCrop: true,
                background: false,
                responsive: true,
                ref: cropperRef,
              }}
              maxFileSize={5242880}
              onChange={(files) => {
                onChangeImage(files);
              }}
              value={watch(name)}
            />
          )}
          rules={rules ? rules : {}}
          name={name}
          control={control}
          fullWidth={true}
        />
      </EuiFormRow>
      {((watch(name) &&
        watch(name).src !== null &&
        watch(name) !== undefined &&
        watch(name).error == null) ||
        boolValue) && (
        <EuiFlexGroup style={{ margin: 10 }} alignItems="center">
          <EuiFlexItem
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            {watch(name) && !watch(name).id && (
              <EuiFlexItem grow={false}>
                <EuiButton
                  isDisabled={loaderDelete}
                  isLoading={loader}
                  onClick={() => onUpload()}
                >
                  Upload
                </EuiButton>
              </EuiFlexItem>
            )}

            <EuiFlexItem grow={false}>
              <EuiButton
                isLoading={loaderDelete}
                isDisabled={loader}
                onClick={() => {
                  onDelete();
                }}
              >
                Delete
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      )}
    </>
  );
};
