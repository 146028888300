import API from "../axios/API";
import * as types from "../../reduxStore/types/plans";

export const submitPlan = (restaurantId, data) => {
  return async (dispatch, getState) => {
    let outletResponse;

    try {
      outletResponse = await API.post(
        `restaurants/${restaurantId}/plans`,
        data
      );
    } catch (error) {
      outletResponse = error;
    }

    return outletResponse;
  };
};
