import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/browser";
import * as SentryError from "@sentry/react";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import store from "./reduxStore";
import { setSuperAgent } from "./config/superagent";
import "./styles/globalStyle.scss";
import { EuiProvider } from '@elastic/eui';
setSuperAgent(store);

Sentry.init({ dsn: process.env.REACT_APP_SENTRY_URL });

ReactDOM.render(
  <Provider store={store}>
    <SentryError.ErrorBoundary fallback={"An error has occurred"}>
        <EuiProvider colorMode="light">
            <App/>
        </EuiProvider>
    </SentryError.ErrorBoundary>
  </Provider>,
  document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
