import React, { useState, useEffect, Fragment } from "react";
import {
  EuiFormRow,
  EuiFieldText,
  EuiForm,
  EuiButton,
  EuiFlexItem,
} from "@elastic/eui";
import { Controller, useForm } from "react-hook-form";
import { allCountry } from "../allCountry";
import TextField from "../../Form/TextField";
import NumberField from "../../Form/NumberField";
import MobileNumber from "../../Form/MobileNumber";
import TextAreaField from "../../Form/TextAreaField";
import SelectField from "../../Form/SelectField";
import { useSelector } from "react-redux";
import { baseApiUrl } from "../../../envConfig";
import { store } from "../../../reduxStore/store";
import API from "../../../api/axios/API";
import { isEmpty } from "lodash";

const BasicInfo = ({ getOutletData }) => {
  const languages = useSelector((state) => state.language.availableLanguages);
  const restaurantID = useSelector((state) => state.restaurant.restaurantId);
  var token = store.getState().auth.token;
  const { handleSubmit, errors, control, watch, reset, setValue } = useForm();
  let countryCode = [];
  for (const [key, value] of Object.entries(allCountry)) {
    countryCode.push({
      value: key,
      inputDisplay: value,
    });
  }
  let defaultValue = {};
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    if (getOutletData) {
      const outletData = getOutletData;
      let translationObject = {
        name: {},
        city: {},
        state: {},
        address_line_1: {},
        address_line_2: {},
      };

      for (const languageId in outletData.translations.name) {
        translationObject.name[languageId] =
          outletData.translations.name[languageId];
      }

      for (const languageId in outletData.outlet_address.translations.city) {
        translationObject.city[languageId] =
          outletData.outlet_address.translations.city[languageId];
      }
      for (const languageId in outletData.outlet_address.translations.state) {
        translationObject.state[languageId] =
          outletData.outlet_address.translations.state[languageId];
      }
      for (const languageId in outletData.outlet_address.translations
        .address_line_1) {
        translationObject.address_line_1[languageId] =
          outletData.outlet_address.translations.address_line_1[languageId];
      }
      for (const languageId in outletData.outlet_address.translations
        .address_line_2) {
        translationObject.address_line_2[languageId] =
          outletData.outlet_address.translations.address_line_2[languageId];
      }

      defaultValue.translations = translationObject;
      defaultValue.fssai_licence =
        outletData &&
        outletData.fssai_licence &&
        outletData.fssai_licence.licence;
      defaultValue.contact_number = outletData.outlet_address.contact_number
        ? `${outletData.outlet_address.country_code}${outletData.outlet_address.contact_number}`
        : outletData.outlet_address.contact_number;
      defaultValue.city = outletData.outlet_address.city;
      defaultValue.state = outletData.outlet_address.state;
      defaultValue.iso_country_code =
        outletData.outlet_address.iso_country_code;

      reset({
        ...defaultValue,
      });
    }
  }, [getOutletData]);

  const onSubmit = async (data) => {
    const city = {};
    const state = {};
    const address_line_1 = {};
    const address_line_2 = {};
    const name = {};
    data.id = null;
    if (getOutletData) {
      data.id = getOutletData.outlet_id;
    }
    for (const property in data.translations.city) {
      city[parseInt(property)] = data.translations.city[property];
    }
    for (const property in data.translations.state) {
      state[parseInt(property)] = data.translations.state[property];
    }
    for (const property in data.translations.address_line_1) {
      address_line_1[parseInt(property)] =
        data.translations.address_line_1[property];
    }
    for (const property in data.translations.address_line_2) {
      address_line_2[parseInt(property)] =
        data.translations.address_line_2[property];
    }

    for (const property in data.translations.name) {
      name[parseInt(property)] = data.translations.name[property];
    }

    data.translations.city = city;
    data.translations.state = state;
    data.translations.address_line_1 = address_line_1;
    data.translations.address_line_2 = address_line_2;
    data.translations.name = name;

    data.address = {
      // contact_number: data.contact_number,
      // country_code: data.country_code,
      // contact_number: data.contact_number,
      iso_country_code: data.iso_country_code ? data.iso_country_code : "IN",
      // country_code: !isEmpty(selectedCountryData) ? selectedCountryData.dialCode : '+91',
      country_code: !isEmpty(data.selectedCountryNumber)
        ? `+${data.selectedCountryNumber.dialCode}`
        : "+91",
      contact_number: !isEmpty(data.selectedCountryNumber)
        ? data.selectedCountryNumber.number
        : getOutletData.outlet_address.contact_number,
      translations: {
        city: data.translations.city,
        state: data.translations.state,
        address_line_1: data.translations.address_line_1,
        address_line_2: data.translations.address_line_2,
      },
    };
    delete data.translations.city;
    delete data.translations.state;
    delete data.translations.address_line_1;
    delete data.translations.address_line_2;

    // update api call fro basic info
    setLoader(true);

    // api call
    const basidInfoJson = await API.put(
      `restaurants/:restaurantID/outlets/${data.id}?section=basic_info`,
      data
    );

    if (basidInfoJson.success) {
      alert("successfully edited");
    } else {
      alert("Please try later");
    }

    setLoader(false);
  };

  console.log("watch ", watch());

  return (
    <EuiForm
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      style={{ marginTop: 20 }}
    >
      {languages &&
        languages.map((item, index) => (
          <TextField
            errors={errors}
            isInvalid={
              errors.translations &&
              errors.translations.name &&
              errors.translations.name.hasOwnProperty(item.language_id)
            }
            error={
              errors.translations &&
              errors.translations.name &&
              errors.translations.name.hasOwnProperty(item.language_id) &&
              errors.translations.name[item.language_id]
                ? errors.translations.name[item.language_id].message
                : ""
            }
            label={`${item.language_label} : Name`}
            name={`translations.name.${item.language_id}`}
            placeholder={`Enter the name of the outlet in ${item.language_label}`}
            rules={{ required: "Please enter name" }}
            control={control}
            watch={watch}
          />
        ))}

      {/* <NumberField 
                label='Contact number'
                errors={errors}
                name='contact_number'
                placeholder='Enter the contact number of the outlet'
                rules={{
                    required: 'Please Enter your contact number',
                    minLength: {
                        value: 10,
                        message: ' length should be 10',
                    },
                    maxLength: {
                        value: 10,
                        message: ' length should be 10',
                    },
                    validate: (value) => value >= 0 || 'Please enter positive value',
                }}
                min={0}
                minlength={10}
                maxlength={10}
                control={control}
                watch={watch}
            /> */}
      <EuiFlexItem style={{ width: "100%" }}>
        <MobileNumber
          watch={watch}
          setValue={setValue}
          label={"Contact number"}
          isInvalid={errors["contact_number"]}
          errors={errors}
          placeholder="Enter the contact number of the outlet"
          name={"contact_number"}
          control={control}
          rules={true}
        />
      </EuiFlexItem>
      {languages &&
        languages.map((item, index) => (
          <Fragment
            style={{ marginTop: 20, marginBottom: 20 }}
            key={item.language_id}
          >
            <TextAreaField
              errors={errors}
              isInvalid={
                errors.translations &&
                errors.translations.address_line_1 &&
                errors.translations.address_line_1.hasOwnProperty(
                  item.language_id
                )
              }
              error={
                errors.translations &&
                errors.translations.address_line_1 &&
                errors.translations.address_line_1.hasOwnProperty(
                  item.language_id
                ) &&
                errors.translations.address_line_1[item.language_id]
                  ? errors.translations.address_line_1[item.language_id].message
                  : ""
              }
              label={`${item.language_label} : Address Line 1`}
              name={`translations.address_line_1.${item.language_id}`}
              placeholder="Write address line 1"
              rules={{ required: "Please write address line 1" }}
              control={control}
              watch={watch}
              rows={4}
            />

            <TextAreaField
              errors={errors}
              isInvalid={
                errors.translations &&
                errors.translations.address_line_2 &&
                errors.translations.address_line_2.hasOwnProperty(
                  item.language_id
                )
              }
              error={
                errors.translations &&
                errors.translations.address_line_2 &&
                errors.translations.address_line_2.hasOwnProperty(
                  item.language_id
                ) &&
                errors.translations.address_line_2[item.language_id]
                  ? errors.translations.address_line_2[item.language_id].message
                  : ""
              }
              label={`${item.language_label} : Address Line 2`}
              name={`translations.address_line_2.${item.language_id}`}
              placeholder="Write address line 1"
              rules={{ required: "Please write address line 1" }}
              control={control}
              watch={watch}
              rows={4}
            />

            <TextField
              errors={errors}
              isInvalid={
                errors.translations &&
                errors.translations.city &&
                errors.translations.city.hasOwnProperty(item.language_id)
              }
              error={
                errors.translations &&
                errors.translations.city &&
                errors.translations.city.hasOwnProperty(item.language_id) &&
                errors.translations.city[item.language_id]
                  ? errors.translations.city[item.language_id].message
                  : ""
              }
              label={`${item.language_label} : City`}
              name={`translations.city.${item.language_id}`}
              placeholder="Please Enter city"
              rules={{ required: "Please enter city" }}
              control={control}
              watch={watch}
            />

            <TextField
              errors={errors}
              isInvalid={
                errors.translations &&
                errors.translations.state &&
                errors.translations.state.hasOwnProperty(item.language_id)
              }
              error={
                errors.translations &&
                errors.translations.state &&
                errors.translations.state.hasOwnProperty(item.language_id) &&
                errors.translations.state[item.language_id]
                  ? errors.translations.state[item.language_id].message
                  : ""
              }
              label={`${item.language_label} : State`}
              name={`translations.state.${item.language_id}`}
              placeholder="Please Enter state"
              rules={{ required: "Please enter state" }}
              control={control}
              watch={watch}
            />
          </Fragment>
        ))}
      <SelectField
        errors={errors}
        label="Country"
        name="iso_country_code"
        options={countryCode}
        valueOfSelected={
          watch("iso_country_code") ? watch("iso_country_code") : "IN"
        }
        onChangeData={(value) => setValue("iso_country_code", value)}
        placeholder="Please enter your country"
        // rules={{ required: 'Please Enter your country' }}
        control={control}
        watch={watch}
        defaultValue={
          watch("iso_country_code") ? watch("iso_country_code") : "IN"
        }
      />
      <EuiFormRow label="FSSAI Licence" fullWidth={true}>
        <Controller
          as={EuiFieldText}
          name="fssai_licence"
          control={control}
          fullWidth={true}
          placeholder="Please enter fssai licence"
        />
      </EuiFormRow>
      <EuiButton isLoading={loader} type="submit" fill>
        Update Basic Info
      </EuiButton>
    </EuiForm>
  );
};

export default BasicInfo;
