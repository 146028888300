import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

export default ({ component: Component, ...rest }) => {
  const isAuthenticated = useSelector((state) => state.auth.isLoggedIn);

  // Check what component it is and are we allowed to see that component.

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};
