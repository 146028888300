import React, { useEffect, useState } from "react";
import {
  EuiPanel,
  EuiText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiLoadingContent,
} from "@elastic/eui";
import { getVisitorInformationByOutlet } from "../../../api/outlet";

export const VisitorCard = (props) => {
  const [state, setState] = useState({
    apiDataLoaded: false,
    todayVisitor: 0,
    todayCarts: 0,
    weekVisitor: 0,
    weekCarts: 0,
    monthVisitor: 0,
    monthCarts: 0,
  });
  useEffect(() => {
    getVisitorInformationByOutlet(props.outletId).then((res) => {
      if (!res.apiError) {
        const details = res.body.details;
        setState({
          ...state,
          apiDataLoaded: true,
          todayVisitor: details.today.users,
          todayCarts: details.today.carts_count,
          weekVisitor: details.week.users,
          weekCarts: details.week.carts_count,
          monthVisitor: details.month.users,
          monthCarts: details.month.carts_count,
        });
      }
    });
  }, []);
  return (
    <EuiPanel>
      <EuiText>
        <h5>Visitor Information</h5>
      </EuiText>
      <EuiSpacer size="xs" />
      {!state.apiDataLoaded && <EuiLoadingContent lines={4} />}
      {state.apiDataLoaded && (
        <EuiFlexGroup responsive>
          <EuiFlexItem>
            <EuiPanel>
              <EuiText>
                <h6>Today</h6>
              </EuiText>
              <EuiSpacer size="xs" />
              <EuiText>
                <p>
                  User: <b>{state.todayVisitor}</b>
                </p>
              </EuiText>
              <EuiText>
                <p>
                  Carts: <b>{state.todayCarts}</b>
                </p>
              </EuiText>
            </EuiPanel>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiPanel>
              <EuiText>
                <h6>Week So Far</h6>
              </EuiText>
              <EuiSpacer size="xs" />
              <EuiText>
                <p>
                  User: <b>{state.weekVisitor}</b>
                </p>
              </EuiText>
              <EuiText>
                <p>
                  Carts: <b>{state.weekCarts}</b>
                </p>
              </EuiText>
            </EuiPanel>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiPanel>
              <EuiText>
                <h6>Month So Far</h6>
              </EuiText>
              <EuiSpacer size="xs" />
              <EuiText>
                <p>
                  User: <b>{state.monthVisitor}</b>
                </p>
              </EuiText>
              <EuiText>
                <p>
                  Carts: <b>{state.monthCarts}</b>
                </p>
              </EuiText>
            </EuiPanel>
          </EuiFlexItem>
        </EuiFlexGroup>
      )}
    </EuiPanel>
  );
};
