/* global localStorage JSON */
import * as types from "../types/auth";
import { v4 as uuidv4 } from "uuid";

const localAuthState = localStorage.getItem("auth");

const userData =
  localAuthState !== null ? { ...JSON.parse(localAuthState) } : {};

// Check if this brwoser has UUID,
let deviceId = localStorage.getItem("deviceId")
  ? localStorage.getItem("deviceId")
  : null;
if (!deviceId) {
  // generate New device ID and also store it in localStorage.
  deviceId = uuidv4();
  localStorage.setItem("deviceId", deviceId);
}

const initialState = {
  // initialize this data from local Storage
  deviceId,
  isLoggedIn: localAuthState !== null,
  token: "",
  ...userData,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_AUTH:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};
