import React from "react";

import { MainLayout } from "../components/layout/mainLayout";
import { UserComponent } from "../components/userComponents/userComponent";

const Users = () => {
  return (
    <MainLayout>
      <UserComponent />
    </MainLayout>
  );
};

export default Users;
