import { baseApiUrl } from "../envConfig";
import { store } from "../reduxStore/store";
import API from "../api/axios/API";

export const getAllUserByRestaurantId = async (key, id) => {
  const userJson = await API.get(`restaurants/${id}/users`);

  console.log(userJson);
  if (userJson.success === true) {
    return userJson;
  } else {
    return userJson;
  }
};

export const getAllUserPermission = async () => {
  const usersPermissionJson = await API.get(
    `restaurants/:restaurantID/users/permissions`
  );

  if (usersPermissionJson.success === true) {
    return usersPermissionJson;
  } else {
    return false;
  }
};

export const getUserPermissionById = async (key, id) => {
  const usersPermissionJson = await API.get(
    `restaurants/:restaurantID/users/${id}/permissions`
  );

  if (usersPermissionJson.success === true) {
    return usersPermissionJson;
  } else {
    return false;
  }
};
