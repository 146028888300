import React from "react";
import { Controller, useFieldArray } from "react-hook-form";
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiFieldNumber,
  EuiButtonIcon,
  EuiButton,
} from "@elastic/eui";

import TimePicker from "react-time-picker";

import "./style.css";

const KitchenTimeSlot = ({ setValue, control, name, errors, watch }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: name,
  });

  return (
    <>
      {fields.length < 6 && (
        <EuiFlexGroup
          style={{
            justifyContent: "flex-end",
          }}
        >
          <EuiButton
            fill
            iconType="plusInCircleFilled"
            onClick={() => {
              append({});
            }}
          >
            Add
          </EuiButton>
        </EuiFlexGroup>
      )}

      {fields.length > 0 &&
        fields.map((item, index) => (
          <EuiFlexGroup
            alignItems="center"
            style={{ margin: "5px" }}
            key={item.id}
          >
            <EuiFlexItem grow={1}> Slot : {index + 1}/6 </EuiFlexItem>
            <EuiFlexItem grow={8}>
              <EuiFlexGroup>
                <EuiFlexItem>
                  <EuiFormRow>
                    <Controller
                      render={({ onChange }) => (
                        <TimePicker
                          value={
                            watch(`${name}[${index}].start_time`)
                              ? watch(`${name}[${index}].start_time`)
                              : undefined
                          }
                          onChange={(time) => {
                            setValue(`${name}[${index}].start_time`, time);
                          }}
                          format="hh:mm a"
                          amPmAriaLabel="Select AM/PM"
                          closeClock={true}
                          disableClock={true}
                          required={true}
                        />
                      )}
                      name={`${name}[${index}].start_time`}
                      control={control}
                      fullWidth={true}
                    />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFormRow>
                    <Controller
                      render={({ onChange }) => (
                        <TimePicker
                          value={
                            watch(`${name}[${index}].end_time`)
                              ? watch(`${name}[${index}].end_time`)
                              : undefined
                          }
                          onChange={(time) => {
                            setValue(`${name}[${index}].end_time`, time);
                          }}
                          format="hh:mm a"
                          amPmAriaLabel="Select AM/PM"
                          closeClock={true}
                          disableClock={true}
                          required={true}
                        />
                      )}
                      name={`${name}[${index}].end_time`}
                      control={control}
                      fullWidth={true}
                      required={true}
                    />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFormRow>
                    <Controller
                      render={({ onChange }) => (
                        <EuiFieldNumber
                          onChange={(e) => {
                            setValue(
                              `${name}[${index}].preparation_time_in_rush`,
                              e.target.value
                            );
                          }}
                          placeholder="Enter prepartion time"
                          value={
                            watch(`${name}[${index}].preparation_time_in_rush`)
                              ? watch(
                                  `${name}[${index}].preparation_time_in_rush`
                                )
                              : null
                          }
                          min={0}
                          required
                        />
                      )}
                      name={`${name}[${index}].preparation_time_in_rush`}
                      control={control}
                      step="any"
                      fullWidth={true}
                      required={true}
                    />
                  </EuiFormRow>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
            <EuiFlexItem grow={1}>
              <EuiFlexGroup alignItems={"center"}>
                <EuiFlexItem>
                  {fields.length > 0 && (
                    <EuiButtonIcon
                      iconType="minusInCircle"
                      onClick={() => {
                        remove(index);
                      }}
                    />
                  )}
                </EuiFlexItem>
                <EuiFlexItem></EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        ))}
    </>
  );
};

export default KitchenTimeSlot;
