import React from "react";
import { store } from "../../reduxStore/store";
import {
  EuiTable,
  EuiTableHeader,
  EuiTableBody,
  EuiTableHeaderCell,
  EuiCheckbox,
  EuiTableRowCell,
  EuiTableRow,
} from "@elastic/eui";
import { useMutation, useQuery } from "react-query";
import { queryCache } from "react-query";
import { baseApiUrl } from "../../envConfig";
import { useSelector } from "react-redux";
import { getUserPermissionById } from "../../api/permissionAPICall";

const UserAccessPermissions = (props) => {
  console.log("mode modules", props.modules);
  console.log("mode user", props.user);
  const token = store.getState().auth.token;
  const restaurantId = useSelector((state) => state.restaurant.restaurantId);

  const {
    isLoading,
    error,
    data: userData,
  } = useQuery(
    [
      `getSelectedUserPermission${props.selectedUserId}${props.value}`,
      props.selectedUserId,
    ],
    getUserPermissionById
  );
  const permissionChangeApi = (permissionArray) => {
    const updatePermission = fetch(
      `${baseApiUrl}restaurants/${restaurantId}/users/permissions`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
        body: JSON.stringify({
          permissions: permissionArray,
        }),
      }
    ).then((res) => {
      return res.json();
    });
    return updatePermission;
  };

  const [mutate, setItem] = useMutation(permissionChangeApi);

  const renderHeaderCell = () => {
    const headers = [
      <EuiTableHeaderCell key="access_module">
        {" "}
        Access Module
      </EuiTableHeaderCell>,
    ];
    props.modules["permissions"].map((column) => {
      headers.push(
        <EuiTableHeaderCell key={column["permission_id"]}>
          {" "}
          {column["permission"]}
        </EuiTableHeaderCell>
      );
    });
    return headers.length ? headers : null;
  };

  const onRemovePermission = async ({
    objectId,
    permissionId,
    permission,
    permissionList,
  }) => {
    let permissionArray = [];
    if (permission.permission === "read") {
      permissionList.map((value) => {
        permissionArray.push({
          is_allowed: 0,
          permission_object_id: objectId,
          user_id: props.selectedUserId,
          permission_id: value.permission_id,
        });
      });
    } else if (permission.permission === "write") {
      let permissionArrayId = permissionList.filter(
        (value) => value.permission === "delete"
      );
      permissionArray.push({
        is_allowed: 0,
        permission_object_id: objectId,
        user_id: props.selectedUserId,
        permission_id: permissionArrayId[0].permission_id,
      });
      permissionArray.push({
        is_allowed: 0,
        permission_object_id: objectId,
        user_id: props.selectedUserId,
        permission_id: permissionId,
      });
    } else {
      permissionArray.push({
        is_allowed: 0,
        permission_object_id: objectId,
        user_id: props.selectedUserId,
        permission_id: permissionId,
      });
    }
    const response = await mutate(permissionArray);

    return response;
  };

  const onAddPermission = async ({
    objectId,
    permissionId,
    permission,
    permissionList,
  }) => {
    let permissionArray = [];
    if (permission.permission === "delete") {
      permissionList.map((value) => {
        permissionArray.push({
          is_allowed: 1,
          permission_object_id: objectId,
          user_id: props.selectedUserId,
          permission_id: value.permission_id,
        });
      });
    } else if (permission.permission === "write") {
      let permissionArrayId = permissionList.filter(
        (value) => value.permission === "read"
      );
      permissionArray.push({
        is_allowed: 1,
        permission_object_id: objectId,
        user_id: props.selectedUserId,
        permission_id: permissionArrayId[0].permission_id,
      });
      permissionArray.push({
        is_allowed: 1,
        permission_object_id: objectId,
        user_id: props.selectedUserId,
        permission_id: permissionId,
      });
    } else {
      permissionArray.push({
        is_allowed: 1,
        permission_object_id: objectId,
        user_id: props.selectedUserId,
        permission_id: permissionId,
      });
    }
    const response = await mutate(permissionArray);

    return response;
  };

  const onChangePermission = async (
    objectId,
    permissionId,
    permission,
    permissionList,
    itemName,
    e
  ) => {
    try {
      const is_allowed = e.target.checked ? 1 : 0;
      // const updatedPermission = await mutate({ objectId, permissionId, is_allowed });
      let updatedPermission;
      if (!is_allowed) {
        updatedPermission = await onRemovePermission({
          objectId,
          permissionId,
          permission,
          permissionList,
        });
      } else {
        updatedPermission = await onAddPermission({
          objectId,
          permissionId,
          permission,
          permissionList,
        });
      }
      console.log("Updated user permission", updatedPermission);
      if (updatedPermission.success === true) {
        queryCache.invalidateQueries(
          `getSelectedUserPermission${props.selectedUserId}${props.value}`
        );
      }
    } catch (error) {
      console.log("Mutation error - ", error);
    }
  };

  const renderRows = () => {
    let object = {};
    props.modules["modules"].map((module) => {
      object[module["object_name"]] = {};
      props.modules["permissions"].map((permission) => {
        object[module["object_name"]][permission["permission"]] = false;
      });
    });

    // props.user['user_permissions'].map((permission) => {
    //     permission.permissions.map((permissionItem) => {
    //         object[permission.permission_object.name][permissionItem.permission] = true;
    //     });
    // });

    userData["user_permissions"].map((permission) => {
      object[permission.name][permission.permission] = true;
    });

    const rows = props.modules["modules"].map((item) => {
      return (
        <EuiTableRow key={item["object_id"]}>
          <EuiTableRowCell>{item["object_name"]}</EuiTableRowCell>
          {props.modules["permissions"].map((permission) => {
            return (
              <EuiTableRowCell key={permission.permission_id}>
                <EuiCheckbox
                  id={permission["permission_id"].toString()}
                  checked={object[item["object_name"]][permission.permission]}
                  //onChange={(e) => e.target.checked }
                  onClick={(e) =>
                    onChangePermission(
                      item.object_id,
                      permission.permission_id,
                      permission,
                      props.modules["permissions"],
                      item["object_name"],
                      e
                    )
                  }
                />
              </EuiTableRowCell>
            );
          })}
        </EuiTableRow>
      );
    });
    return rows;
  };
  if (isLoading) return "loading..";
  if (error) return "error..";
  console.log("userData", userData);
  return (
    <>
      {props.user && (
        <EuiTable>
          <EuiTableHeader>{renderHeaderCell()}</EuiTableHeader>
          <EuiTableBody>{renderRows()}</EuiTableBody>
        </EuiTable>
      )}
    </>
  );
};

export default UserAccessPermissions;
